import { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Typography,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TableFooter,
  TextField,
  Tooltip,
} from "@mui/material";
// import Textarea from '@mui/material/Textarea';
import TextareaAutosize from "@mui/base/TextareaAutosize";
import { useContext } from "react";
import { AuthContext } from "../../../store/context/AuthProvider";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { basename, customerName } from "../../../Config";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import instance from "../../../api/Interceptor";
import MailIcon from "@mui/icons-material/Mail";
import FileDownloadTwoToneIcon from "@mui/icons-material/FileDownloadTwoTone";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import dayjs from "dayjs";
import urlResolver from "../../../utils/urlResolver";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function QuoteRates() {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };
  const lineStyle = {
    width: "130px",
    borderBottom: "3px solid var(--color-secondary-dark)", // Add a horizontal line
  };
  const headingStyle = {
    fontSize: "18px", // Adjust the font size as needed
  };
  const textStyle = {
    fontSize: "16px",
    color: "var(--color-secondary-dark)", // Adjust the font size as needed
  };
  const role =
    JSON.parse(localStorage.getItem("userData")).Role.includes("Sales") === true
      ? "Sales"
      : "";
  const Email = JSON.parse(localStorage.getItem("userData")).Email;
  const [checksubmit, setCheckSubmit] = useState(false);
  const location = useLocation();
  const dataToSend = location.state;
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [marginValue, setMarginValue] = useState({});
  const [QuotationNumber, setQuotationNumber] = useState(null);
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const [backdroploader, setBackdroploader] = useState(false);
  const [remarks, setRemarks] = useState("");
  // You can access individual data properties like this:
  const {
    information,
    containers,
    fclRates,
    ContainerTypeCode,
    transformMode,
    orgizationName,
    CurrencyCode,
  } = dataToSend;
  console.log(dataToSend, "datatosend");
  const { ContainerTypes } = information;
  const [emailContact, setemailContact] = useState(Email);
  const [isMessageVisible, setMessageVisible] = useState({});
  const [isToggleOn, setIsToggleOn] = useState(false);
  const toggleMessage = (rowIndex) => {
    setIsToggleOn(!isToggleOn);
    setMessageVisible((prevState) => {
      return {
        ...prevState,
        [rowIndex]: !prevState[rowIndex],
      };
    });
  };
  const completeBreakUP = [];
  containers.forEach((selectedContainer) => {
    ContainerTypes.forEach((container) => {
      if (selectedContainer.name === container.ContainerISO) {
        container?.Rates.forEach((rate) => {
          const data = { ...selectedContainer, ...rate };
          completeBreakUP.push(data);
        });
      }
    });
  });
  console.log("completeBreakUP", context.rmsData);
  let totalMargin = Object.values(marginValue).reduce(
    (total, value) => total + parseFloat(value),
    0
  );
  const grandTotal = completeBreakUP.reduce((total, item, index) => {
    const rowTotal =
      item.quantity * item.Rate + (parseFloat(marginValue[index]) || 0);
    return total + rowTotal;
  }, 0);

  const submitHandler = () => {
    setBackdroploader(true);
    let clientCode = "";
    if (role === "Sales") {
      clientCode = context.rmsData.orgizationName;
    } else {
      clientCode = context.rmsData.ClientCode;
    }
    let index = 0;
    const containerData2 = [];
    ContainerTypes.map((element) => {
      if (
        containers
          .map((element) => element.name)
          .includes(element?.ContainerISO)
      ) {
        containerData2.push(element);
      }
    });
    console.log("ContainerTypes", ContainerTypes);
    containerData2?.map((item) => {
      let itemtotal = 0;
      item?.Rates?.map((itemRate) => {
        itemRate.Rate += parseInt(marginValue[index++] ?? 0);
        itemtotal += itemRate.Rate;
      });
      item.TotalRate = itemtotal;
      item.TotalValue = itemtotal;
    });

    containers.forEach((obj) => {
      containerData2.forEach((element) => {
        if (element?.ContainerISO === obj?.name) {
          element.ContainerCount = obj.quantity;
        }
      });
    });
    console.log(containers, "containerData2", containerData2);
    const body = {
      ...information,
      ContainerTypes: containerData2,
      Margin: totalMargin,
      Remarks: remarks,
      CommodityDetails: context?.rmsData?.commodity ?? "",
      GrossWeight: context?.rmsData?.weight ?? "",
      WeightUnit: context?.rmsData?.weightUnit ?? "",
      TotalRate: `${Number(fclRates) + Number(totalMargin)}`,
      TransportMode: transformMode,
      ClientCode: context.rmsData.orgizationName,
      ContainerType: ContainerTypeCode,
    };
    //     let body;

    // if (customerName === "SAR Transport") {
    //   body = {
    //     ...information,
    //     ContainerTypes: containerData2,
    //     Margin: totalMargin,
    //     Remarks: remarks,
    //     CommodityDetails: context?.rmsData?.commodity ?? "",
    //     GrossWeight: context?.rmsData?.weight ?? "",
    //     WeightUnit: context?.rmsData?.weightUnit ?? "",
    //     TotalRate: `${Number(fclRates) + Number(totalMargin)}`,
    //     TransportMode: transformMode,
    //     ClientCode: context.rmsData.orgizationName,
    //     ContainerType: ContainerTypeCode,
    //   };
    // } else {
    //   body = {
    //     ...information,
    //     ContainerTypes: containers,
    //     Margin: totalMargin,
    //     Remarks: remarks,
    //     CommodityDetails: context?.rmsData?.commodity ?? "",
    //     GrossWeight: context?.rmsData?.weight ?? "",
    //     WeightUnit: context?.rmsData?.weightUnit ?? "",
    //     TotalRate: `${Number(fclRates) + Number(totalMargin)}`,
    //     TransportMode: transformMode,
    //     ClientCode: context.rmsData.orgizationName,
    //     ContainerType: ContainerTypeCode,
    //   };
    // }
    instance
      .post("api/v1/Quotation/CreateQuote", body)
      .then((res) => {
        setQuotationNumber(res?.data[0]?.QuotationNumber);
        setCheckSubmit(true);
        Swal.fire({
          icon: "success",
          text: "Quotes Created Successfully.",
          confirmButtonColor: "var(--color-secondary-dark)",
          allowOutsideClick: false,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBackdroploader(false);
      });
    setMarginValue({});
  };

  const generatePDFContent = () => {
    const doc = new jsPDF();
    const name = JSON.parse(localStorage.getItem("userData")).Name;
    const email = JSON.parse(localStorage.getItem("userData")).Email;
    const date = dayjs().format("DD-MM-YYYY");
    console.log(name, email, "userinformation", dayjs().format("DD-MM-YYYY"));
    const defaultsize = 12;
    const sideLablesize = 14;
    const part2 = 100;
    const fontName = "helvetica";
    let rateDetailsStartY = 45;
    const position = () => {
      rateDetailsStartY += 10;
      return rateDetailsStartY;
    };
    // Function to check if there's enough space on the current page
    const checkRemainingSpace = (requiredSpace) => {
      const pageHeight = doc.internal.pageSize.height;
      const currentPosition =
        doc.autoTable.previous.finalY || rateDetailsStartY;
      return currentPosition + requiredSpace < pageHeight;
    };

    // Function to add a new page if there's not enough space
    const addNewPageIfNeeded = (requiredSpace) => {
      if (!checkRemainingSpace(requiredSpace)) {
        doc.addPage();
        rateDetailsStartY = 45; // Reset the starting Y position for the new page
      }
    };

    const imgData = `${urlResolver("assets/headerLogo.png")}`;
    const imgWidth = 30; // Width of the image in millimeters
    const imgHeight = 15; // Height of the image in millimeters
    const imgX = 90; // X position of the image
    const imgY = 5; // Y position of the image
    doc.addImage(imgData, "PNG", imgX, imgY, imgWidth, imgHeight);
    doc.setFontSize(22);
    doc.setFont(fontName, "bold");
    doc.setTextColor(0, 51, 102);
    doc.text("Quote Details", 80, 35);
    doc.setTextColor(0, 0, 0);

    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Name : `, 15, position());
    doc.setFontSize(defaultsize);
    doc.setFont("helvetica", "normal");
    doc.text(` ${name}`, 32, rateDetailsStartY);
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Email :`, 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${email}`, 32, rateDetailsStartY);
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Date :`, 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${date}`, 30, rateDetailsStartY);

    // Add EST travel time
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`EST travel time :`, 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(
      `${
        dataToSend?.information?.TransitTime.length > 0
          ? dataToSend?.information?.TransitTime + " days"
          : "" ?? "N/A"
      }`,
      55,
      rateDetailsStartY
    );

    // generate for information
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Generated for :`, 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${orgizationName}`, 51, rateDetailsStartY);

    // Commodity
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Commodity :`, 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    if (
      context?.rmsData?.commodity !== null &&
      context?.rmsData?.commodity !== undefined
    ) {
      doc.text(` ${context?.rmsData?.commodity}`, 50, rateDetailsStartY);
    } else {
      // Display a blank space for both weight and weightUnit
      doc.text(` `, part2 + 50, rateDetailsStartY); // Display a blank space
    }

    // to show on the right side
    rateDetailsStartY = 45;

    // Add Carrier Name
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Carrier Name :`, part2 + 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(
      ` ${dataToSend?.information?.CarrierName}`,
      part2 + 50,
      rateDetailsStartY
    );

    // Add Origin and Destination information
    const originText =
      dataToSend?.information?.OriginName ??
      dataToSend?.information?.OriginCode ??
      "N/A";
    const destinationText =
      dataToSend?.information?.DestinationName ??
      dataToSend?.information?.DestinationCode ??
      "N/A";
    // origin
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Origin :`, part2 + 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${originText}`, part2 + 33, rateDetailsStartY);
    // Destination
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Destination :`, part2 + 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${destinationText}`, part2 + 46, rateDetailsStartY);
    // quation number
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Quotation Number :`, part2 + 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${QuotationNumber} `, part2 + 63, rateDetailsStartY);

    //weight
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Weight :`, part2 + 15, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    // doc.text(` ${context?.rmsData?.weight} ${context?.rmsData?.weightUnit} `, part2 + 34, rateDetailsStartY);
    // Check if context?.rmsData?.weight and context?.rmsData?.weightUnit are not null or undefined
    if (
      context?.rmsData?.weight !== null &&
      context?.rmsData?.weight !== undefined &&
      context?.rmsData?.weightUnit !== null &&
      context?.rmsData?.weightUnit !== undefined
    ) {
      doc.text(
        ` ${context?.rmsData?.weight} ${context?.rmsData?.weightUnit} `,
        part2 + 34,
        rateDetailsStartY
      );
    } else {
      // Display a blank space for both weight and weightUnit
      doc.text(` `, part2 + 34, rateDetailsStartY); // Display a blank space
    }
    // Carrier Service
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Carrier Service Level :`, 115, position());
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    if (
      context?.rmsData?.CarrierServiceLevel !== null &&
      context?.rmsData?.CarrierServiceLevel !== undefined
    ) {
      doc.text(
        ` ${context?.rmsData?.CarrierServiceLevel}`,
        50,
        rateDetailsStartY
      );
    } else {
      // Display a blank space for both weight and weightUnit
      doc.text(` `, part2 + 50, rateDetailsStartY); // Display a blank space
    }

    // Add Rate Breakdown table
    const rateTableRows = completeBreakUP.map((item, index) => [
      item.Name,
      item.name,
      item.quantity,
      item.quantity * item.Rate,
    ]);

    const rateTableHeaders = ["Description", "Item", "Quantity", "Total"];

    doc.autoTable({
      startY: position() + 10,
      head: [rateTableHeaders],
      body: rateTableRows,
    });

    // Add Margin and Grand Total
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Grand Total :`, 15, doc.autoTable.previous.finalY + 10);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);
    doc.text(` ${grandTotal}`, 47, doc.autoTable.previous.finalY + 10);
    // Add remark
    doc.setFont(fontName, "bold");
    doc.setFontSize(sideLablesize);
    doc.text(`Remarks:`, 15, doc.autoTable.previous.finalY + 20);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(defaultsize);

    // Calculate the available width for the text
    const availableWidth = doc.internal.pageSize.width - 55; // Adjust as needed

    // Split the remark text into multiple lines
    const remarkLines = doc.splitTextToSize(remarks, availableWidth);

    // Iterate through and add each line
    for (let i = 0; i < remarkLines.length; i++) {
      // Check if there's enough space for the line, and add a new page if necessary
      // addNewPageIfNeeded(defaultsize);

      // Add the line of text
      doc.text(remarkLines[i], 40, doc.autoTable.previous.finalY + 20);

      // Increment the Y position
      doc.autoTable.previous.finalY += 5;
    }

    // Save the PDF as a blob
    return doc.output("blob");
  };

  const sendMail = () => {
    window.location.href = `mailto:${emailContact}`;
  };
  // console.log(context.rmsData, "containerInformation");
  const bookingHandler = () => {
    context.setRmsData((prev) => {
      const temp = {
        ...prev,
        margin: marginValue,
        containerInformationRates: [...completeBreakUP],
      };
      return temp;
    });
    navigate(Routeconstant.RMS);
  };
  useEffect(() => {
    if (!context.rmsData.Containertype) navigate("/rms");
  }, [context?.rmsData?.Containertype]);
  return (
    <>
      {backdroploader && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            height: "100vh",
            width: "100vw",
          }}
          open={backdroploader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container spacing={1} className="pl-3">
        <Grid
          item
          lg={12}
          sx={{ cursor: "pointer", marginBottom: "1rem" }}
          onClick={() => navigate(-1)}
          className="font-shipment"
        >
          <KeyboardBackspaceIcon />
          Back
        </Grid>
      </Grid>

      {checksubmit && (
        <Grid container spacing={1}>
          <Grid item lg={5}></Grid>
          <Grid item lg={6} md={6} xs={6}>
            <Typography>
              <span className=" font-weight font-shipment">
                Quotation Number :-
              </span>
              <span className=" font-rms font-shipment">
                {" "}
                {QuotationNumber}
              </span>
            </Typography>
          </Grid>
          <Grid item lg={1} spacing={3}>
            <Tooltip title="Mail">
              <MailIcon className="layout-notification" onClick={sendMail} />
            </Tooltip>
            <FileDownloadTwoToneIcon
              onClick={() => {
                setGeneratingPDF(true);
                const pdfBlob = generatePDFContent();
                saveAs(pdfBlob, `${QuotationNumber}.pdf`);
                setGeneratingPDF(false);
              }}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            />
          </Grid>
        </Grid>
      )}
      <Grid container spacing={1}>
        <Grid item xs={12} className="p-4">
          <Grid container spacing={1}>
            <Grid item lg={1} xs={2}>
              <p className="m-0 font-shipment" style={headingStyle}>
                {" "}
                Rate Details
              </p>
            </Grid>
            <Grid item lg={11} xs={10} className="d-flex  dash-rms">
              <Divider
                variant="center"
                flexItem
                sx={{
                  backgroundColor: " var(--color-secondary-light)",
                  width: "100%",
                  marginTop: "0.8rem",
                }}
              />
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="p-4"
              style={{
                backgroundColor: "white",
                boxShadow: " 0px 1px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "8px",
                padding: "0rem",
                position: "relative",
                marginTop: "1rem",
                marginLeft: "0px",
                paddingBottom: "20px",
              }}
            >
              <Grid item lg={2} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1  head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Origin
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    <LocationOnOutlinedIcon color="primary" />
                    {dataToSend?.information?.OriginName ??
                      dataToSend?.information?.OriginCode ??
                      "N/A"}
                  </p>
                </div>
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <div>
                  {" "}
                  <p
                    className="mb-1  head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Transport Mode
                  </p>{" "}
                  <div
                    style={containerStyle}
                    className="d-flex align-items-center justify-content-between"
                  >
                    {/* <HorizontalRuleOutlinedIcon color="primary" /> */}
                    <div style={lineStyle}></div>
                    {context.buttonActive.Code === "AIR" && (
                      <img
                        src={`/${basename}/assets/Air.svg`}
                        alt="AIR"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    {context.buttonActive.Code === "COU" && (
                      <img
                        src={`/${basename}/assets/courier.svg`}
                        alt="COU"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    {context.buttonActive.Code === "SEA" && (
                      <img
                        src={`/${basename}/assets/ship.svg`}
                        alt="SEA"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    {context.buttonActive.Code === "RAO" && (
                      <img
                        src={`/${basename}/assets/road.svg`}
                        alt="RAO"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    {context.buttonActive.Code === "RAIL" && (
                      <img
                        src={`/${basename}/assets/rail.svg`}
                        alt="RAIL"
                        style={{ width: "30px", height: "30px" }}
                      />
                    )}
                    {/* <HorizontalRuleOutlinedIcon color="primary"/> */}
                    <div style={lineStyle}></div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1 head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Destination
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    <LocationOnOutlinedIcon color="primary" />
                    {dataToSend?.information?.DestinationName ??
                      dataToSend?.information?.DestinationCode ??
                      "N/A"}
                  </p>
                </div>
              </Grid>
              <Grid item lg={1} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1 head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Service Level
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    {`${dataToSend?.information?.ServiceLevel ?? "N/A"}`}
                  </p>
                </div>
              </Grid>
              <Grid item lg={2} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1 head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Carrier Service Level
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    {`${dataToSend?.information?.CarrierServiceLevel ?? "N/A"}`}
                  </p>
                </div>
              </Grid>
              <Grid item lg={1} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1 head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    EST travel time
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    {dataToSend?.information?.TransitTime &&
                    dataToSend?.information?.TransitTime.length
                      ? `${dataToSend?.information?.TransitTime} days`
                      : ""}
                  </p>
                </div>
              </Grid>
              <Grid item lg={1} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1 head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Carrier Name
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    {`${dataToSend?.information?.CarrierName ?? "N/A"}`}
                  </p>
                </div>
              </Grid>
              <Grid item lg={1} md={6} xs={12}>
                <div>
                  <p
                    className="mb-1 head-text text-center font-shipment"
                    style={headingStyle}
                  >
                    Total Rate
                  </p>
                  <p
                    className="mb-0 head-text-para text-center font-shipment"
                    style={textStyle}
                  >
                    {CurrencyCode} {grandTotal.toFixed(2)}
                  </p>
                </div>
              </Grid>
            </Grid>
            <Grid item lg={1} xs={2} sx={{ marginTop: "0.8rem" }}>
              <p className="m-0" style={headingStyle}>
                {" "}
                Charge Breakup
              </p>
            </Grid>
            <Grid
              item
              lg={11}
              xs={10}
              className="d-flex  dash-rms"
              sx={{ marginTop: "0.8rem" }}
            >
              <Divider
                variant="center"
                flexItem
                sx={{
                  backgroundColor: " var(--color-secondary-light)",
                  width: "100%",
                  marginTop: "0.8rem",
                }}
              />
            </Grid>
            <TableContainer component={Paper} sx={{ marginTop: "16px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="font-shipment" style={headingStyle}>
                      Description
                    </TableCell>
                    <TableCell className="font-shipment" style={headingStyle}>
                      Item
                    </TableCell>
                    <TableCell className="font-shipment" style={headingStyle}>
                      Quantity
                    </TableCell>
                    {/* {role === "Sales" && !checksubmit && <TableCell style={headingStyle}>Rate</TableCell>} */}
                    <TableCell className="font-shipment" style={headingStyle}>
                      Rate
                    </TableCell>
                    {customerName === "SAR Transport" &&
                      role === "Sales" &&
                      !checksubmit && (
                        <TableCell
                          className="font-shipment"
                          style={headingStyle}
                        >
                          Margin
                        </TableCell>
                      )}
                    <TableCell className="font-shipment" style={headingStyle}>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {completeBreakUP?.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell className="font-shipment">
                        {item.Name}
                      </TableCell>
                      <TableCell className="font-shipment">
                        {item.name}
                      </TableCell>
                      <TableCell className="font-shipment">
                        {item.quantity}
                      </TableCell>
                      {/* {role === "Sales" && !checksubmit && <TableCell>{item.Rate}</TableCell>} */}
                      <TableCell className="font-shipment">
                        {item.Rate}
                      </TableCell>
                      {customerName === "SAR Transport" &&
                        role === "Sales" &&
                        !checksubmit && (
                          <TableCell>
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  type="text"
                                  placeholder="0"
                                  value={
                                    isMessageVisible[index]
                                      ? ((marginValue[index] * 100) /
                                          item.Rate ===
                                        0
                                          ? ""
                                          : (marginValue[index] * 100) /
                                            item.Rate) ?? ""
                                      : marginValue[index] ?? ""
                                  }
                                  onChange={(e) => {
                                    const inputValue = parseInt(e.target.value);
                                    console.log(inputValue, "inputvlaue");
                                    if (
                                      /^\d*$/.test(inputValue) ||
                                      inputValue.length === 0
                                    ) {
                                      const newMarginValues = {
                                        ...marginValue,
                                      };
                                      newMarginValues[index] = isMessageVisible[
                                        index
                                      ]
                                        ? (inputValue * item.Rate) / 100
                                        : inputValue;
                                      newMarginValues[index] = isMessageVisible[
                                        index
                                      ]
                                        ? (inputValue * item.Rate) / 100
                                        : inputValue;
                                      setMarginValue(newMarginValues);
                                    } else if (isNaN(inputValue)) {
                                    } else if (isNaN(inputValue)) {
                                      const newMarginValues = {
                                        ...marginValue,
                                      };
                                      newMarginValues[index] = null;
                                      newMarginValues[index] = null;
                                      setMarginValue(newMarginValues);
                                    }
                                  }}
                                  inputProps={{
                                    min: 0,
                                    step: 1,
                                    pattern: "[0-9]*",
                                  }} // Allow only numbers
                                />
                                {/* {(customerName !== "SFLInsight" && role === "Sales") && (
                                <Tooltip title={isMessageVisible[index] ? "switch for flat margin" : "switch for percentage margin"}>
                                  <Switch
                                   defaultChecked={false}
                                    onClick={() => {
                                      toggleMessage(index)
                                      marginValue[index] = 0;
                                      setMarginValue(marginValue)
                                    }}
                                  />

                                </Tooltip>)} */}
                              </div>
                            </div>
                            {/* {isMessageVisible[index] ? (
                              <Typography variant="body2" color="primary">
                                Fill the Margin Value in Percent(%)
                              </Typography>
                            ) : (<Typography variant="body2" color="primary">
                              Fill the Margin Value in flat
                            </Typography>)} */}
                          </TableCell>
                        )}

                      {/* {!isMessageVisible[index] ? (
                      <TableCell>{(item.quantity * item.Rate) + (parseFloat(marginValue[index]) || 0)}</TableCell>):(
                        <TableCell>{(item.quantity * item.Rate) + ((parseFloat(marginValue[index]) * item.Rate) /100 || 0)}</TableCell>)} */}
                      <TableCell className="font-shipment">
                        {item.quantity * item.Rate +
                          (parseFloat(marginValue[index]) || 0)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>

                <TableFooter>
                  {/* {
                    (role === "Sales") && !checksubmit && (
                      <TableRow>
                        <TableCell colSpan={4}>
                          <Typography variant="h6" component="span">
                            Margin:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <TextField
                            type="number"
                            value={marginValue ?? 0}
                            onChange={(e) => {
                              setMarginValue(e.target.value)
                            }}
                            inputProps={{ min: 0, step: 1 }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  } */}

                  <TableRow>
                    <TableCell
                      colSpan={
                        customerName === "SFLInsight" ? 4 : !checksubmit ? 5 : 4
                      }
                    >
                      <Typography
                        variant="h6"
                        component="span"
                        className="font-shipment"
                      >
                        Grand Total:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        component="span"
                        className="font-shipment"
                      >
                        {CurrencyCode} {grandTotal.toFixed(2)}
                        {/* {`${Number(fclRates) + Number(marginValue)}`} */}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={1}>
                      <Typography
                        variant="h6"
                        component="span"
                        className="font-shipment"
                      >
                        Remarks:
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextareaAutosize
                        style={{
                          width: "100%",
                          border: "1px solid var(--color-secondary-light)",
                          padding: "8px" /* Add padding as needed */,
                          outline:
                            "none" /* Remove the default outline if not needed */,
                        }}
                        variant="h6"
                        className="font-shipment"
                        disabled={checksubmit}
                        minRows={2}
                        maxRows={3}
                        type="text"
                        placeholder="Remarks"
                        onChange={(e) => setRemarks(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <Grid
              container
              justifyContent="center"
              spacing={2}
              sx={{ marginTop: "16px" }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => submitHandler()}
                  disabled={checksubmit}
                  className="font-shipment"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!checksubmit}
                  onClick={() => bookingHandler()}
                  className="font-shipment"
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
