import React, { useState, useEffect } from "react";
import CreateBtn from "../../components/button/CreateBtn";
import instance from "../../api/Interceptor";
import { useLocation } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

const HeaderButton = (props) => {
  const transportType = props.transportType;
  const [headerdata, setHeaderdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const item = location.state;
  useEffect(() => {
    getTransportmodes();
  }, []);

  const getTransportmodes = () => {
    setLoading(true);
    instance
      .get("api/v1/master/transportmodes")
      .then((res) => {
        const filteredData = res.data.filter(
          (obj) => obj.Code === "AIR" || obj.Code === "SEA"
        );

        const updatedData = filteredData.map((obj) => {
          if (obj.Code === "AIR") {
            return {
              ...obj,
              blueimg: "AIR-WHITE.svg",
              wideimg: "Air.svg",
            };
          } else if (obj.Code === "SEA") {
            return {
              ...obj,
              blueimg: "SHIP-WHITE.svg",
              wideimg: "ship.svg",
            };
          } else {
            return obj;
          }
        });

        setHeaderdata(updatedData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="d-flex justify-content-between createbtn-section font-shipment">
      {loading ? (
        <>
          <Skeleton variant="rounded" width={"100%"} height={"4em"} />
        </>
      ) : (
        <>
          {headerdata.length > 0 && ( // Only render the CreateBtn if there is data available
            <CreateBtn
              transportType={transportType}
              selectedData={item?.TransportMode ?? null}
              data={headerdata}
              widecolor="var(--color-miscellaneous-6)"
              bluecolor="var(--color-secondary-dark)"
              className="rms-btn-text"
              style={{
                padding: "0.2rem 3rem",
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default HeaderButton;
