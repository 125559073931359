import { createTheme } from "@mui/material/styles";
export const appTheme = createTheme({
  typography: {
    fontFamily: `IBM Plex Sans`,
  },
  palette: {
    primary: {
      main: "#002D5D",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    secondary: {
      main: "#004E72",
      // light: '#757ce8',
      // dark: '#002884',
      // contrastText: '#fff',
    },
    third: {
      main: "#F4F6F5",
    },
  },
});
