import React, { useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import urlResolver from "../../utils/urlResolver";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";

function MapcompDashboard(props) {
  const [location, setlocation] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentDate = new Date();
  const currentTimeInMinutes =
    currentDate.getHours() * 60 + currentDate.getMinutes();
  const WhatCompToRender = () => {
    if (loading) {
      return (
        <div
          style={{
            zIndex: "20",
            backgroundColor: "rgba(128,128,128,0.4)",
            position: "absoulte",
            top: "37px",
            left: "40px",
            width: "100%",
            height: "100%",
            "@media only screen  and (max-width: 424px)": {
              width: "96%",
            },
            borderRadius: "1rem",
            // backgroundColor: "pink",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <h2>Loading..</h2>
          </div>
        </div>
      );
    } else if (location?.length === 0) {
      return (
        <div
          style={{
            zIndex: "20",
            backgroundColor: "rgba(128,128,128,0.4)",
            position: "absoulte",
            borderRadius: "12px",
            top: "37px",
            left: "40px",
            width: "100%",
            height: "100%",
            "@media only screen  and (max-width: 424px)": {
              width: "96%",
            },

            // backgroundColor: "pink",
          }}
        >
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            {/* <h3>No data found</h3> */}
            <Typography variant="">No data found</Typography>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };
  // api call to get the location data
  const getUpcomingShipmentLocationCounts = () => {
    setLoading(true);
    const body = {
      CurrentPage: 1,
      PageSize: 10,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_MAPLOCATIONDATA, body)
      .then((response) => {
        localStorage.setItem(
          "MapInformatinForPin",
          JSON.stringify(response?.data ?? [])
        );
        localStorage.setItem(
          "MapInformatinForPinTimigOfStorage",
          JSON.stringify(currentTimeInMinutes)
        );
        setlocation(response?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    const timeOfStorage = JSON.parse(
      localStorage.getItem("MapInformatinForPinTimigOfStorage")
    );
    if (timeOfStorage === null || currentTimeInMinutes - timeOfStorage >= 5)
      getUpcomingShipmentLocationCounts();
    else setlocation(JSON.parse(localStorage.getItem("MapInformatinForPin")));
  }, []);
  return (
    <Grid
      container
      spacing={4}
      sx={{
        position: "relative",
        top: "37px",
        left: "40px",
        width: "100%",
        height: "100%",

        "@media only screen and (max-width: 424px)": {
          width: "96%",
        },
        borderRadius: "1rem",
      }}
    >
      <WhatCompToRender />
      <Map
        google={props.google}
        zoom={1}
        initialCenter={{
          lat: location[0]?.Lattitude ?? 0,
          lng: location[0]?.Longitude ?? 0,
        }}
        style={{
          borderRadius: "12px",
        }}
      >
        {location.map((item, index) => {
          if (item.Lattitude && item.Longitude !== "") {
            return (
              <Marker
                icon={{
                  url: urlResolver("assets/destinationMarker.svg"),
                  scaledSize: new window.google.maps.Size(25, 25),
                }}
                title={`${item?.DestinationPortName ?? ""}(${
                  item?.TotalCount ?? ""
                })`}
                name={`${item?.DestinationPortName ?? ""}(${
                  item?.TotalCount ?? ""
                })`}
                position={{
                  lat: item.Lattitude,
                  lng: item.Longitude,
                }}
              />
            );
          } else {
          }
        })}
      </Map>
    </Grid>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(MapcompDashboard));
