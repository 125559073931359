import React from "react";
import { Button, Dialog, DialogContent, Grid } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./rmsbookmodal.css";
import Input from "@mui/material/Input";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import InputAdornment from "@mui/material/InputAdornment";

const NegotiateShipment = ({ setNegotiatemodal, negotiatemodal }) => {
  const handleClose = () => setNegotiatemodal("");

  return (
    <Dialog
      open={negotiatemodal}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "50%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={2} style={{ margin: "-8px" }}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="d-flex justify-content-end p-0"
          >
            <CloseOutlinedIcon
              onClick={() => {
                handleClose();
              }}
            />
          </Grid>
          <div className="nego-rms">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className="nego-text-rms mb-0">
                Are you dissatisfied with the pricing?
              </p>
              <p className="mb-0 nego-head">
                Initiate negotiations with the carrier regarding the pricing
              </p>
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="pt-3 nego-grid"
            >
              <Input
                color="primary"
                defaultValue="₹ 1,50,000"
                endAdornment={
                  <InputAdornment position="end">
                    <Button
                      className="btn-nego"
                      onClick={() => {
                        // console.log("Send Offer button clicked");
                        setNegotiatemodal("b");
                      }}
                    >
                      Send offer
                    </Button>
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                borderRadius: "12px",
                backgroundColor: "#D9D9D9",
                padding: "8px",
              }}
            >
              <SpeedOutlinedIcon className="nego-color" />
              <span className=" nego-color ">Very good offer</span>
              <p className="mb-0 nego-color">High chance of acceptance</p>
            </Grid>
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NegotiateShipment;
