import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MainLayout } from "../components/layout/MainLayout";
import Login from "../screens/auth/login/Login";
import { Routeconstant } from "./utils/RouteConstant";
import { basename, LOCAL_STORAGE_KEY, marginRms } from "../Config";
import Shipment from "../screens/shipment";
import Order from "../screens/order/index";
import ShipmentDetails from "../screens/shipment/components/ShipmentDetails";
import Routing from "../screens/shipment/components/Routing";
import CargoDetails from "../screens/shipment/components/CargoDetails";
import Documentation from "../screens/shipment/components/Documentation";
import ShipmentAnalytics from "../screens/shipment/components/ShipmentAnalytics";
import Enquiry from "../screens/Enquiry";
import Statement from "../screens/statement";
import Users from "../screens/users";
import NoRoute from "../common/NoRoute";
import Nointernet from "../common/Nointernet";
import PrivateRoute from "./component/PrivateRoute";
import PublicRoute from "./component/PublicRoute";

import { AuthContext } from "../store/context/AuthProvider";
import ResetPassword from "../screens/auth/reset/Resetpassword";
import ForgetPassword from "../screens/auth/forget/ForgetPassword.js";
import Dashboard from "../screens/dashboard/Dashboard";
import { localData } from "../services/auth-header";
import ShipmentDetailsPublic from "../screens/publicscreens/ShipmentDetailsPublic";
import Rms from "../screens/rms/Rms";
import CreateRms from "../screens/rms/CreateRms";
import CreateRmsList from "../screens/rms/components/CreateRmsList";
import CargoRates from "../screens/rms/components/CargoRates";
import DocumentRms from "../screens/rms/components/DocumentRms";
import RmsReport from "../screens/rms/components/RmsReport";
import OrderDashboard from "../screens/order/orderDashboard/OrderDashboard";
import OrderStatus from "../screens/order/orderDashboard/OrderStatus";
import CreateRates from "../screens/rms/components/CreateRates";
import QuoteRates from "../screens/rms/components/QuoteRates";
import OrderDetails from "../screens/order/orderDashboard/orderdetails/OrderDetails";

import RmsMargin from "../screens/rmsMargn/RmsMargin";
import CreateRmsMargin from "../screens/rmsMargn/CreateRmsMargin";
import CreateRmsListMargin from "../screens/rmsMargn/components/CreateRmsListMargin";
import CargoRatesMargin from "../screens/rmsMargn/components/CargoRatesMargin";
import DocumentRmsMargin from "../screens/rmsMargn/components/DocumentRmsMargin";
import RmsReportMargin from "../screens/rmsMargn/components/RmsReportMargin";
import CreateRatesMargin from "../screens/rmsMargn/components/CreateRatesMargin";
import QuoteRatesMargin from "../screens/rmsMargn/components/QuoteRatesMargin";

import Enquirydashboard from "../screens/Enquiry/Enquirydashboard";
import Enquirypreview from "../screens/Enquiry/Enquirypreview";
import OrderTrack from "../screens/order/orderDashboard/orderdetails/Routes/OrderTrack";
import Reports from "../screens/order/reports";
import SystemLogs from "../screens/order/logs/SystemLogs";
import AdminSettings from "../screens/adminSettings/AdminSettings";
import OrganizationMapping from "../screens/users/component/OrganizationMapping";
import { lazy, Suspense } from "react";
import { Puff } from "react-loader-spinner";
const Navigation = () => {
  const context = useContext(AuthContext);
  const [online, setOnline] = useState(window.navigator.onLine);
  const Track = lazy(() =>
    import("../screens/auth/login/trackyourshipment/Track.js")
  );
  useEffect(() => {
    // console.log("useEffect 5");
    window.addEventListener("offline", () => setOnline(false));
    window.addEventListener("online", () => setOnline(true));
  }, []);
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  function Loading() {
    return (
      <div className="d-flex justify-content-center align-items-center w-100 h-100">
        {/* <CircularProgress color="inherit" /> */}
        <Puff
          height="80"
          width="80"
          radius={1}
          color="#0a2d44"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  function lazySuspense(child) {
    return <Suspense fallback={<Loading />}>{child}</Suspense>;
  }
  return (
    <BrowserRouter basename={basename}>
      {online ? (
        <Routes>
          {/* log in route */}
          <Route
            path={Routeconstant.LOGIN}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          {/*Public Shipment Details from track your shipment*/}
          <Route
            path={Routeconstant.SHIPMENTDETAILS}
            element={
              <PublicRoute>
                <ShipmentDetailsPublic />
              </PublicRoute>
            }
          />

          <Route
            path={Routeconstant.TRACKYOURSHIPMENT}
            element={<PublicRoute>{lazySuspense(<Track />)}</PublicRoute>}
          />
          {/* reset password */}
          <Route
            path={Routeconstant.RESETPASSWORD}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />

          {/* forget password */}
          <Route
            path={Routeconstant.FORGETPASSWORD}
            element={
              <PublicRoute>
                <ForgetPassword />
              </PublicRoute>
            }
          />

          {/* main layout includes all */}
          <Route
            path="/"
            element={
              <PrivateRoute>
                <MainLayout />
              </PrivateRoute>
            }
          >
            {/* dashboard route */}
            <Route path={Routeconstant.LANDING_PAGES} element={<Dashboard />} />

            {/* shipment listing */}
            <Route path={Routeconstant.SHIPMENT_LISTING} element={<Shipment />}>
              <Route
                path={Routeconstant.DETAILS}
                element={<ShipmentDetails />}
              />
              <Route path={Routeconstant.ROUTING} element={<Routing />} />
              <Route
                path={Routeconstant.CARGO_DETAILS}
                element={<CargoDetails />}
              />
              <Route
                path={Routeconstant.DOCUMENTATION}
                element={<Documentation />}
              />
            </Route>

            {/* Shipment Analytics */}
            <Route
              path={Routeconstant.SHIPMENT_ANALYTICS}
              element={<ShipmentAnalytics />}
            ></Route>

            {/* Order listing */}
            {/* <Route path={Routeconstant.ORDER_LISTING} element={<Order />}>
              <Route
                path={Routeconstant.ORDER_DETAILS}
                element={<OrderDetails />}
              />
              <Route
                path={Routeconstant.ORDER_ROUTING}
                element={<OrderRouting />}
              />
              <Route
                path={Routeconstant.ORDER_CARGO_DETAILS}
                element={<OrderCargoDetails />}
              />
              <Route
                path={Routeconstant.ORDER_DOCUMENTATION}
                element={<OrderDocumentation />}
              />
            </Route> */}

            {/* Order Analyics */}
            {/* <Route
              path={Routeconstant.ORDER_ANALYTICS}
              element={
                <PrivateRoute>
                  <OrderAnalytics />
                </PrivateRoute>
              }
            ></Route> */}

            {/* Enquiry */}
            <Route
              path={Routeconstant.ENQUIRY}
              element={
                <PrivateRoute>
                  <Enquiry />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path={Routeconstant.ENQUIRYDASHBOARD}
              element={
                <PrivateRoute>
                  <Enquirydashboard />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path={Routeconstant.ENQUIRYPREVIEW}
              element={
                <PrivateRoute>
                  <Enquirypreview />
                </PrivateRoute>
              }
            ></Route>

            {/* RMS */}
            {marginRms ? (
              <>
                <Route
                  path={Routeconstant.RMS}
                  element={
                    <PrivateRoute>
                      <RmsMargin />
                    </PrivateRoute>
                  }
                />
                {/* CREATE-RMS */}
                <Route
                  path={Routeconstant.CREATERMS}
                  element={
                    <PrivateRoute>
                      <CreateRmsMargin />
                    </PrivateRoute>
                  }
                />
                {/* CREATELIST-RMS */}
                <Route
                  path={Routeconstant.CREATERMSLIST}
                  element={
                    <PrivateRoute>
                      <CreateRmsListMargin />
                    </PrivateRoute>
                  }
                />
                {/* CARGORATES-RMS */}
                <Route
                  path={Routeconstant.CARGORATES}
                  element={
                    <PrivateRoute>
                      <CargoRatesMargin />
                    </PrivateRoute>
                  }
                />
                {/* Document-RMS */}
                <Route
                  path={Routeconstant.DOCUMENTRMS}
                  element={
                    <PrivateRoute>
                      <DocumentRmsMargin />
                    </PrivateRoute>
                  }
                />
                {/* Report-RMS */}
                <Route
                  path={Routeconstant.RMSREPORT}
                  element={
                    <PrivateRoute>
                      <RmsReportMargin />
                    </PrivateRoute>
                  }
                />
                {/* Create-Rates */}
                <Route
                  path={Routeconstant.CREATERATES}
                  element={
                    <PrivateRoute>
                      <CreateRatesMargin />
                    </PrivateRoute>
                  }
                />
                {/* Create-Quotes */}
                <Route
                  path={Routeconstant.QUOTESRATES}
                  element={
                    // <PrivateRoute>
                    <QuoteRatesMargin />
                    // </PrivateRoute>
                  }
                />
              </>
            ) : (
              <>
                <Route
                  path={Routeconstant.RMS}
                  element={
                    <PrivateRoute>
                      <Rms />
                    </PrivateRoute>
                  }
                />
                {/* CREATE-RMS */}
                <Route
                  path={Routeconstant.CREATERMS}
                  element={
                    <PrivateRoute>
                      <CreateRms />
                    </PrivateRoute>
                  }
                />
                {/* CREATELIST-RMS */}
                <Route
                  path={Routeconstant.CREATERMSLIST}
                  element={
                    <PrivateRoute>
                      <CreateRmsList />
                    </PrivateRoute>
                  }
                />
                {/* CARGORATES-RMS */}
                <Route
                  path={Routeconstant.CARGORATES}
                  element={
                    <PrivateRoute>
                      <CargoRates />
                    </PrivateRoute>
                  }
                />
                {/* Document-RMS */}
                <Route
                  path={Routeconstant.DOCUMENTRMS}
                  element={
                    <PrivateRoute>
                      <DocumentRms />
                    </PrivateRoute>
                  }
                />
                {/* Report-RMS */}
                <Route
                  path={Routeconstant.RMSREPORT}
                  element={
                    <PrivateRoute>
                      <RmsReport />
                    </PrivateRoute>
                  }
                />
                {/* Create-Rates */}
                <Route
                  path={Routeconstant.CREATERATES}
                  element={
                    <PrivateRoute>
                      <CreateRates />
                    </PrivateRoute>
                  }
                />
                {/* Create-Quotes */}
                <Route
                  path={Routeconstant.QUOTESRATES}
                  element={
                    // <PrivateRoute>
                    <QuoteRates />
                    // </PrivateRoute>
                  }
                />
              </>
            )}
            {/* Statement of Account */}
            <Route
              path={Routeconstant.STATEMENT}
              element={<Statement />}
            ></Route>
            {/* <Route
              path={Routeconstant.CREATE_ORDER}
              element={<Order />}
            ></Route>
            <Route
              path={Routeconstant.ORDER_DASHBOARD}
              element={<OrderDashboard />}
            ></Route>
            <Route
              path={Routeconstant.ORDER_STATUS}
              element={<OrderStatus />}
            ></Route>
            <Route
              path={Routeconstant.ORDER_DETAILS}
              element={<OrderDetails />}
            ></Route>
            <Route path={Routeconstant.ORDER_REPORTS} element={<Reports />} />
            <Route
              path={Routeconstant.ORDER_TRACK}
              element={<OrderTrack />}
            ></Route> */}
            {/* <Route
              path={Routeconstant.SYSTEM_LOGS}
              element={<SystemLogs />}
            ></Route> */}
            {/*admin settings */}
            {/* <Route
              path={Routeconstant.ADMIN_SETTINGS}
              element={<AdminSettings />}
            ></Route> */}

            {/* user logs */}
            {storedUserData?.Role?.includes("Admin") && (
              <Route path={Routeconstant.USER_LOGS} element={<Users />}></Route>
            )}
            {storedUserData?.Role?.includes("Admin") && (
              <Route
                path={Routeconstant.USER_Orgination_mapping}
                element={<OrganizationMapping />}
              ></Route>
            )}
          </Route>
          {/* No route */}
          <Route path="*" element={<NoRoute />} />
        </Routes>
      ) : (
        <Nointernet />
      )}
    </BrowserRouter>
  );
};

export default Navigation;
