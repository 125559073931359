import React, { useEffect, useState, useRef, useContext } from "react";
import "../shipment/shipment.css";
import "../rms/rms.css";
import { IconButton, Tabs, Tab, Tooltip } from "@mui/material";
import { basename } from "../../Config";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import instance from "../../api/Interceptor";
import dayjs from "dayjs";
import FilterInquiryModel from "./FilterInquiryModel.jsx";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Backdrop from "@mui/material/Backdrop";
import { AuthContext } from "../../store/context/AuthProvider";

const Enquirydashboard = () => {
  const navigate = useNavigate();
  const hasmoreData = useRef(10);
  const [showmoreData, setShowMoreData] = useState(true);
  const [inquiryData, setInquiryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showmoreLoader, setShowMoreLoader] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [sortmodal, setSortmodal] = useState(false);
  const Quotation = useRef(null);
  const indexOfCard = useRef(null);
  const [BackdropOpen, setBackdropOpen] = useState(false);
  const [filterModalData, setFilterModalData] = useState({
    Origin: "",
    Destination: "",
    TransportMode: "",
  });
  const showMoreClicked = useRef(false);
  const filtterDAta = useRef({
    Origin: "",
    Destination: "",
    TransportMode: "",
  });
  const context = useContext(AuthContext);

  const handleInquiryClick = (inquiryNo) => {
    navigate(`/Enquirypreview/${inquiryNo}`);
  };
  const handleQuotationClick = (item) => {
    context.setQuotationID(item.QuotationId);
    navigate(`/createrms/${item.QuotationId}`, { state: item });
  };
  //   const AcceptRejecClickHandler = (Quotation, status, reason) => {
  //     const body = {
  //       "Status": status,
  //       "RejectReason": reason,
  //       "QuotationNo": Quotation.current,
  //     }
  //     instance.post("api/v1/Quotation/UpdateQuoteStatus", body)
  //       .then((res) => {
  //         getQuoteData[indexOfCard.current].QuoteStatus = status
  //         getQuoteData[indexOfCard.current].RejectReason = reason
  //         setQuoteRmsData((prev) => [...prev])
  //       }).catch((err) => {
  //         console.log(err)
  //       }).finally(() => {
  //         setBackdropOpen(false)
  //       })
  //   }

  const handlesearch = (e) => {
    const searchValue = e.target.value.trim();
    setSearchValue(searchValue);
  };
  // useEffect(() => {
  //   getRmsDetails();
  // }, []);

  const inputValidation = (input) => {
    const regex = new RegExp("^[^<>()*]*$");
    return regex.test(input);
  };

  useEffect(() => {
    hasmoreData.current = 10;
    setSearchValue("");
    setFilterModalData({
      Origin: "",
      Destination: "",
      TransportMode: "",
    });
    filtterDAta.current = {
      Origin: "",
      Destination: "",
      TransportMode: "",
    };
    getInquiryDetails("");
  }, []);

  // Enquiry api call
  const getInquiryDetails = (search) => {
    if (showMoreClicked.current === false) setLoading(true);
    const body = {
      sortOrder: "",
      pageNumber: 0,
      pageSize: hasmoreData.current, // Change the page size to load more data
      sortCriteria: "",
      inquiryStatus: "",
      searchtext: "",
      transortmode: filtterDAta.current.TransportMode ?? "",
      origin: filtterDAta.current.Origin ?? "",
      destination: filtterDAta.current.Destination ?? "",
      inquiryNumber: search ?? "",
    };
    instance
      .post("api/v1/Inquiry/GetAllInquires", body)
      .then((res) => {
        setInquiryData(res.data.enquiryModel ?? []);
        if (res?.totalCount <= hasmoreData.current) {
          setShowMoreData(false); // Hide the "show more" button when all data is loaded
        } else {
          setShowMoreData(true); // Keep the button visible
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setShowMoreLoader(false);
        showMoreClicked.current = false;
      });
  };

  const loadMoreData = () => {
    hasmoreData.current += 10; // Increase the page size to load more data
    showMoreClicked.current = true;
    setShowMoreLoader(true);
    getInquiryDetails("");
  };

  return (
    <>
      {sortmodal ? (
        <FilterInquiryModel
          setSortmodal={setSortmodal}
          sortmodal={sortmodal}
          setFilterModalData={setFilterModalData}
          filterModalData={filterModalData}
          getInquiryDetails={getInquiryDetails}
          filtterDAta={filtterDAta}
          setSearchValue={setSearchValue}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={BackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container-fluid">
        <div className="row mb-3">
          <p className="layout-head mb-2 px-4">General Enquiry</p>
          <div className="d-flex  flex-row justify-content-center align-items-center">
            {/* filter button */}
            <div
              className={`col-lg-1 col-md-1 col-xs-1 d-flex justify-content-center align-items-center filter-div ${
                Object.values(filtterDAta.current).filter((element) => {
                  if (element?.length > 0) return 1;
                }).length > 0
                  ? "color-style"
                  : ""
              }`}
            >
              <div style={{ marginRight: "10%" }}>
                <FilterAltOutlinedIcon
                  style={{ cursor: "pointer" }}
                  color={`${
                    Object.values(filtterDAta.current).filter((element) => {
                      if (element?.length > 0) return 1;
                    }).length > 0
                      ? "third"
                      : "primary"
                  }`}
                  onClick={() => {
                    setSortmodal(true);
                  }}
                />
              </div>
              <div className=" d-flex cursorPointer ">
                {Object.values(filtterDAta.current).filter((element) => {
                  if (element?.length > 0) return 1;
                }).length > 0 && (
                  <div className="d-flex ps-2">
                    <div style={{ border: "1px solid white" }} />
                    <IconButton size="small" style={{ marginLeft: "20%" }}>
                      <CloseOutlinedIcon
                        color="third"
                        onClick={() => {
                          setFilterModalData((prev) => {
                            return {
                              ...prev,
                              Origin: "",
                              Destination: "",
                              TransportMode: "",
                            };
                          });
                          filtterDAta.current = {
                            Origin: "",
                            Destination: "",
                            TransportMode: "",
                          };
                          getInquiryDetails();
                        }}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            {/* search bar */}
            <div className="col-lg-10 col-md-11 col-xs-11">
              <div className="headersearch searchShipmentDiv ">
                <input
                  type="text"
                  className="form-control "
                  placeholder={"Search your Inquiry Number"}
                  value={searchValue}
                  onChange={handlesearch}
                  onKeyUp={(e) => {
                    if (e.key === "Enter" && inputValidation(searchValue)) {
                      getInquiryDetails(searchValue);
                    }
                  }}
                />
                <img
                  src={`/${basename}/assets/search_icon.svg`}
                  style={{ color: "rgba(var(--text-heading-dark))" }}
                  className="searchIcon"
                  alt=""
                />
                {searchValue && (
                  <CloseOutlinedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      cursor: "pointer",
                    }}
                    className="searchClearIcon"
                    onClick={() => {
                      setSearchValue("");
                      getInquiryDetails("");
                    }}
                  />
                )}
              </div>
            </div>
            {/* get rate */}
            <div className="col-md-1 col-xs-1 buttons-container">
              <IconButton size="large" onClick={() => navigate("/enquiry")}>
                Create Enquiry
              </IconButton>
            </div>
          </div>
          {inputValidation(searchValue) ? (
            ""
          ) : (
            <div
              className="validation-error"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Special characters are not allowed.
            </div>
          )}
        </div>
      </div>

      {/* Inquiry table */}
      <div className="tableWrap">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="font-table">
                Inquiry Number
              </th>
              <th scope="col" className="font-table">
                Transport Mode
              </th>
              <th scope="col" className="font-table">
                Origin Port
              </th>
              <th scope="col" className="font-table">
                Destination Port
              </th>
              <th scope="col" className="font-table">
                INCO terms
              </th>
              <th scope="col" className="font-table">
                Cargo type
              </th>
              <th scope="col" className="font-table">
                Cargo ready date
              </th>
              <th scope="col" className="font-table">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                {new Array(6).fill(1).map(() => {
                  return (
                    <tr>
                      {new Array(11).fill(1).map(() => {
                        return (
                          <td>
                            <Skeleton
                              animation="wave"
                              width={210}
                              height={60}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {inquiryData.length === 0 ? (
                  <>
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "100%",
                      }}
                    >
                      NO DATA FOUND
                    </div>
                  </>
                ) : (
                  <>
                    {inquiryData.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            cursor: "pointer",
                            color: "var(--color-mile)",
                          }}
                          onClick={() =>
                            handleInquiryClick(item.Inquiry_Number)
                          }
                        >
                          {" "}
                          {item.Inquiry_Number}
                        </td>
                        <td>{item.Inquiry_TransportMode}</td>
                        <td>{item.Inquiry_OriginPort}</td>
                        <td>{item.Inquiry_DestinationPort}</td>
                        <td>{item.Inquiry_IncoTerm}</td>
                        <td>{item.Inquiry_CargoType}</td>
                        <td>
                          {dayjs(item?.Inquiry_CreatedOn).format("DD-MM-YYYY")}
                        </td>
                        <td>{item?.Inquiry_Status}</td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        {showmoreData && !loading && inquiryData.length > 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: "12px" }}
          >
            {showmoreLoader ? (
              <CircularProgress />
            ) : (
              <Button variant="outlined" onClick={loadMoreData}>
                show more
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Enquirydashboard;
