import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import BtnOutline from "../../components/button/BtnOutline";
import BtnFilled from "../../components/button/BtnFilled";
import MapcompDashboard from "./MapcompDashboard";
import LightTypography from "../../components/LightTypography";
import TableTypo from "../../components/typograpy/tabletypo";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import { DateFormat } from "../../utils/moment";
import { basename } from "../../Config";
import IconButton from "@mui/material/IconButton";
import { Skeleton } from "@mui/material";
import { AuthContext } from "../../store/context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import urlResolver from "../../utils/urlResolver";
import { useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import CustomTooltip from "../../utils/CustomTooltip";

export default function Upcoming() {
  const [isloading, setisLoading] = useState(true);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [upcomingShipmentList, setUpcomingShipmentList] = useState([]);
  const [upcomingShipmentLocationList, setUpcomingShipmentLocationList] =
    useState([]);
  const hasmoredata = useRef(true);
  const insData = useRef(0);
  useEffect(() => {
    getUpcomingShipment();
  }, []);

  const getUpcomingShipment = () => {
    if (insData.current === 0) setisLoading(true);
    insData.current += 5;
    const body = {
      ShipmentId: "",
      ETAFrom: "",
      ETATo: "",
      ETDFrom: "",
      ETDTo: "",
      ATDFrom: "",
      ATDTo: "",
      ATAFrom: "",
      ATATo: "",
      ShowDeveloperResponse: true,
      DateFrom: "",
      DateTo: "",
      Status: "",
      CurrentPage: 0,
      PageSize: insData.current,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_UPCOMINGSHIPMENT, body)
      .then((res) => {
        // console.log(res, "response of upcoming.js");
        setisLoading(false);
        if (res?.data?.length === res?.totalCount) {
          hasmoredata.current = false;
        }
        setUpcomingShipmentList(res?.data ?? []);
        setUpcomingShipmentLocationList(
          res?.data[0]?.ShipmentlocationCollection ?? []
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const transportImg = (locate) => {
    if (locate === "SEA") {
      return (
        <img
          style={{ height: "23px", width: "21px" }}
          src={urlResolver("assets/ship.svg")}
        />
      );
    } else if (locate === "AIR") {
      return (
        <img
          style={{ height: "23px", width: "21px" }}
          src={urlResolver("assets/Air.svg")}
        />
      );
    } else if (locate === "ROA") {
      return (
        <img
          style={{ height: "23px", width: "21px" }}
          src={urlResolver("assets/road.svg")}
        />
      );
    } else if (locate === "RAI") {
      return (
        <img
          style={{ height: "23px", width: "21px" }}
          src={urlResolver("assets/rail.svg")}
        />
      );
    } else if (locate === "COU") {
      return (
        <img
          style={{ height: "23px", width: "21px" }}
          src={urlResolver("assets/courier.svg")}
        />
      );
    }
  };
  return (
    <>
      <div className="map-container">
        <MapcompDashboard />
      </div>
      <div className="upcoming-container">
        <div className="upcoming-buttons">
          <div className="shipment-button">
            Upcoming Shipments{" "}
            <CustomTooltip
              of="Dashboard"
              forKey="Upcoming_Shipments"
              sx={{ color: "white" }}
            />
          </div>
        </div>
        <div className="upcoming-data" id="scrollableDiv">
          {isloading ? (
            <div className="skeleton-div">
              <Skeleton style={{ height: "7rem", width: "16rem" }} />
              <Skeleton style={{ height: "7rem", width: "16rem" }} />
            </div>
          ) : upcomingShipmentList?.length ? (
            <InfiniteScroll
              dataLength={upcomingShipmentList?.length} //if this value change next method will fire
              next={getUpcomingShipment}
              hasMore={hasmoredata.current} //if true loader will appear
              loader={
                <div className="skeleton-div">
                  <Skeleton style={{ height: "7rem", width: "95%" }} />
                  <Skeleton style={{ height: "7rem", width: "95%" }} />
                </div>
              }
              scrollableTarget="scrollableDiv"
            >
              {upcomingShipmentList.map((item, index) => {
                return (
                  <div className="card upcoming-data-card" key={index}>
                    <div className="card-body upcoming-card-body-container">
                      <div className="shipment-image-container">
                        <div
                          className="shipment-number-container"
                          onClick={() => {
                            context.setShipmentId(item.ShipmentID);
                            context.setGridViewShipment(false);
                            navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                              state: {
                                ShipmentId: item.ShipmentID,
                                ETA: item.ETADate,
                                ETD: item.ETDDate,
                                ORIGIN: item.OriginPortName,
                                DESTINATION: item.DestinationPortName,
                              },
                            });
                          }}
                        >
                          {item?.ShipmentID ?? "N/A"}
                        </div>
                        <div className="image-container">
                          {transportImg(item?.TransportMode)}
                          {/* <img
                            alt="ship-icon"
                            style={{ height: "23px", width: "21px" }}
                            src={urlResolver("assets/ship.svg")}
                          /> */}
                        </div>
                      </div>
                      <div className="upcoming-destination-eta-container">
                        <div className="destination-container">
                          <p
                            style={{
                              margin: 0,
                              color: "var(--color-miscellaneous-4)",
                              fontSize: "var(--fs-small)",
                              fontWeight: 400,
                            }}
                          >
                            Destination
                          </p>
                          <p
                            style={{
                              margin: 0,
                              color: "var(--color-miscellaneous-2)",
                              fontSize: "var(--fs-mid)",
                              fontWeight: 400,
                              paddingTop: "5px",
                            }}
                          >
                            {item.DestinationPortName}
                          </p>
                        </div>
                        <div className="eta-container">
                          <p
                            style={{
                              margin: 0,
                              color: "var(--color-miscellaneous-4)",
                              fontSize: "var(--fs-small)",
                              fontWeight: 400,
                            }}
                          >
                            ETA
                          </p>
                          <p
                            style={{
                              margin: 0,
                              color: "var(--color-miscellaneous-2)",
                              fontSize: "var(--fs-mid)",
                              fontWeight: 400,
                              paddingTop: "5px",
                            }}
                          >
                            {DateFormat(item?.ETA)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          ) : (
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ marginTop: "5.3rem" }}
            >
              No upcoming shipments
            </div>
          )}
        </div>
      </div>
    </>
  );
}
