import React, { useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";

import FormControl from "@mui/material/FormControl";

import OutlinedInput from "@mui/material/OutlinedInput";

import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const FilterBody = ({ formData, setFormData }) => {
    const pattern = /^[A-Za-z\s]+$/;
  return (
    <Grid container spacing={2}>
      {/* ------name------- */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={0}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Typography>Organization name</Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                value={formData?.OrganizationName}
                placeholder="Please enter name"
                onChange={(e) => {
                  const Orgname = e.target.value;
                  if ((pattern.test(Orgname) && Orgname.length <= 50) ||  Orgname.length === 0) {
                    setFormData((prev) => {
                      return { ...prev, OrganizationName: Orgname };
                    });
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      {/* -------Code------ */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={0}>
          <Grid item lg={4} md={4} sm={4} xs={4}>
            <Typography>Organization Code</Typography>
          </Grid>
          <Grid item lg={8} md={8} sm={8} xs={8}>
            <FormControl size="small" fullWidth>
              <OutlinedInput
                value={formData?.OrganizationCode}
                placeholder="Please enter code"
                onChange={(e) => {
                  const Orgcode = e.target.value;
                  if ((/^[A-Za-z0-9\s]+$/.test(Orgcode) && Orgcode.length <= 10 ) || Orgcode.length === 0) {
                    setFormData((prev) => {
                      return { ...prev, OrganizationCode: Orgcode };
                    });
                  }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function FilterModal({ setOpen, open }) {
  const [formData, setFormData] = useState({
    OrganizationName: "",
    OrganizationCode: "",
  });
  // method to close the modal
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            action={
              <Button
                onClick={() => {
                  setFormData({
                    OrganizationName: "",
                    OrganizationCode: "",
                  });
                }}
              >
                Reset all
              </Button>
            }
            subheader={
              <Button
                onClick={() => {
                  handleClose();
                }}
                color={"primary"}
              >
                <KeyboardBackspaceIcon /> Back
              </Button>
            }
          />
          <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
          <CardContent>
            <FilterBody setFormData={setFormData} formData={formData} />
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              onClick={() => {
                // api call will be mention here
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
}
