import { customerName } from "../Config";

const UserAccess = () => {
    // Fetch user data and set the role based on the data
    let userRole = ""
    const userData = JSON.parse(localStorage.getItem("userData")).Role.split(",");
    if (customerName === "SFLInsight") {
        if (userData.includes("Admin")) {
            userRole = "Admin"; 
        } 
        else if (userData.includes("Sales"))  {
            userRole = "Approver";
        }
        else if (userData.includes("Buyer") || userData.includes("Supplier")) {
            userRole = "User";
        } 
        return userRole;
    }
}
export{UserAccess};
