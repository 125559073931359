import React, { useState, useEffect } from "react";
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Typography,
  TableFooter,
  TableBody,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../shipment/shipment.css";
import "../rms.css";
import { useNavigate, useParams } from "react-router-dom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import { basename } from "../../../Config";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { AuthContext } from "../../../store/context/AuthProvider";
import instance from "../../../api/Interceptor";
import "react-datepicker/dist/react-datepicker.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Skeleton from "@mui/material/Skeleton";
import {
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
} from "@mui/material";
const RmsReport = () => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };
  const lineStyle = {
    width: "130px",
    borderBottom: "3px solid var(--color-secondary-dark)", // Add a horizontal line
  };
  const headingStyle = {
    fontSize: "18px", // Adjust the font size as needed
  };
  const textStyle = {
    fontSize: "16px",
    color: "var(--color-secondary-dark)", // Adjust the font size as needed
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { inquiryNo } = useParams();
  const [data, setData] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [QuoteData, setQuoteData] = useState([]);
  const [ContainerData, setContainerData] = useState([]);
  const context = React.useContext(AuthContext);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const type = inquiryNo.charAt(0) === "Q"; // type true means quatation view
  const completeBreakUP = [];
  ContainerData?.forEach((singlecontainer) => {
    singlecontainer?.Rates?.forEach((rate) => {
      completeBreakUP.push({
        quantity: singlecontainer.ContainerCount,
        item: singlecontainer.ContainerISO,
        description: rate.Name,
        rate: rate.Rate,
      });
    });
  });
  let bookinginformation, bookingcontainerInformation;
  const getBookingDetails = () => {
    setLoading(true);
    const body = {
      InquiryNo: inquiryNo,
      CurrentPage: 0,
      PageSize: 10,
      SortColumnName: "",
      SortDirection: "",
    };
    const url = "api/v1/RateManagement/GetRateDetails";
    instance
      .post(url, body)
      .then(({ data }) => {
        setBookingData(data);
        bookinginformation = data;
        bookingcontainerInformation = data[0]?.RatingPacking;
        const quatation = data[0]?.RatingHeader?.QuotationNumber;
        getQuotesDetails(quatation);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQuotesDetails = (QuotationNo) => {
    setLoading(true);
    let information;
    const body = {
      QuotationNo: QuotationNo,
    };
    const url = "api/v1/Quotation/GetQuote";
    instance
      .post(url, body)
      .then(({ data }) => {
        information = data;
        // console.log();
        setData(data);
        const containerDisplayInformation = data[0]?.ContainerTypes;
        console.log(
          containerDisplayInformation,
          "containerDisplayInformation11",
          bookingcontainerInformation
        );
        if (type) setContainerData(data[0]?.ContainerTypes);
        else {
          let data1 = data[0]?.ContainerTypes;
          data1.forEach((containerInformation) => {
            bookingcontainerInformation.forEach((element) => {
              if (element.ContainerType === containerInformation.ContainerISO) {
                containerInformation.ContainerCount = element.ContainerCount;
              }
            });
          });
          setContainerData(data1);
        }
        setQuoteData(data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        let finalData;
        if (type) {
          finalData = { ...(information[0]?.Quotation ?? {}) };
        } else {
          finalData = {
            ...(information[0]?.Quotation ?? []),
            ...(bookinginformation[0]?.RatingHeader ?? {}),
          };
        }
        console.log(finalData, "finalData");
        setData(finalData);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (type) getQuotesDetails(inquiryNo);
    else getBookingDetails();
  }, []);
  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevOpen) => !prevOpen);
  };
  console.log(data, "hello");
  return (
    <>
      <Grid item lg={12} md={6} xs={12} width={"100%"}>
        <Grid container spacing={1}>
          <Grid
            item
            lg={3}
            md={6}
            xs={12}
            className="p-4 font-shipment"
            sx={{ cursor: "pointer", marginBottom: "1rem" }}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />
            Back to Cargo details
          </Grid>
          <Grid item lg={3} md={6} xs={12} className="p-4 font-shipment">
            <div>
              {" "}
              <span className="head-text text-center font-weight">
                Quotation Number :
              </span>
              <span className="head-text text-center font-rms">
                {" "}
                {data.QuotationId}{" "}
              </span>
            </div>
            {console.log("dataqqq", data)}
          </Grid>
          {type === false && (
            <>
              <Grid item lg={3} md={6} xs={12} className="p-4 font-shipment">
                <div>
                  <b className="head-text text-center">Booking Number :</b>
                  {inquiryNo}
                </div>
              </Grid>
              <Grid item lg={3} md={6} xs={12} className="p-4 font-shipment">
                <div>
                  <b className="head-text text-center">CWBooking Number :</b>
                  {data.CWBookingNumber}
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        {/* origin and destination information */}

        {/* card information */}
        <Grid item lg={12} md={12} xs={12} className="p-4">
          <Grid container spacing={1}>
            <Grid item lg={1} md={1} xs={2}>
              <p className="m-0 font-shipment"> Rate Details</p>
            </Grid>
            <Grid
              item
              lg={11}
              xs={10}
              className="d-flex  font-shipment dash-rms"
            >
              <Divider
                variant="center"
                flexItem
                sx={{
                  backgroundColor: " var(--color-secondary-light)",
                  width: "100%",
                  marginTop: "0.8rem",
                }}
              />
            </Grid>
            {loading ? (
              <>
                <Grid item xs={12} width={"100%"}>
                  <Skeleton variant="rounded" height={"6em"} />
                </Grid>
              </>
            ) : data?.length === 0 ? (
              <Grid item xs={12} width={"100%"}>
                <div className="center-message font-change">No data Found!</div>
              </Grid>
            ) : (
              <>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  className="p-4"
                  style={{
                    backgroundColor: "white",
                    boxShadow: " 0px 1px 10px rgba(0, 0, 0, 0.25)",
                    borderRadius: "8px",
                    padding: "0rem",
                    position: "relative",
                    marginTop: "1rem",
                    marginLeft: "0px",
                    paddingBottom: "20px",
                  }}
                >
                  <Grid item lg={2} md={6} xs={12}>
                    <div>
                      <p className="mb-1 font-shipment  head-text text-center">
                        Origin
                      </p>
                      <p className="mb-0 font-shipment head-text-para text-center">
                        <LocationOnOutlinedIcon color="primary" />
                        {data?.OriginName?.length ? data?.OriginName : "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <div>
                      {" "}
                      <p className="mb-1 font-shipment  head-text text-center">
                        Transport Mode
                      </p>{" "}
                      <div
                        style={containerStyle}
                        className="d-flex align-items-center justify-content-between"
                      >
                        {/* <HorizontalRuleOutlinedIcon color="primary" /> */}
                        <div style={lineStyle}></div>
                        {data?.TransportMode === "AIR" && (
                          <img
                            src={`/${basename}/assets/Air.svg`}
                            alt="AIR"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                        {data?.TransportMode === "COU" && (
                          <img
                            src={`/${basename}/assets/courier.svg`}
                            alt="COU"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                        {data?.TransportMode === "SEA" && (
                          <img
                            src={`/${basename}/assets/ship.svg`}
                            alt="SEA"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                        {data?.TransportMode === "RAO" && (
                          <img
                            src={`/${basename}/assets/road.svg`}
                            alt="RAO"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                        {data?.TransportMode === "RAIL" && (
                          <img
                            src={`/${basename}/assets/rail.svg`}
                            alt="RAIL"
                            style={{ width: "30px", height: "30px" }}
                          />
                        )}
                        {/* <HorizontalRuleOutlinedIcon color="primary"/> */}
                        <div style={lineStyle}></div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <div>
                      <p className="mb-1 head-text text-center">Destination</p>
                      <p className="mb-0 head-text-para text-center">
                        <LocationOnOutlinedIcon color="primary" />
                        {data?.DestinationName?.length
                          ? data?.DestinationName
                          : "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={1} md={6} xs={12}>
                    <div>
                      <p className="mb-1 head-text text-center">
                        Service Level
                      </p>
                      <p className="mb-0 head-text-para text-center">
                        {data?.ServiceLevel?.length
                          ? data?.ServiceLevel
                          : "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <div>
                      <p className="mb-1 head-text text-center">
                        EST travel time
                      </p>
                      <p className="mb-0 head-text-para text-center">
                        {data?.TransitTime?.length > 0
                          ? `${data?.TransitTime} days`
                          : "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={1} md={6} xs={12}>
                    <div>
                      <p className="mb-1 head-text text-center">Carrier Name</p>
                      <p className="mb-0 head-text-para text-center">
                        {data?.CarrierName ?? "N/A"}
                      </p>
                    </div>
                  </Grid>
                  <Grid item lg={2} md={6} xs={12}>
                    <div>
                      <p className="mb-1 head-text text-center">Total Rate</p>
                      <p className="mb-0 head-text-para text-center">
                        {data?.CurrencyCode}{" "}
                        {completeBreakUP.reduce((accumulater, element) => {
                          return (accumulater +=
                            element.rate * element.quantity);
                        }, 0) ?? "N/A"}
                        <ExpandCircleDownOutlinedIcon
                          className={isAccordionOpen ? "chageicon180" : ""}
                          color="primary"
                          onClick={handleAccordionToggle}
                        />
                      </p>
                    </div>
                  </Grid>

                  {/* Render the accordion content based on the open state */}
                  {isAccordionOpen && (
                    <div className="w-100 p-5">
                      <div className="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">SNo.</th>
                              <th scope="col">Description</th>
                              <th scope="col">Item</th>
                              <th scope="col">Quantity</th>
                              <th scope="col">Rate</th>
                              <th scope="col">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {completeBreakUP?.map((item, index) => (
                              <tr key={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.description ?? "N/A"}</td>
                                <td>{item.item ?? "N/A"}</td>
                                <td>{item.quantity ?? "N/A"}</td>
                                <td>{item.rate ?? "N/A"}</td>
                                <td>{item.quantity * item.rate ?? "N/A"}</td>
                              </tr>
                            ))}
                            <tr>
                              <th colSpan={5}>Grand Total:</th>
                              <th>
                                <Typography variant="h6" component="span">
                                  {data?.CurrencyCode}{" "}
                                  {completeBreakUP.reduce(
                                    (accumulater, element) => {
                                      return (accumulater +=
                                        element.rate * element.quantity);
                                    },
                                    0
                                  ) ?? "N/A"}
                                </Typography>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan={1}>Remarks:</th>
                              <th colSpan={5}>
                                <div
                                  style={{
                                    maxHeight: "87px",
                                    overflowY: "auto",
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    component="span"
                                    style={{ fontSize: "18px" }}
                                  >
                                    {data?.Remarks ?? ""}
                                  </Typography>
                                </div>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan={1}>commodity:</th>
                              <th>
                                <Typography
                                  variant="h6"
                                  component="span"
                                  style={{ fontSize: "18px" }}
                                >
                                  {data?.CommodityDetails ?? ""}
                                </Typography>
                              </th>
                            </tr>
                            <tr>
                              <th colSpan={1}>Weight:</th>
                              <th>
                                <Typography
                                  variant="h6"
                                  component="span"
                                  style={{ fontSize: "18px" }}
                                >
                                  {`${data?.GrossWeight ?? ""} ${
                                    data?.WeightUnit ?? ""
                                  }`}
                                </Typography>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </Grid>

        {/* Cargo Details */}
        {type === false && (
          <>
            <Grid item lg={12} md={6} xs={12} className="p-4">
              <Grid container spacing={1}>
                <Grid item lg={1} md={1} xs={2}>
                  <p className="m-0"> Booking Details</p>
                </Grid>
                <Grid item lg={11} xs={10} className="d-flex ali dash-rms">
                  <Divider
                    variant="center"
                    flexItem
                    sx={{
                      backgroundColor: " var(--color-secondary-light)",
                      width: "100%",
                      marginTop: "0.8rem",
                    }}
                  />
                </Grid>
                {loading ? (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{ marginTop: "1%" }}
                  >
                    <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
                      <Skeleton variant="rounded" height={"15em"} />
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                      <Skeleton variant="rounded" height={"15em"} />
                    </Grid>
                    <Grid item lg={3} md={12} sm={12}>
                      <Skeleton variant="rounded" height={"15em"} />
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      style={{ marginTop: "1%" }}
                    >
                      {/* --FIRST-- */}
                      <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
                        <Paper
                          elevation={4}
                          sx={{ padding: "24px", height: "100%" }}
                        >
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="cargoType"
                              label="CARGO TYPE"
                              variant="outlined"
                              value={
                                data?.Carotype?.length ? data?.Carotype : "N/A"
                              }
                              disabled
                            />
                          </FormControl>
                          <br />
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="incoType"
                              label="INCO TERMS"
                              variant="outlined"
                              value={
                                data?.Incoterm?.length ? data?.Incoterm : "N/A"
                              }
                              disabled
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="shipperNo"
                              label="SHIPPER INVOICE NO."
                              variant="outlined"
                              value={
                                data?.ShipperInvoiceNumber?.length
                                  ? data?.ShipperInvoiceNumber
                                  : "N/A"
                              }
                              disabled
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="goodValue"
                              label="GOOD VALUE"
                              variant="outlined"
                              value={
                                data?.Goodsvalue?.length
                                  ? data?.Goodsvalue
                                  : "N/A"
                              }
                              disabled
                            />
                          </FormControl>
                        </Paper>
                      </Grid>
                      {/* --MID-- */}
                      <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                        <Paper
                          elevation={4}
                          sx={{
                            padding: "24px",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <Grid
                            container
                            rowSpacing={1}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <Grid
                              item
                              lg={6}
                              md={12}
                              sm={12}
                              sx={{ width: "100%" }}
                            >
                              {/* <Typography className="pb-3">
                                <LocationOnIcon color="primary" />
                                <span className="card-rms">Origin</span>
                              </Typography> */}
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: "1rem" }}
                                className="details-section"
                              >
                                <TextField
                                  label="ORIGIN PIN/ZIP CODE"
                                  variant="outlined"
                                  name="originPin"
                                  value={
                                    data?.OriginPinCode?.length
                                      ? data?.OriginPinCode
                                      : "N/A"
                                  }
                                  disabled
                                />
                              </FormControl>
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: "1rem" }}
                                className="details-section"
                              >
                                <TextField
                                  name="datepicker"
                                  label="CARGO TYPE DATE"
                                  variant="outlined"
                                  value={
                                    data?.CargoReadyDate?.length
                                      ? data?.CargoReadyDate
                                      : "N/A"
                                  }
                                  disabled
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              lg={6}
                              md={12}
                              sm={12}
                              sx={{ width: "100%" }}
                            >
                              {/* <Typography className="pb-3">
                               <LocationOnIcon color="primary" />
                                <span className="card-rms">Destination</span>
                              </Typography> */}
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: "1rem" }}
                                className="details-section"
                              >
                                <TextField
                                  label="DESTINATION PIN/ZIP CODE"
                                  variant="outlined"
                                  name="destPin"
                                  value={data?.DestinationPinCode ?? "N/A"}
                                  disabled
                                />
                              </FormControl>
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: "1rem" }}
                                className="details-section"
                              >
                                <TextField
                                  name="additionalServices"
                                  value={
                                    data?.AdditionalServices?.length
                                      ? data?.AdditionalServices
                                      : "N/A"
                                  }
                                  label="ADDITIONAL SERVICES"
                                  variant="outlined"
                                  disabled
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            sx={{ width: "100%" }}
                          >
                            <FormControl
                              fullWidth
                              sx={{ paddingBottom: "1rem" }}
                              className="details-section"
                            >
                              <TextField
                                name="goodDesc"
                                value={
                                  data?.GoodsDescription?.length
                                    ? data?.GoodsDescription
                                    : "N/A"
                                }
                                disabled
                                label="GOOD DESCRIPTION."
                                variant="outlined"
                                multiline
                                rows={3}
                              />
                            </FormControl>
                          </Grid>
                        </Paper>
                      </Grid>
                      {/* --LAST-- */}
                      <Grid item lg={3} md={12} sm={12}>
                        <Paper
                          elevation={4}
                          sx={{ padding: "24px", height: "100%" }}
                        >
                          <Typography className="pb-3">
                            {/* <Switch
                              defaultChecked={false}
                              size="small"
                            /> */}
                            <span>Hazardous goods</span>
                          </Typography>
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="imoClass"
                              value={
                                data?.IMOClass?.length ? data?.IMOClass : "N/A"
                              }
                              label="IMO CLASS"
                              variant="outlined"
                              disabled
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="hsCode"
                              value={
                                data?.HSCode?.length ? data?.HSCode : "N/A"
                              }
                              label="HS CODE"
                              variant="outlined"
                              disabled
                              // disabled={!hazardous || !ishazardous}
                            />
                          </FormControl>
                          <FormControl
                            fullWidth
                            sx={{ paddingBottom: "1rem" }}
                            className="details-section"
                          >
                            <TextField
                              name="unNumber"
                              value={
                                data?.UnNumber?.length ? data?.UnNumber : "N/A"
                              }
                              label="UN NUMBER"
                              variant="outlined"
                              disabled
                              // disabled={!hazardous || !ishazardous}
                            />
                          </FormControl>
                        </Paper>
                      </Grid>
                      {console.log("dataa", data)}
                      {/* --BOTTOM-- */}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
        <Grid container className="p-4 pt-0">
          <Grid item lg={1} md={1} xs={2}>
            <p className="m-0 font-shipment"> Package Details</p>
          </Grid>
          <Grid
            item
            lg={11}
            xs={11}
            className="d-flex align-items-center dash-rms"
          >
            <Divider
              variant="left"
              flexItem
              sx={{
                backgroundColor: " var(--color-secondary-light)",
                width: "100%",
                marginTop: "0.8rem",
              }}
            />
          </Grid>
        </Grid>

        {/* table information in other cases */}
        <Grid container className="p-4 pt-0">
          {[
            "LSE",
            "CON",
            "BCN",
            "LCL",
            "BLK",
            "LQD",
            "BBK",
            "OBC",
            "UNA",
          ].includes(savedTransportData) ? (
            <>
              <Grid item lg={12}>
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">
                              Packaging Type
                            </p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">
                              Quantity
                            </p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">
                              Volume Unit
                            </p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">Volume</p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">
                              {" "}
                              Weight Unit
                            </p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">Weight</p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2">
                              Stakable
                            </p>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="table-data" align="center">
                            <Select
                              size="small"
                              sx={{ padding: "0" }}
                              className="MuiSelect-outlined"
                              name="PackagingType"
                              value={data?.PackagingType ?? "N/A"}
                              variant="outlined"
                            >
                              <MenuItem value="Select" disabled>
                                Select
                              </MenuItem>
                              <MenuItem value="Pallets">Pallets</MenuItem>
                              <MenuItem value="Skids">Skids</MenuItem>
                              <MenuItem value="Crates">Crates</MenuItem>
                              <MenuItem value="boxes">boxes</MenuItem>
                              <MenuItem value="Cartons">Cartons</MenuItem>
                              <MenuItem value="cases">cases</MenuItem>
                              <MenuItem value="Packages">Packages</MenuItem>
                              <MenuItem value="Drums">Drums</MenuItem>
                              <MenuItem value="Bags">Bags</MenuItem>
                              <MenuItem value="Rolls">Rolls</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <TextField
                              type="number"
                              name="Quantity"
                              value={data?.Quantity ?? "N/A"}
                              variant="outlined"
                              size="small"
                              className="custom-textfield"
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Select
                              sx={{ padding: "0" }}
                              size="small"
                              className="MuiSelect-outlined"
                              type="text"
                              name="VolumeUnit"
                              value={data?.VolumeUnit ?? "N/A"}
                              variant="outlined"
                            >
                              <MenuItem value="Select" disabled>
                                Select
                              </MenuItem>
                              <MenuItem value="m3">m3</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <TextField
                              type="number"
                              name="Volume"
                              value={data?.Volume ?? "N/A"}
                              variant="outlined"
                              size="small"
                              className="custom-textfield"
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Select
                              sx={{ padding: "0" }}
                              size="small"
                              className="MuiSelect-outlined"
                              type="text"
                              name="WeightUnit"
                              value={data?.WeightUnit ?? "N/A"}
                              variant="outlined"
                            >
                              <MenuItem value="Select" disabled>
                                Select
                              </MenuItem>
                              <MenuItem value="Kg">Kg</MenuItem>
                              <MenuItem value="Ton">Ton</MenuItem>
                              <MenuItem value="LB">LB</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <TextField
                              type="number"
                              name="Weight"
                              value={data.Weight}
                              variant="outlined"
                              size="small"
                              className="custom-textfield"
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Switch
                              size="small"
                              checked={data?.Stakable ?? "N/A"}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </>
          ) : (
            <>
              {" "}
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
                style={{
                  backgroundColor: "white",
                  boxShadow: " 0px 1px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  padding: "0rem",
                  position: "relative",
                  marginTop: "-1rem",
                  marginLeft: "0px",
                  paddingBottom: "20px",
                  width: "100%",
                }}
              >
                {/* <Paper> */}
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow className="form-control">
                        <TableCell className="tablecell">
                          <p className="head-text text-center pt-2">Quantity</p>
                        </TableCell>

                        <TableCell className="tablecell" align="center">
                          <p className="head-text text-center pt-2">
                            Container Type
                          </p>
                        </TableCell>
                      </TableRow>
                      {loading ? (
                        <TableRow>
                          <TableCell className="table-data" align="center">
                            <Skeleton
                              variant="rounded"
                              width={"25em"}
                              height={"5em"}
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Skeleton
                              variant="rounded"
                              width={"25em"}
                              height={"5em"}
                            />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {ContainerData?.length &&
                            ContainerData?.map((singlecontainer, index) => {
                              console.log("singlecontainer", singlecontainer);
                              return (
                                <TableRow>
                                  <TableCell>
                                    <p className="m-0 package-section">
                                      {singlecontainer?.ContainerCount ?? "N/A"}
                                    </p>
                                  </TableCell>
                                  <TableCell>
                                    <p className="m-0 package-section">
                                      {singlecontainer?.ContainerISO ?? "N/A"}
                                    </p>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </>
                      )}
                    </TableHead>
                  </Table>
                </TableContainer>
                {/* </Paper> */}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
export default RmsReport;
