import React from "react";
import "./dashboard.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import urlResolver from "../../../utils/urlResolver";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { API_PATH } from "../../../constant/apiPath";
import orderInstance from "../../../api/orderInterceptor";
import Card from "./Card";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import CardStatusEdit from "./CardStatusEdit";
import { updatePermissionData } from "../../../redux/orderRedux/orderPermission";
import getpermision from "../Permission/permison";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: 100 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const OrderStatus = () => {
  const [status, setStatus] = useState([]);
  const [page, setPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [buyerFilter, setBuyerFilter] = useState("");
  const location = useLocation();
  const [value, setValue] = useState(location?.state?.tab);
  const [type, setType] = useState(location?.state?.code);
  const [count, setCount] = useState(null);
  const dispatch = useDispatch();
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [indexOfCard, setIndexOfCard] = useState(null);

  const [search, setSearch] = useState(null);

  console.log("page", search);

  const getPlacedStatus = (val) => {
    const body = {
      SearchData: val === "" ? val : search ? search : buyerFilter,
      OrgCodes: "",
      Status: type,
      SortColumnName: "OD_OrderLastEditedDateUTC",
      SortDirection: "desc",
      CurrentPage: 0,
      PageSize: page,
    };
    orderInstance
      .post(API_PATH.GET_ORDER_LIST, body)
      .then((res) => {
        setCount(res?.totalCount);
        setStatus(res?.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setBtnLoading(false);
      });
  };

  const [buyer, setBuyer] = useState([]);

  const getBuyer = () => {
    const body = {
      SearchData: "",
      OrgCodes: "",
      Status: "",
      SortColumnName: "OD_OrderLastEditedDateUTC",
      SortDirection: "desc",
      CurrentPage: 0,
      PageSize: 10,
    };
    orderInstance
      .get(API_PATH.GET_BUYER)
      .then((res) => {
        setBuyer(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const options = buyer.map((indvBuyer) => {
    return indvBuyer;
  });

  useEffect(() => {
    setLoading(true);
    getPlacedStatus();
  }, [type, buyerFilter]);

  useEffect(() => {
    setBtnLoading(true);
    getPlacedStatus();
  }, [page]);

  useEffect(() => {
    getBuyer();
  }, []);

  // in case use reload the page this work to make sure that permission works in order management
  const getPermissionData = async () => {
    const data = await getpermision();
    dispatch(updatePermissionData({ ...data }));
  };
  useEffect(() => {
    if (Object.keys(permisisonOrder).length === 0) {
      getPermissionData();
    }
  }, []);
  return (
    <>
      <Grid container className="order-list-div">
        <Grid item xs={12} sm={12} md={12} lg={12} className="order-type-div">
          <div className="search-buyer-div">
            <div className="searchbar-div">
              <div className="headersearch searchShipmentDiv ">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search Orders"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      getPlacedStatus();
                    }
                  }}
                />
                <img
                  src={urlResolver("assets/search_icon.svg")}
                  style={{ color: "rgba(var(--text-heading-dark))" }}
                  className="searchIcon"
                  onClick={() => getPlacedStatus()}
                />
                {search && (
                  <CloseOutlinedIcon
                    color="primary"
                    style={{
                      position: "absolute",
                      right: "1rem",
                      cursor: "pointer",
                    }}
                    // className="searchClearIcon"
                    onClick={() => {
                      setSearch("");
                      getPlacedStatus("");
                    }}
                  />
                )}
              </div>
            </div>
            <div className="dropdown-div">
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                options={options}
                getOptionLabel={(option) => option?.Name}
                sx={{ width: 300, marginTop: "15px" }}
                onChange={(e, value) => {
                  // console.log("event", value);
                  setBuyerFilter(value?.Code);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Buyer" />
                )}
              />
            </div>
          </div>
          <div className="status-tabs-div">
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  position: "sticky ",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  variant="fullWidth"
                >
                  <Tab
                    label="Placed"
                    {...a11yProps(0)}
                    onClick={() => {
                      setType("PLC");
                      setSearch(null);
                    }}
                  />
                  <Tab
                    label="Loaded"
                    {...a11yProps(1)}
                    onClick={() => {
                      setType("SHP");
                      setSearch(null);
                    }}
                  />
                  <Tab
                    label="Approved"
                    {...a11yProps(2)}
                    onClick={() => {
                      setType("APP");
                      setSearch(null);
                    }}
                  />
                  <Tab
                    label="Cancelled"
                    {...a11yProps(3)}
                    onClick={() => {
                      setType("CAN");
                      setSearch(null);
                    }}
                  />
                  <Tab
                    label="Archived"
                    {...a11yProps(4)}
                    onClick={() => {
                      setType("ARC");
                      setSearch(null);
                    }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <div
                  style={{
                    // backgroundColor: "pink",
                    height: "74vh",
                    overflow: "scroll",
                    padding: "0 1rem 0 1rem",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <>
                      <div className="d-flex justify-content-center pb-4">
                        <CircularProgress
                          size={"2rem"}
                          sx={{
                            color: "var(--color-primary)",
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {status.map((indvOrder, index) => {
                        return (
                          <CardStatusEdit
                            getPlacedStatus={getPlacedStatus}
                            orderDetails={indvOrder}
                            index={index}
                            setIndexOfCard={setIndexOfCard}
                            indexOfCard={indexOfCard}
                          />
                        );
                      })}
                    </>
                  )}
                  <div className="d-flex align-items-center justify-content-center">
                    {page > count ? (
                      ""
                    ) : (
                      <button
                        className="download-report-button"
                        onClick={() => {
                          setPage((prev) => prev + 10);
                        }}
                      >
                        {btnLoading ? (
                          <CircularProgress
                            size={"1rem"}
                            sx={{ color: "var(--color-white)" }}
                          />
                        ) : (
                          "Show more"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <div
                  style={{
                    // backgroundColor: "pink",
                    height: "74vh",
                    overflow: "scroll",
                    padding: "0 1rem 0 1rem",
                  }}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center pb-4">
                      <CircularProgress
                        size={"2rem"}
                        sx={{ color: "var(--color-primary)" }}
                      />
                    </div>
                  ) : (
                    <>
                      {status.map((indvOrder, index) => {
                        return (
                          <CardStatusEdit
                            getPlacedStatus={getPlacedStatus}
                            orderDetails={indvOrder}
                            index={index}
                            setIndexOfCard={setIndexOfCard}
                            indexOfCard={indexOfCard}
                          />
                        );
                      })}
                    </>
                  )}
                  <div className="d-flex align-items-center justify-content-center">
                    {page > count ? (
                      ""
                    ) : (
                      <button
                        className="download-report-button"
                        onClick={() => {
                          setPage(page + 10);
                        }}
                      >
                        {btnLoading ? (
                          <CircularProgress
                            size={"1rem"}
                            sx={{ color: "var(--color-white)" }}
                          />
                        ) : (
                          "Show more"
                        )}
                      </button>
                    )}
                  </div>
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <div
                  style={{
                    // backgroundColor: "pink",
                    height: "74vh",
                    overflow: "scroll",
                    padding: "0 1rem 0 1rem",
                  }}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center pb-4">
                      <CircularProgress
                        size={"2rem"}
                        sx={{ color: "var(--color-primary)" }}
                      />
                    </div>
                  ) : (
                    <>
                      {status.map((indvOrder, index) => {
                        return (
                          <CardStatusEdit
                            getPlacedStatus={getPlacedStatus}
                            orderDetails={indvOrder}
                            index={index}
                            setIndexOfCard={setIndexOfCard}
                            indexOfCard={indexOfCard}
                          />
                        );
                      })}
                    </>
                  )}
                  {page > count ? (
                    ""
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="download-report-button"
                        onClick={() => {
                          setPage(page + 10);
                        }}
                      >
                        {btnLoading ? (
                          <CircularProgress
                            size={"1rem"}
                            sx={{ color: "var(--color-white)" }}
                          />
                        ) : (
                          "Show more"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <div
                  style={{
                    // backgroundColor: "pink",
                    height: "74vh",
                    overflow: "scroll",
                    padding: "0 1rem 0 1rem",
                  }}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center pb-4">
                      <CircularProgress
                        size={"2rem"}
                        sx={{ color: "var(--color-primary)" }}
                      />
                    </div>
                  ) : (
                    <>
                      {status.map((indvOrder, index) => {
                        return (
                          <CardStatusEdit
                            getPlacedStatus={getPlacedStatus}
                            orderDetails={indvOrder}
                            index={index}
                            setIndexOfCard={setIndexOfCard}
                            indexOfCard={indexOfCard}
                          />
                        );
                      })}
                    </>
                  )}
                  {page > count ? (
                    ""
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="download-report-button"
                        onClick={() => {
                          setPage(page + 10);
                        }}
                      >
                        {btnLoading ? (
                          <CircularProgress
                            size={"1rem"}
                            sx={{ color: "var(--color-white)" }}
                          />
                        ) : (
                          "Show more"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={4}>
                <div
                  style={{
                    // backgroundColor: "pink",
                    height: "74vh",
                    overflow: "scroll",
                    padding: "0 1rem 0 1rem",
                  }}
                >
                  {loading ? (
                    <div className="d-flex justify-content-center pb-4">
                      <CircularProgress
                        size={"2rem"}
                        sx={{ color: "var(--color-primary)" }}
                      />
                    </div>
                  ) : (
                    <>
                      {status.map((indvOrder, index) => {
                        return (
                          <CardStatusEdit
                            getPlacedStatus={getPlacedStatus}
                            orderDetails={indvOrder}
                            index={index}
                            setIndexOfCard={setIndexOfCard}
                            indexOfCard={indexOfCard}
                          />
                        );
                      })}
                    </>
                  )}
                  {page > count ? (
                    ""
                  ) : (
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="download-report-button"
                        onClick={() => {
                          setPage(page + 10);
                        }}
                      >
                        {btnLoading ? (
                          <CircularProgress
                            size={"1rem"}
                            sx={{ color: "var(--color-white)" }}
                          />
                        ) : (
                          "Show more"
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </CustomTabPanel>
            </Box>
          </div>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="order-specification-div"
        >
          <OrderSpecification details={status[indexOfCard]} />
        </Grid> */}
      </Grid>
    </>
  );
};

export default OrderStatus;
