import React from "react";
import { Dialog, DialogContent, Grid, Button } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./rmsbookmodal.css";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { useNavigate } from "react-router-dom";

const CreatedShipment = ({ setNegotiatemodal, negotiatemodal }) => {
  const handleClose = () => setNegotiatemodal("");
  const navigate = useNavigate();
  return (
    <Dialog
      open={negotiatemodal}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "50%",
            maxWidth: "400px",
          },
        },
      }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="d-flex justify-content-end"
          >
            <CloseOutlinedIcon
              onClick={() => {
                handleClose();
              }}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="p-0 text-center"
          >
            <ThumbUpOutlinedIcon color="primary" style={{ fontSize: "4rem" }} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <p className="mb-0 text-center shipment-create">
              Your shipment has been created
            </p>
            <p className="mb-0 text-center shipment-create-text">
              You will get shipment details in your mail
            </p>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              fullWidth
              sx={{
                borderRadius: "62px",
                textTransform: "lowercase",
                backgroundColor: "var(--color-secondary-dark)",
                color: "var(--color-white)",
              }}
              onClick={() => {
                navigate(Routeconstant.RMS);
              }}
            >
              view created shipment
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default CreatedShipment;
