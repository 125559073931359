import React, { useEffect, useState } from "react";
import LayoutDetail from "../../../components/layout/LayoutDetail";
import "../shipment.css";
import { basename } from "../../../Config";
import { API_PATH } from "../../../constant/apiPath";
import { useLocation, useNavigate } from "react-router-dom";
import instance from "../../../api/Interceptor";
import { AuthContext } from "../../../store/context/AuthProvider";

import {
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
} from "@mui/material";
import urlResolver from "../../../utils/urlResolver";
import { useContext } from "react";
import { Routeconstant } from "../../../routing/utils/RouteConstant";

const CargoDetails = ({ TransportMode }) => {
  const [cargoDetails, setCargoDetails] = useState({});
  const [currentDetails, setCurrentDetails] = useState({});
  const location = useLocation();
  const context = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  let ShipmentTransportMode = TransportMode || location.state.TransportMode;
  const transportImg = () => {
    if (ShipmentTransportMode === "SEA") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/ship.svg")} />
      );
    } else if (ShipmentTransportMode === "AIR") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/Air.svg")} />
      );
    } else if (ShipmentTransportMode === "ROA") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/road.svg")} />
      );
    } else if (ShipmentTransportMode === "RAI") {
      return (
        <img className="img-ship-cargo" src={urlResolver("assets/rail.svg")} />
      );
    } else if (ShipmentTransportMode === "COU") {
      return (
        <img
          className="img-ship-cargo"
          src={urlResolver("assets/courier.svg")}
        />
      );
    }
  };
  useEffect(() => {
    if (context?.shipmentId?.length !== 0) getCargoDetails();
  }, [location.state.ShipmentId]);

  const getCargoDetails = () => {
    setLoading(true);
    const body = {
      ShipmentId: location?.state?.ShipmentId ?? "",
    };
    instance
      .post(API_PATH.GET_CARGO_DETAILS, body)
      .then((res) => {
        setCargoDetails(res?.data[0] ?? {});
        setCurrentDetails(res?.data[0].CargoDetails[0]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filterCurrentCargo = (data) => {
    let filteredData = cargoDetails.CargoDetails.filter((details) => {
      return details.Key === data;
    });
    setCurrentDetails(filteredData[0]);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      navigate(Routeconstant.SHIPMENT_LISTING);
    }
  }, []);
  return (
    <LayoutDetail>
      {loading ? (
        <div className="skeleton-div">
          <Skeleton style={{ height: "45rem", marginTop: "1rem" }} />
        </div>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            lg={12}
            sx={{
              marginTop: "0.2rem",
              marginBottom: "0.5rem",
            }}
          >
            <Grid item lg={6} md={6} sm={6} xs={6}>
              <p className="basic-head m-0">Cargo Details</p>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={6}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <FormControl sx={{ width: 180 }} className="font-change">
                <Select
                  className="font-change"
                  sx={{
                    maxHeight: 30,
                  }}
                  defaultValue={currentDetails?.Key}
                  fullWidth
                  onChange={(e) => {
                    filterCurrentCargo(e.target.value);
                  }}
                >
                  {cargoDetails?.KeyValues?.map((item, index) => {
                    return (
                      <MenuItem
                        className="font-change"
                        key={index}
                        value={item.Key}
                      >
                        {cargoDetails?.CargoDetails[index]?.Containernum ??
                          "N/A"}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid
              item
              lg={3}
              md={3}
              sm={3}
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {transportImg()}

              <div className="d-flex gap-1 mt-1">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <span
                    className="m-0 py-1 text-head "
                    style={{ width: "100px" }}
                  >
                    Cargo Type &nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="m-0 py-1 text-head ">
                    {currentDetails?.Commodity ?? "N/A"}{" "}
                  </span>
                </Grid>
                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <p className="  text-sub">
                    {currentDetails?.Commodity ?? "N/A"}{" "}
                  </p>
                </Grid> */}
              </div>
            </Grid>
            <Grid item lg={9} md={9} sm={9} xs={9}>
              <Grid container spacing={0}>
                {ShipmentTransportMode !== "AIR" && (
                  <>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Grid container spacing={0}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <p
                            style={{ color: "#6190cb" }}
                            className="m-0 py-1 text-head "
                          >
                            Container No.
                          </p>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <p className="  text-sub">
                            {currentDetails?.Containernum ?? "N/A"}
                          </p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Grid container spacing={0}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 py-1 text-head "
                        style={{ color: "#6190cb" }}
                      >
                        No. of Packages
                      </p>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="text-sub">
                        {currentDetails?.PackageCount ?? "N/A"}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Grid container spacing={0}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 py-1 text-head "
                        style={{ color: "#6190cb" }}
                      >
                        Package Types
                      </p>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="text-sub">
                        {currentDetails?.PackageType ?? "N/A"}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Grid container spacing={0}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 py-1 text-head "
                        style={{ color: "#6190cb" }}
                      >
                        Volume
                      </p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="  text-sub">
                        {currentDetails?.Volume ?? "N/A"}
                        {currentDetails?.UV ?? "N/A"}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Grid container spacing={0}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 py-1 text-head "
                        style={{ color: "#6190cb" }}
                      >
                        Weight
                      </p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="m-0 text-sub">
                        {currentDetails?.Weight ?? "N/A"}
                        {currentDetails?.UW ?? "N/A"}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Grid container spacing={0}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p
                        className="m-0 py-1 text-head"
                        style={{ color: "#6190cb" }}
                      >
                        Commadity
                      </p>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <p className="m-0 text-sub">
                        {currentDetails?.Commodity ?? "N/A"}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "1rem",
            }}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className="m-0 py-1 text-head " style={{ color: "#6190cb" }}>
                Goods Description
              </p>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <p className="m-0 text-sub">
                {currentDetails?.GoodsDescription ?? "N/A"}
              </p>
            </Grid>
          </Grid>
        </>
      )}
    </LayoutDetail>
  );
};

export default CargoDetails;
