import React from "react";
import {
  Button,
  Grid,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
  MenuItem,
  Paper,
  InputLabel,
  TableBody,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useState } from "react";
import { useRef } from "react";
import { FormControl } from "@mui/base";

const Tablerow = ({
  data,
  key,
  indexvalue,
  prevcount,
  onDelete,
  onChange,
  containerData,
  setContainerInformation,
  containerInformation,
}) => {
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const [quantity, setQuantity] = useState(1);
  const [Weight, setWeigth] = useState("");
  const [WeightUnit, setWeigthUnit] = useState("");
  const quantityRef = useRef(1);
  const handleDelete = (indexvalue) => {
    onDelete(indexvalue);
  };
  // console.log(containerData,"containerData")
  const handleInputChange = (field, value) => {
    if (
      field === "PackagingType" ||
      field === "VolumeUnit" ||
      field === "WeightUnit" ||
      field === "containerType"
    ) {
      onChange(data.id, field, value);
    } else if (field === "Stakable") {
      onChange(data.id, field, !data.Stakable);
    } else {
      onChange(data.id, field, value);
    }
  };
  const QuantityUpdate = (containertype) => {
    setContainerInformation((prev) => {
      return prev.map((obj) => {
        if (obj.ContainerISO === containertype) {
          const info = { ...obj };
          info.ContainerCount = quantityRef?.current;
          return { ...info };
        } else {
          return { ...obj };
        }
      });
    });
  };
  return (
    <>
      {["LSE", "CON", "BCN", "LCL", "BLK", "LQD", "BBK", "OBC", "UNA"].includes(
        savedTransportData
      ) ? (
        <>
          <TableRow
            style={{
              display: "table",
              width: "100%",
              borderCollapse: "collapse",
              borderSpacing: "0",
            }}
          >
            <TableCell className="table-data" align="center">
              {/* <DeleteOutlineOutlinedIcon
               onClick={handleDelete}
               disabled={data.disableDelete} // Disable delete button based on disableDelete property
               style={{ opacity: data.id === 0 ? 0.5 : 1, pointerEvents: data.id === 0 ? "none" : "auto" }}
              /> */}
              <Button
                onClick={handleDelete}
                disabled={data.disableDelete} // Disable delete button based on disableDelete property
                style={{
                  opacity: data.id === 0 ? 0.5 : 1,
                  pointerEvents: data.id === 0 ? "none" : "auto",
                }}
              >
                Delete
              </Button>
            </TableCell>
            <TableCell className="table-data" align="center">
              <Select
                size="small"
                sx={{ padding: "0" }}
                className="MuiSelect-outlined"
                name="PackagingType"
                value={data.PackagingType}
                onChange={(e) =>
                  handleInputChange("PackagingType", e.target.value)
                }
                variant="outlined"
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Pallets">Pallets</MenuItem>
                <MenuItem value="Skids">Skids</MenuItem>
                <MenuItem value="Crates">Crates</MenuItem>
                <MenuItem value="boxes">boxes</MenuItem>
                <MenuItem value="Cartons">Cartons</MenuItem>
                <MenuItem value="cases">cases</MenuItem>
                <MenuItem value="Packages">Packages</MenuItem>
                <MenuItem value="Drums">Drums</MenuItem>
                <MenuItem value="Bags">Bags</MenuItem>
                <MenuItem value="Rolls">Rolls</MenuItem>
              </Select>
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Quantity"
                value={data.Quantity}
                onChange={(e) => handleInputChange("Quantity", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <Select
                sx={{ padding: "0" }}
                size="small"
                className="MuiSelect-outlined"
                type="text"
                name="VolumeUnit"
                value={data.VolumeUnit}
                onChange={(e) =>
                  handleInputChange("VolumeUnit", e.target.value)
                }
                variant="outlined"
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="m3">m3</MenuItem>
              </Select>
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Volume"
                value={data.Volume}
                onChange={(e) => handleInputChange("Volume", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <Select
                sx={{ padding: "0" }}
                size="small"
                className="MuiSelect-outlined"
                type="text"
                name="WeightUnit"
                value={data.WeightUnit}
                onChange={(e) =>
                  handleInputChange("WeightUnit", e.target.value)
                }
                variant="outlined"
                IconComponent={KeyboardArrowDownOutlinedIcon}
              >
                <MenuItem value="Select" disabled>
                  Select
                </MenuItem>
                <MenuItem value="Kg">Kg</MenuItem>
                <MenuItem value="Ton">Ton</MenuItem>
                <MenuItem value="LB">LB</MenuItem>
              </Select>
            </TableCell>
            <TableCell className="table-data" align="center">
              <TextField
                type="number"
                name="Weight"
                value={data.Weight}
                onChange={(e) => handleInputChange("Weight", e.target.value)}
                variant="outlined"
                size="small"
                className="custom-textfield"
              />
            </TableCell>
            <TableCell className="table-data" align="center">
              <Switch
                size="small"
                checked={data.Stakable}
                onChange={() => handleInputChange("Stakable")}
              />
            </TableCell>
          </TableRow>
        </>
      ) : (
        <>
          {/* <Grid item lg={6} md={6} xs={12} sx={{ width: "100%" }}>
            <TableContainer>
              <Table>
               
                  <TableRow>
                    <TableCell  align="center">
                      <Button
                        onClick={() => handleDelete(indexvalue)}
                        disabled={prevcount > indexvalue} // Disable delete button based on disableDelete property
                      // style={{ opacity: data.id === 0 ? 0.5 : 1, pointerEvents: data.id === 0 ? "none" : "auto" }}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      <TextField
                        type="number"
                        disabled
                        name="containerQuant"
                        value={quantity}
                      
                        variant="outlined"
                        size="small"
                        className="custom-textfield"
                      />
                    </TableCell>
                    <TableCell className="table-data" align="center">
                    
                      {!containerData?.ContainerISO?.length ?
                        (
                          <Select
                            size="small"
                            style={{ width:"10rem",marginLeft:"28px",marginRight:"28px" }}
                            className="custom-textfield"
                            name="containerType"
                            value={containerData?.ContainerISO}
                            onChange={(e) => containerData.ContainerISO = e.target.value}
                            variant="outlined"
                            IconComponent={KeyboardArrowDownOutlinedIcon}
                          >
                            <MenuItem value="Select" disabled>
                              Select ContainerISO
                            </MenuItem>
                            {containerInformation.map((container, index) => (
                              <MenuItem key={index} value={container.ContainerISO}>
                                {container.ContainerISO}
                              </MenuItem>
                            ))}
                          </Select>) : (<TextField
                            type="text"
                            aria-readonly
                            name="containerType"
                          
                            value={containerData.ContainerISO}
                          
                            variant="outlined"
                            size="small"
                            className="custom-textfield"

                          />)
                      }
                    </TableCell>
                    <TableCell className="table-data" align="center">
                      <TextField
                        type="text"
                        name="Weigth"
                        value={Weight}
                        
                        onChange={(e) => {
                        console.log(e.target.value, containerData, "containerData")
                          var pattern = /^[0-9]+$/;
                          if (pattern.test(e.target.value)) {
                            setWeigth(e.target.value)
                            containerData.Weight = e.target.value;
                          }
                        }}
                        variant="outlined"
                        size="small"
                        className="custom-textfield"
                      />
                    </TableCell>
                    <Select
                      size="small"
                      sx={{ padding: "0" }}
                      name="unit"
                      value={WeightUnit}
                      onChange={(e) => {
                        setWeigthUnit(e.target.value);
                        containerData.WeightUnit = e.target.value;
                      }}
                      variant="outlined"
                      IconComponent={KeyboardArrowDownOutlinedIcon}
                    >
                      <MenuItem value="Select" disabled>
                        Select
                      </MenuItem>
                      {["KG", "TON", "LB"].map((Unit, index) => (
                        <MenuItem key={index} value={Unit}>
                          {Unit}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableRow>
              
              </Table>
            </TableContainer>
          </Grid> */}
          <Grid iitem lg={9} md={12} xs={10}>
            <TableContainer
              style={{
                maxWidth: "90vw",
                overflow: "auto",
              }}
            >
              <Table>
                <TableRow>
                  {/* <TableCell > 
                      <Button
                        onClick={() => handleDelete(indexvalue)}
                        disabled={prevcount > indexvalue} // Disable delete button based on disableDelete property
                      // style={{ opacity: data.id === 0 ? 0.5 : 1, pointerEvents: data.id === 0 ? "none" : "auto" }}
                      >
                        Delete
                      </Button></TableCell> */}
                  <TableCell>
                    <input
                      type="number"
                      disabled
                      name="containerQuant"
                      value={containerData?.ContainerCount}
                    ></input>
                  </TableCell>
                  <TableCell>
                    {!containerData?.ContainerISO?.length ? (
                      <select
                        style={{
                          width: "100%",
                          minWidth: "10.5rem",
                          padding: "3px",
                        }}
                        name="containerType"
                        value={containerData.ContainerISO}
                        onChange={(e) =>
                          (containerData.ContainerISO = e.target.value)
                        }
                      >
                        <option hidden>Select Container</option>
                        {containerInformation.map((container, index) => {
                          // console.log("containerrrrrrr",containerInformation);
                          if (container.hasOwnProperty("ContainerISO"))
                            return (
                              <option value={container.ContainerISO}>
                                {" "}
                                {container.ContainerISO}
                              </option>
                            );
                        })}
                      </select>
                    ) : (
                      <input
                        type="text"
                        style={{
                          minWidth: "10.5rem",
                        }}
                        name="containerType"
                        // value={data.containerQuant}
                        value={containerData.ContainerISO}
                      />
                    )}
                  </TableCell>
                  {/* <TableCell>
                    <input  type="text"
                        name="Weigth"
                        value={Weight}
                        onChange={(e) => {
                        console.log(e.target.value, containerData, "containerData")
                          // var pattern = /^[0-9]+$/;
                          // if (pattern.test(e.target.value)) {
                            setWeigth(e.target.value)
                            containerData.Weight = e.target.value;
                          // }
                        }}/>
                  </TableCell>
                    <TableCell >
                    <select
                    style={{
                      width:"85%",
                      padding:"3px"
                    }}
                      name="unit"
                      value={WeightUnit}
                      onChange={(e) => {
                        setWeigthUnit(e.target.value);
                        containerData.WeightUnit = e.target.value;
                      }}
                    >
                       <option value="" hidden>Select Unit</option>
                      {["KG", "TON", "LB"].map((Unit, index) => (
                        <option key={index} value={Unit}>
                          {Unit}
                        </option>
                      ))}
                    </select>

                    </TableCell> */}
                </TableRow>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
    </>
  );
};
export default Tablerow;
