import * as yup from "yup";

export const orderspecificSchema = yup.object().shape({
  orderNumber: yup.object().shape({
    orderNumber: yup.string(),
    cw1OrderNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
    // orderDate: yup.string().required(),
    // sendingAgent: yup.string().required(),
    // receivingAgent: yup.string().required(),
  }),
  // mileStones: yup.object().shape({
  //   orderConfirmed: yup.object().shape({
  //     estDate: yup.string().required(),
  //     actDate: yup.string().required(),
  //   }),
  //   exFactory: yup.object().shape({
  //     estDate: yup.string().required(),
  //     actDate: yup.string().required(),
  //   }),
  // }),
  // supplier: yup.object().shape({
  //   supplierName: yup.string().required(),
  //   supplierAddress: yup.string().required(),
  // }),
  buyer: yup.object().shape({
    buyerName: yup.object().shape({
      Code: yup.string().required("Buyer Name is Required"),
    }),
    // buyerAddress: yup.string().required(),
    // finalDeliveryLocation: yup.string().required(),
  }),
  orderDetails: yup.object().shape({
    currencyOfPayment: yup.object().shape({
      Code: yup.string().required("Currency is Required"),
    }),
    containerMode: yup.object().shape({
      Code: yup.string().required("Container mode is Required"),
    }),
    // reqWork: yup.string().required(),
    // serviceLevel: yup.string().required(),
    shipmentNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
    invoiceNumber: yup
      .string()
      .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
    incoTerm: yup.object().shape({
      Code: yup.string().required("Inco term is Required"),
    }),
    // transportMode: yup.string().required(),
    // reqStore: yup.string().required(),
    // shipmentNumber: yup.string().required(),
    // originCountry: yup.string().required(),
    // goodsDescription: yup.string().required(),
    // specialInstructions: yup.string().required(),
    // containerRequired: yup.array().of(
    //   yup.object().shape({
    //     count: yup.number().required(),
    //     type: yup.string().required(),
    //   })
    // ),
  }),
});
