import * as yup from "yup";

export const orderLineDetailsSchema = yup.object().shape({
  itemNo: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
  description: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),

  additionalTerms: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
  confirmNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
  containerNumber: yup
    .string()
    .matches(/^[a-zA-Z0-9\s]*$/, "Special characters are not allowed."),
});
