import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";


const FilterModalRms = ({ setSortmodal, sortmodal, filterModalData, setFilterModalData, getQuoteDetails, tab ,getRmsDetails ,filtterDAta ,  setSearchValue}) => {
  const handleClose = () => setSortmodal(false);
  const Modeoptions = ["SEA", "AIR", "ROA", "RAI", "COU"];

  return (
    <Dialog
      open={sortmodal}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth
    >
      <DialogContent>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CardHeader
            action={
              <Button onClick={() => {
                setFilterModalData({
                  "Carrier": "",
                  "Origin": "",
                  "Destination": "",
                  "ValidatFrom": null,
                  "ValidTo": null,
                  "TransportMode" : ""
                })
                filtterDAta.current={
                  "Carrier": "",
                  "Origin": "",
                  "Destination": "",
                  "ValidatFrom": null,
                  "ValidTo": null,
                  "TransportMode" : ""
                }
                if (tab === "Quote") {
                  getQuoteDetails();
                }
                else {
                  getRmsDetails();
                }
              }}>
                Reset all
              </Button>
            }
            subheader={
              <Button color={"primary"} onClick={() => {
                handleClose();
              }}>
                <KeyboardBackspaceIcon /> Back
              </Button>
            }
          />
          <Divider sx={{ backgroundColor: "var(--color-miscellaneous-1)" }} />
          <CardContent>
            <Grid container spacing={2}>
              {
                tab === "Quote" && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography>Carrier</Typography>
                      </Grid>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        <FormControl size="small" fullWidth>
                          <OutlinedInput
                            value={filterModalData?.Carrier}
                            placeholder="Please enter text"
                            onChange={(e) => {
                              var allowedCharacters = /^[A-Za-z _-]+$/;
                              if (e.target.value.match(allowedCharacters) || e.target.value.length === 0) {
                                setFilterModalData((prev) => {
                                  return (
                                    { ...prev, "Carrier": e.target.value.toUpperCase() }
                                  )
                                });
                              }
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }

              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography>Origin</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filterModalData?.Origin}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (e.target.value.match(allowedCharacters) || e.target.value.length === 0 ) {
                            setFilterModalData((prev) => {
                              return (
                                { ...prev, "Origin": e.target.value.toUpperCase() }
                              )
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              {/* ------------- */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Grid container spacing={0}>
                  <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Typography>Destination</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8} xs={8}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        value={filterModalData?.Destination}
                        placeholder="Please enter text"
                        onChange={(e) => {
                          var allowedCharacters = /^[A-Za-z _-]+$/;
                          if (e.target.value.match(allowedCharacters) || e.target.value.length === 0) {
                            setFilterModalData((prev) => {
                              return (
                                { ...prev, "Destination": e.target.value.toUpperCase() }
                              )
                            });
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* ----- valid data in case of quote-------- */}
              {
                tab === "Quote" && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={4} md={6} sm={6} xs={6}>
                        <Typography>Validity Date</Typography>
                      </Grid>
                      <Grid
                        item
                        lg={3.5}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ color: "black" }}
                      >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            maxDate={filterModalData.ValidTo}
                            className="w-100 "
                            label="Select Date"
                            value={filterModalData.ValidatFrom}
                            inputFormat="DD/MM/YYYY"
                            onChange={(e) => {
                              setFilterModalData((prev) => {
                                return (
                                  { ...prev, "ValidatFrom": e }
                                )
                              });
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item lg={1} md={12} sm={12} xs={12}>
                        <Typography align="center">to</Typography>
                      </Grid>
                      <Grid item lg={3.5} md={12} sm={12} xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            minDate={filterModalData.ValidatFrom}
                            className="w-100 "
                            inputFormat="DD/MM/YYYY"
                            label="Select Date"
                            value={filterModalData.ValidTo}
                            onChange={(e) => {
                              setFilterModalData((prev) => {
                                return (
                                  { ...prev, "ValidTo": e }
                                )
                              });
                            }}
                            renderInput={(params) => (
                              <TextField size="small" {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
              {/* transport mode */}
              {
                tab !== "Quote" && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Typography>Transport Mode</Typography>
                      </Grid>
                      <Grid item lg={8} md={8} sm={8} xs={8}>
                        <FormControl size="small" fullWidth>
                          <Select
                            value={filterModalData?.TransportMode}
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(e) => {
                              setFilterModalData((prev) => {
                                return (
                                  { ...prev, "TransportMode": e.target.value }
                                )
                              });
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            {Modeoptions.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )

              }

            </Grid>
          </CardContent>
          <CardActions className="d-flex justify-content-end">
            <Button
              onClick={() => {
                handleClose();
                filtterDAta.current={...filterModalData}
                if(Object.values(filtterDAta.current).filter((element)=>element?.length || (element != null && typeof(element) === "object")).length > 0){
                  if (tab === "Quote") {
                    getQuoteDetails();
                  }
                  else {
                    getRmsDetails();
                  }
                }               
                setSearchValue("")
              }}
            >
              Search
            </Button>
          </CardActions>
        </Card>
      </DialogContent>
    </Dialog>
  );
};

export default FilterModalRms;
