import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { AuthContext } from "../../store/context/AuthProvider";
import { basename, customerName } from "../../Config";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./layout.css";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import Tooltip from "@mui/material/Tooltip";
function LayoutDetail(props) {
  const location = useLocation();
  const context = useContext(AuthContext);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [emailContact, setemailContact] = useState("");
  // const [subscription, setSubscription] = useState("");
  // const postShipmentSubscription = () => {
  //   const body = {
  //     ShipmentId: location?.state?.ShipmentId,
  //     ShowDeveloperResponse: true,
  //   };
  //   instance
  //     .post(API_PATH.POST_SHIPMENT_SUBSCRIPTION, body)
  //     .then((res) => {
  //       setSubscription(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     })
  //     .finally(() => {
  //       // setLoadingAging(false);
  //     });
  // };

  const getEmailContacts = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true,
    };
    instance
      .post(API_PATH.GET_EMAIL_CONTACTS, body)
      .then((res) => {
        setemailContact(res.data[0].EmailId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWhatsappNumber = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true,
    };
    instance
      .post(API_PATH.GET_WHATSAPP_NUMBER, body)
      .then((res) => {
        setWhatsappNumber(res.data[0].ContactNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if(context.gridViewShipment){
      getWhatsappNumber();
      getEmailContacts();
    }
    // postShipmentSubscription();
  }, [location?.state?.ShipmentId]);

  const sendMail = () => {
    let body = `Hello, %0A%0ATeam ${customerName}, %0A%0ACould you please send more details about my shipment? %0A%0AShipment Number: ${
      location?.state?.ShipmentId ?? ""
    } %0AHouse BL Number: ${
      location?.state?.data?.HouseBillNumber ?? ""
    } %0ATransport Mode: ${
      location?.state?.TransportMode ?? ""
    } %0A%0AThank you! %0A${"SFL HUB"}%0A%0A`;
    window.location.href = `mailto:${emailContact}?subject=Shipment ID - ${
      location?.state?.ShipmentId ?? ""
    }&body=${body}`;
  };
  return (
    <div
      className="detail-header overflow_detail"

      // className={
      //   !location.pathname.includes(Routeconstant.ROUTING)
      //     ? "detail-header"
      //     : "detail-header overflow_detail"
      // }
    >
      {context.gridViewShipment ? (
        <div className="d-flex justify-content-between align-items  p-2 details-head-div">
          <div>
            <p className="mb-0 mt-2 detail-text-head">
              {location?.state?.ShipmentId ?? "N/A"}
            </p>
          </div>

          <div
            className="d-flex justify-content-between align-items"
            style={{ minWidth: "8%" }}
          >
            {/* <NotificationsNoneIcon className="layout-notification" /> */}
            {/* <a o> */}
            <Tooltip title="Mail">
              <MailIcon className="layout-notification" onClick={sendMail} />
            </Tooltip>
            {/* </a> */}
            <Tooltip title="Whatsapp">
              <a
                href={`https://web.whatsapp.com/send?phone=${whatsappNumber}`}
                target="_blank"
              >
                <WhatsAppIcon className="layout-notification" />
              </a>
            </Tooltip>

            {/* <ShareOutlinedIcon className="mx-2 cursorPointer" /> */}
            {/* <img src={urlResolver("assets/dotted.svg")} /> */}
          </div>
        </div>
      ) : null}
      <div
        className={
          context.gridViewShipment
            ? "layout-details-div  mx-3"
            : "layout-details-div  mx-3 pt-5"
        }
      >
        {" "}
        {props.children}{" "}
      </div>
    </div>
  );
}

export default LayoutDetail;
