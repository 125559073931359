import { Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "./Header";
import Footer from "./Footer";
import CreateOrderLayout from "./CreateOrderLayout";
import { useSelector } from "react-redux";
import ResponseDialoge from "./ResponseDialoge";

const CreateOrder = () => {
  const { createOrderData } = useSelector((state) => state);
  return (
    <Grid
      container
      spacing={0}
      style={{
        display: "flex",
        height: "100%",
      }}
    >
      <Grid item lg={12} xs={12}>
        {/* this component display the progress level */}
        <Header />
      </Grid>
      <Grid item lg={12} xs={12}>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={createOrderData?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        {/* complete first stage form */}
        <CreateOrderLayout />
      </Grid>
      <Grid
        item
        lg={12}
        xs={12}
        sx={{
          paddingTop: "1rem",
        }}
      >
        {/* next and prvious */}
        <Footer />
      </Grid>
      <ResponseDialoge />
    </Grid>
  );
};
export default CreateOrder;
