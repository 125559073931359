import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";
import React, { useEffect } from "react";
import { getLocationDetails } from "../../apicalls/get/getData";
const TrackMap = (props) => {
  const [locationData, setLocationData] = React.useState({});
  const [coordinate, setCoordinate] = React.useState([]);
  const [polyline, setPolyline] = React.useState([]);

  const [bounds, setBounds] = React.useState(
    new props.google.maps.LatLngBounds()
  );
  const getOrderLocation = async (orderId) => {
    const promiseLocationDetails = getLocationDetails(orderId);
    const { data } = await promiseLocationDetails;

    mapData(data);
    setLocationData(data);
  };
  const mapData = (routes) => {
    setCoordinate(routes.route);
    const arr = [];
    let bound = new props.google.maps.LatLngBounds();

    routes.route.map((ele, index) => {
      ele.path.map((item) => {
        const obj = {
          lat: parseFloat(item[0]),
          lng: parseFloat(item[1]),
        };
        arr.push(obj);

        bound.extend(obj);
      });

      // if (index != 2) {

      // }
    });

    setBounds(bound);
    setPolyline([...arr]);
  };
  useEffect(() => {
    getOrderLocation(props.id);
    // getOrderLocation("C14216AE-0B6A-405D-BB1A-EEA3E4172CB6");
  }, []);
  return (
    <div
      style={{
        position: "relative",
        height: "80vh",
        width: "100%",
      }}
    >
      <Map
        google={props.google}
        zoom={2}
        style={{
          borderRadius: "12px",
        }}
      >
        {polyline.map((item, index) => {
          if (index === 0 || index === polyline.length - 1) {
            return (
              <Marker
                position={item}
                title={`lat=${item.lat}, lng=${item.lng}`}
              />
            );
          }
        })}

        {polyline.length != 0 && !props.loading && (
          <Polyline
            path={polyline}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor="#0000FF"
            fillOpacity={1}
            options={{
              strokeColor: "var(--color-primary-light)",
              strokeOpacity: 0.75,
              strokeWeight: 2,
              icons: [
                {
                  icon: {
                    path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeOpacity: 0,
                    fillColor: "var(--color-primary-light)",
                    fillOpacity: 1.0,
                    scale: 4,
                  },
                  repeat: "80px",
                },
              ],
            }}
          />
        )}
      </Map>
    </div>
  );
};
export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(TrackMap));
