import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import instance from "../../api/Interceptor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AuthContext } from "../../store/context/AuthProvider";
import dayjs from "dayjs";
import CreateRmsList from "./components/CreateRmsList";
import Skeleton from "@mui/material/Skeleton";

const DetailsRms = ({ setTransportType }) => {
  const today = new Date();
  const { QuotationId } = useParams();
  const context = React.useContext(AuthContext);
  const [transport, setTransport] = useState([]);
  const FinalInformation = useRef();
  const [movement, setMovement] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [inco, setInco] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryPort, setCountryPort] = useState([]);
  const [countryDestPort, setCountryDestPort] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [quotedata, setQuotedata] = useState([]);
  const [hazardous, setHazardous] = useState(true);
  const [selectedOriginCountry, setSelectedOriginCountry] = useState(null);
  const [selectedOriginPort, setSelectedOriginPort] = useState(null);
  const containerData = useRef(null);
  const QuotationData = useRef(null);
  const location = useLocation();
  const item = location.state;
  const [formData, setFormData] = useState({
    imoClass: "",
    hsCode: "",
    unNumber: "",
    shipperNo: "",
    goodValue: "",
    additionalServices: "",
    goodDesc: "",
  });
  const [formOriginData, setFormOriginData] = useState({
    originCountry: null,
    originPortName: null,
    originPort: null,
    originPin: "",
    destCountry: null,
    destPortName: null,
    destPort: null,
    destPin: "",
  });

  const [formdropData, setFormdropData] = useState({
    transportType: "",
    movementType: "",
    cargoType: "",
    incoType: "",
  });
  const [rmsFormData, setRmsFormData] = useState({
    InquiryNo: "",
    CWBookingNumber: "",
    Transportmode: "",
    Containertype: "",
    MovementType: "",
    Incoterm: "",
    Carotype: "",
    IMOClass: "",
    HSCode: "",
    UnNumber: "",
    OriginPort: "",
    destinationport: "",
    POReceipt: "",
    FinalDeliveryUnlocoCode: "",
    ShipperInvoiceNumber: "",
    Goodsvalue: "",
    Cargoreadydate: "",
    AdditionalServices: "",
    Goodsdescription: "",
    StatusCode: "",
    StatusDescription: "",
    JobType: "",
    CreatedUserName: "",
    ConsignorCode: "",
    ConsignorName: "",
    ConsigneeCode: "",
    ConsigneeName: "",
    NotifypartyCode: "",
    NotifypartyName: "",
    PaymentTerm: "",
    ClientNotes: "",
    CarrierCode: "",
    CarrierName: "",
    validityDate: "",
    OriginPin: "",
    Destpin: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    transportType: "",
    movementType: "",
    incoType: "",
    selectedDate: "",
    originCountry: "",
    originPort: "",
    destCountry: "",
    destPort: "",
  });
  const isCountryNameSelected = !!formOriginData.originCountry;
  const isCountryPortSelected = !!formOriginData.originPort;
  const isCountryPortSelected1 = formdropData.movementType === "D2D";
  const isDestCountryNameSelected = !!formOriginData.destCountry;
  const isDestCountryPortSelected = !!formOriginData.destPort;
  const isDestCountryPortSelected1 = formdropData.movementType === "D2D";
  const ishazardous = formdropData.cargoType === "HAZ";
  const Todays_Date = dayjs(new Date()).format("YYYY-MM-DD 00:00:00");
  const handleDateChange = (date) => {
    const selected =
      dayjs(date).format("YYYY-MM-DD 00:00:00") ||
      dayjs(new Date()).format("YYYY-MM-DD 00:00:00"); // If no date is selected, default to today's date
    setSelectedDate(selected);
  };
  const handleDropdownOriginChange = (event) => {
    const { name, value } = event.target;
    setFormOriginData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setFormdropData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleToggleHazardous = () => {
    setHazardous((prevHazardous) => !prevHazardous);
    if (hazardous !== false) {
      setFormData({ imoClass: "", hsCode: "", unNumber: "" });
    }
  };

  const handleQuoteDataChange = () => {
    setLoading(true);
    const body = {
      QuotationNo: QuotationId,
      ShowDeveloperResponse: true,
    };
    instance
      .post("api/v1/Quotation/GetQuote", body)
      .then((res) => {
        setQuotedata(res?.data ?? []);
        setTransportType(res?.data?.TransportMode ?? "SEA");
        containerData.current = [...res?.data[0]?.ContainerTypes];
        QuotationData.current = { ...res?.data[0]?.Quotation };
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRmsDataChange = () => {
    setValidationErrors({
      transportType: "",
      movementType: "",
      incoType: "",
      originCountry: "",
      originPort: "",
      destCountry: "",
      destPort: "",
    });
    let errors = {};
    if (formdropData.movementType.trim() === "") {
      errors.movementType = "Please provide Movement Type.";
    }
    if (formdropData.incoType.trim() === "") {
      errors.incoType = "Please provide Inco Type.";
    }
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return false;
    }

    let temp = {
      InquiryNo: "",
      CWBookingNumber: "",
      Transportmode: context.buttonActive,
      Containertype: context?.rmsData?.Containertype,
      MovementType: formdropData.movementType,
      Incoterm: formdropData.incoType,
      Carotype: formdropData.cargoType,
      IMOClass: formData.imoClass,
      HSCode: formData.hsCode,
      UnNumber: formData.unNumber,
      Originpin: formOriginData.originPin,
      Destpin: formOriginData.destPin,
      OriginPort: context.rmsData.OriginPort,
      destinationport: context?.rmsData?.destinationport,
      POReceipt: "",
      FinalDeliveryUnlocoCode: "",
      ShipperInvoiceNumber: formData.shipperNo,
      Goodsvalue: formData.goodValue,
      Cargoreadydate: selectedDate,
      AdditionalServices: formData.additionalServices,
      Goodsdescription: formData.goodDesc,
      StatusCode: "",
      StatusDescription: "",
      JobType: "",
      CreatedUserName: "",
      ConsignorCode: "",
      ConsignorName: "",
      ConsigneeCode: "",
      ConsigneeName: "",
      NotifypartyCode: "",
      NotifypartyName: "",
      PaymentTerm: "",
      ClientNotes: "",
      CarrierCode: "",
      CarrierName: "",
      validityDate: "",
      originPortName: context?.rmsData?.originPortName,
      destPortName: context?.rmsData?.destPortName,
    };
    setRmsFormData(temp);
    localStorage.setItem(
      "TransportData",
      JSON.stringify(formdropData.transportType)
    );
    context.setRmsData((prev) => {
      const tempData = {
        ...prev,
        ...temp,
        ...item,
        QuotationId,
      };
      FinalInformation.current = tempData;
      return tempData;
    });
    // navigate(Routeconstant.CREATERMSLIST, {
    //   state: {
    //     "containerDataInformation": containerData.current,
    //     "QuotationData": QuotationData.current,
    //     "item": item
    //   }
    // });
    return true;
  };
  // console.log(item, "tempData1");

  useEffect(() => {
    getTransporttype();
  }, [context.buttonActive]);

  useEffect(() => {
    getcountryPort();
    setSelectedOriginCountry(
      country.find((c) => c.CountryCode === formOriginData.originCountry) ||
        null
    );
    setSelectedOriginPort(
      country.find((c) => c.CountryCode === formOriginData.originPort) || null
    );
  }, [formOriginData.originCountry, formOriginData.originCountry, country]);

  useEffect(() => {
    getdestcountryPort();
  }, [formOriginData.destCountry]);

  useEffect(() => {
    getTransporttype();
    getMovementtype();
    getcargotype();
    getincotype();
    getcountry();
    handleQuoteDataChange();
  }, []);

  const getTransporttype = () => {
    const body = {
      TransportMode: context.buttonActive,
    };
    instance
      .post("api/v1/master/GetContainerType", body)
      .then((res) => {
        setTransport(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const getMovementtype = () => {
    instance
      .get("api/v1/master/GetMovementType")
      .then((res) => {
        setMovement(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const getcargotype = () => {
    instance
      .get("api/v1/master/GetCargoType")
      .then((res) => {
        //  console.log("movement",res.data);
        setCargo(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const getincotype = () => {
    instance
      .get("api/v1/master/GetIncoTerm")
      .then((res) => {
        //  console.log("movement",res.data);
        setInco(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const getcountry = () => {
    instance
      .get("api/v1/master/GetCountries")
      .then((res) => {
        setCountry(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const getcountryPort = () => {
    const body = {
      CountryCode: formOriginData.originCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        setCountryPort(res.data ?? []);
        // console.log("setCountryPort", countryPort);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };
  const getdestcountryPort = () => {
    const body = {
      CountryCode: formOriginData.destCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        setCountryDestPort(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* --FIRST-- */}
        <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            {/* {temp.map((obj, index) => { */}
            {loading ? (
              <>
                <Skeleton variant="rounded" width={"100%"} height={"16em"} />
              </>
            ) : (
              <>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    name="Transport type"
                    value={
                      item?.ContainerTypeDesc?.length > 0
                        ? item?.ContainerTypeDesc
                        : item?.ContainerType
                    }
                    label="TRANSPORT TYPE"
                    variant="outlined"
                  />
                </FormControl>
                <br />
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: " var(--color-mile)" }}
                  >
                    MOVEMENT TYPE
                    <span style={{ color: "var(--color-miscellaneous-7)" }}>
                      *
                    </span>
                  </InputLabel>
                  <Select
                    name="movementType"
                    value={formdropData.movementType}
                    onChange={(event) => {
                      setFormOriginData((prev) => {
                        return {
                          ...prev,
                          destPin: "",
                          originPin: "",
                        };
                      });
                      handleDropdownChange(event);
                    }}
                    fullWidth
                    className="MuiSelect-outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    default={20}
                    label="MOVEMENT TYPE"
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    variant="outlined"
                  >
                    <MenuItem value={0} hidden>
                      Select
                    </MenuItem>
                    {movement.map((movem, index) => {
                      return (
                        <MenuItem value={movem.Code}>
                          {movem.Description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {validationErrors.movementType && (
                    <div className="error">{validationErrors.movementType}</div>
                  )}
                </FormControl>
                <br />
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: " var(--color-mile)" }}
                  >
                    CARGO TYPE
                  </InputLabel>
                  <Select
                    fullWidth
                    name="cargoType"
                    value={formdropData.cargoType}
                    onChange={handleDropdownChange}
                    className="MuiSelect-outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    default={20}
                    label="CARGO TYPEE"
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    variant="outlined"
                  >
                    <MenuItem value={0} hidden>
                      Select
                    </MenuItem>
                    {cargo.map((cargo, indexC) => {
                      return (
                        <MenuItem value={cargo.Code}>
                          {cargo.Description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    style={{ color: " var(--color-mile)" }}
                  >
                    INCO TERMS
                    <span style={{ color: "var(--color-miscellaneous-7)" }}>
                      *
                    </span>
                  </InputLabel>
                  <Select
                    fullWidth
                    name="incoType"
                    value={formdropData.incoType}
                    onChange={handleDropdownChange}
                    className="MuiSelect-outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    default={20}
                    label="INCO TERMS"
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    variant="outlined"
                  >
                    <MenuItem value={0} hidden>
                      Select
                    </MenuItem>
                    {inco.map((inco, indexI) => {
                      return (
                        <MenuItem value={inco.Code}>
                          {inco.Description}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {validationErrors.incoType && (
                    <div className="error">{validationErrors.incoType}</div>
                  )}
                </FormControl>
              </>
            )}
          </Paper>
        </Grid>
        {/* --MID-- */}
        <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{ padding: "24px", height: "100%", width: "100%" }}
          >
            {loading ? (
              <>
                <Skeleton variant="rounded" width={"100%"} height={"16em"} />
              </>
            ) : (
              <>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                    <Typography className="pb-3">
                      <LocationOnIcon color="primary" />
                      <span className="card-rms">Origin</span>
                    </Typography>

                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                      className="details-section"
                    >
                      <TextField
                        style={{ color: "var(--color-mile)" }}
                        name="Origin Country"
                        value={
                          country?.filter(
                            (obj) =>
                              obj?.CountryCode ===
                              item?.OriginCode?.substring(0, 2)
                          )[0]?.CountryName ?? ""
                        }
                        label="COUNTRY NAME"
                        variant="outlined"
                      />
                    </FormControl>
                    <br />
                    <br />

                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                      className="details-section"
                    >
                      <TextField
                        style={{ color: "var(--color-mile)" }}
                        name="Origin Country port"
                        value={item?.OriginName ?? ""}
                        label="ORIGIN PORT"
                        variant="outlined"
                      />
                    </FormControl>
                    <br />
                    <br />

                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: "1rem" }}
                      className="details-section"
                    >
                      <TextField
                        label="COUNTRY PIN/ZIP CODE"
                        variant="outlined"
                        name="originPin"
                        value={formOriginData.originPin}
                        onChange={(e) =>
                          handleDropdownOriginChange(
                            e,
                            e.target.value,
                            "originPin"
                          )
                        }
                        // disabled={!(isCountryPortSelected && isCountryPortSelected1)}
                        disabled={
                          formdropData.movementType === "P2D" ||
                          formdropData.movementType === "P2P"
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                    <Typography className="pb-3">
                      <LocationOnIcon color="primary" />
                      <span className="card-rms">Destination</span>
                    </Typography>

                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                      className="details-section"
                    >
                      <TextField
                        style={{ color: "var(--color-mile)" }}
                        name="Destination Country"
                        value={
                          country?.filter(
                            (obj) =>
                              obj?.CountryCode ===
                              item?.DestinationCode?.substring(0, 2)
                          )[0]?.CountryName ?? ""
                        }
                        label="DESTINATION NAME"
                        variant="outlined"
                      />
                    </FormControl>
                    <br />
                    <br />
                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                      className="details-section"
                    >
                      <TextField
                        style={{ color: "var(--color-mile)" }}
                        name="Destination Country port"
                        value={item?.DestinationName ?? ""}
                        label="DESTINATION PORT"
                        variant="outlined"
                      />
                    </FormControl>
                    <br />
                    <br />
                    <FormControl
                      fullWidth
                      sx={{ paddingBottom: "1rem" }}
                      className="details-section"
                    >
                      <TextField
                        label="COUNTRY PIN/ZIP CODE"
                        variant="outlined"
                        name="destPin"
                        value={formOriginData.destPin}
                        // onChange={handleDropdownOriginChange}
                        onChange={(e) =>
                          handleDropdownOriginChange(
                            e,
                            e.target.value,
                            "destPin"
                          )
                        }
                        // disabled={!(isDestCountryPortSelected && isDestCountryPortSelected1)}
                        disabled={
                          formdropData.movementType === "D2P" ||
                          formdropData.movementType === "P2P"
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
        {/* --LAST-- */}
        <Grid item lg={3} md={12} sm={12}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            {loading ? (
              <>
                <Skeleton variant="rounded" width={"100%"} height={"16em"} />
              </>
            ) : (
              <>
                <Typography className="pb-3">
                  {/* <Switch {...label} defaultChecked size="small" /> */}
                  <Switch
                    checked={hazardous}
                    onChange={handleToggleHazardous}
                    defaultChecked={false}
                    size="small"
                    disabled={!ishazardous}
                  />
                  <span>Hazardous goods</span>
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    name="imoClass"
                    value={!ishazardous ? "" : formData.imoClass}
                    onChange={handleInputChange}
                    label="IMO CLASS"
                    variant="outlined"
                    disabled={!hazardous || !ishazardous}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    name="hsCode"
                    value={!ishazardous ? "" : formData.hsCode}
                    onChange={handleInputChange}
                    label="HS CODE"
                    variant="outlined"
                    disabled={!hazardous || !ishazardous}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    name="unNumber"
                    value={!ishazardous ? "" : formData.unNumber}
                    onChange={handleInputChange}
                    label="UN NUMBER"
                    variant="outlined"
                    disabled={!hazardous || !ishazardous}
                  />
                </FormControl>
              </>
            )}
          </Paper>
        </Grid>
        {/* --BOTTOM-- */}
        <Grid item lg={12} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{ padding: "24px", height: "100%", width: "100%" }}
          >
            {loading ? (
              <>
                <Skeleton variant="rounded" width={"100%"} height={"100%"} />
              </>
            ) : (
              <>
                <Grid container spacing={2}>
                  <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
                    {/* <Grid container spacing={2}> */}
                    <Grid item lg={12} md={12} sm={12}>
                      <FormControl
                        fullWidth
                        sx={{ paddingBottom: "1rem" }}
                        className="details-section"
                      >
                        <TextField
                          name="shipperNo"
                          value={formData.shipperNo}
                          onChange={handleInputChange}
                          label="SHIPPER INVOICE NO."
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl
                        fullWidth
                        sx={{ paddingBottom: "1rem" }}
                        className="details-section"
                      >
                        <TextField
                          name="goodValue"
                          value={formData.goodValue}
                          onChange={handleInputChange}
                          label="GOOD VALUE"
                          variant="outlined"
                        />
                      </FormControl>
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                  <Grid item lg={4} md={12} sm={12} sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item lg={12} md={12} sm={12} sx={{ width: "100%" }}>
                        <Grid container spacing={2}>
                          <div className="row w-100 ml-0 mb-3 mt-3">
                            <div className="col-md-6">
                              <Grid item style={{ display: "grid" }}>
                                <FormControl className="details-section">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DatePicker
                                      className="datepicker form-control"
                                      label="CARGO TYPE DATE"
                                      size="small"
                                      value={selectedDate || Todays_Date}
                                      onChange={(e) => handleDateChange(e)}
                                      renderInput={(params) => (
                                        <TextField size="small" {...params} />
                                      )}
                                      minDate={today}
                                    />
                                  </LocalizationProvider>
                                </FormControl>
                              </Grid>
                            </div>
                            <div className="col-md-6">
                              <Grid item>
                                <FormControl
                                  fullWidth
                                  className="details-section w-100 form-control"
                                >
                                  <TextField
                                    name="WEIGHT"
                                    disabled={true}
                                    value={`${
                                      QuotationData?.current?.GrossWeight ??
                                      "N/A"
                                    } ${
                                      QuotationData?.current?.WeightUnit ?? ""
                                    }`}
                                    label="WEIGHT"
                                    variant="outlined"
                                    className="form-control"
                                  />
                                </FormControl>
                              </Grid>
                            </div>
                          </div>
                        </Grid>

                        <FormControl
                          fullWidth
                          sx={{ paddingBottom: "1rem" }}
                          className="details-section"
                        >
                          <TextField
                            name="additionalServices"
                            value={formData.additionalServices}
                            onChange={handleInputChange}
                            label="ADDITIONAL SERVICES"
                            variant="outlined"
                            style={{ marginRight: "15px" }}
                          />
                          {validationErrors.additionalServices && (
                            <div className="error">
                              {validationErrors.additionalServices}
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={5} md={12} sm={12} sx={{ width: "100%" }}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={6} sx={{ width: "100%" }}>
                        <FormControl
                          fullWidth
                          sx={{ paddingBottom: "1rem" }}
                          className="details-section"
                        >
                          <TextField
                            name="COMMODITY"
                            value={
                              QuotationData?.current?.CommodityDetails ?? "N/A"
                            }
                            disabled={true}
                            label="COMMODITY"
                            variant="outlined"
                            multiline
                            rows={3}
                          />
                          {validationErrors.goodDesc && (
                            <div className="error">
                              {validationErrors.goodDesc}
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} sx={{ width: "100%" }}>
                        <FormControl
                          fullWidth
                          sx={{ paddingBottom: "1rem" }}
                          className="details-section"
                        >
                          <TextField
                            name="goodDesc"
                            value={formData.goodDesc}
                            onChange={handleInputChange}
                            label="GOOD DESCRIPTION."
                            variant="outlined"
                            multiline
                            rows={3}
                          />
                          {validationErrors.goodDesc && (
                            <div className="error">
                              {validationErrors.goodDesc}
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* <div className="d-flex justify-content-end align-items-center">
              <IconButton
                sx={{
                  borderRadius: "50%",
                  width: "32px",
                  backgroundColor: "var(--color-secondary-dark)",
                  color: "var(--color-white)",
                  "&:hover": {
                    backgroundColor: "var(--color-secondary-dark)",
                  },
                }}

                onClick={() => {
                  handleRmsDataChange()
                }}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </div> */}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* SECOND CONTAINER DATA INFORMATION */}
      <CreateRmsList
        containerDataInformation={containerData.current}
        QuotationData={QuotationData.current}
        item={item}
        handleRmsDataChangeprevious={handleRmsDataChange}
        FinalInformation={FinalInformation}
        initialLoading={loading}
      />
    </>
  );
};

export default DetailsRms;
