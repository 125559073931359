import { useState, useEffect, useContext, useRef } from "react";
import { API_PATH } from "../../constant/apiPath";
import instance from "../../api/Interceptor";
import { DateFormat } from "../../utils/moment";
import { AuthContext } from "../../store/context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import CustomTooltip from "../../utils/CustomTooltip";
const Etaetd = () => {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const [eta, setEta] = useState([]);
  const [etd, setEtd] = useState([]);
  const etaData = useRef(0);
  const etdData = useRef(0);
  const etaHasMoreData = useRef(true);
  const etdHasMoreData = useRef(true);
  const geteta = () => {
    // setisLoading(true);
    etaData.current += 10;
    const body = {
      CurrentPage: 0,
      PageSize: etaData.current,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_ETA_EXPECTATION, body)
      .then((res) => {
        // setisLoading(false);
        if (res?.data.length === res?.totalCount)
          etaHasMoreData.current = false;
        setEta(res?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setisLoading(false);
      });
  };

  const getetd = () => {
    // setisLoading(true);
    etdData.current += 10;
    const body = {
      CurrentPage: 0,
      PageSize: etdData.current,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_ETD_EXPECTATION, body)
      .then((res) => {
        // setisLoading(false);
        if (res?.data.length === res?.totalCount)
          etdHasMoreData.current = false;
        setEtd(res?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setisLoading(false);
      });
  };

  useEffect(() => {
    geteta();
    getetd();
  }, []);

  return (
    <>
      <div className="eta-expectation-container">
        <div className="heading-eta-expectation">
          ETA Exception <CustomTooltip of="Dashboard" forKey="ETA_Exception" />
        </div>
        <div className="data-eta-expectation" id="scrollableETA">
          {/* <div className="table-head eta-expectation-header">
            <div>Shipment Number</div> */}
          <div className="table-head  d-flex sticky-top p-1">
            <div className="col-4">Shipment Number</div>
            <div
              className="col-4"
              // className="old-eta-heading"
              // style={{ paddingRight: "45px" }}
            >
              ETA Date
            </div>
            <div
              className="col-4 "
              // className="new-eta-heading"
              // style={{ paddingRight: "5px" }}
            >
              Discharge Port
            </div>
          </div>
          {true ? (
            <>
              <InfiniteScroll
                dataLength={eta?.length} //if this value change next method will fire
                next={geteta}
                hasMore={etaHasMoreData.current} //if true loader will appear
                loader={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "2px",
                    }}
                  >
                    <CircularProgress size={"10px"} />
                  </Box>
                }
                scrollableTarget="scrollableETA"
              >
                {eta.map((item, index) => {
                  return (
                    // <div
                    //   className=" table-data individual-eta-expectation"
                    //   key={index}
                    // >
                    <div className=" table-data" key={index}>
                      {/* <div
                      className="eta-expectation-shipment-data"
                      style={{ cursor: "pointer", color: "var(--color-secondary-dark)" }}
                      onClick={() => {
                        context.setGridViewShipment(false);
                        navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                          state: {
                            ShipmentId: item.ShipmentId,
                            ETA: item.ETADate,
                            ETD: item.ETDDate,
                            ORIGIN: item.OriginPortName,
                            DESTINATION: item.DestinationPortName,
                          },
                        });
                      }}
                    >
                      {item?.ShipmentId ?? "N/A"}
                    </div> */}
                      <div
                        className="col-4 shipment-head"
                        onClick={() => {
                          context.setGridViewShipment(false);
                          context.setShipmentId(item?.ShipmentId);
                          navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                            state: {
                              ShipmentId: item.ShipmentId,

                              // ETA: item.ETADate,
                              // ETD: item.ETDDate,
                              // ORIGIN: item.OriginPortName,
                              // DESTINATION: item.DestinationPortName,
                              // TransportMode: item.TransportMode,
                              // HouseBillNumber: item.HBLNumber,
                            },
                          });
                        }}
                      >
                        {item?.ShipmentId ?? "N/A"}
                      </div>
                      <div
                        // className="old-eta-data"
                        className="col-4 shipment-head-list "
                        // style={{ position: "relative", left: "-27px" }}
                      >
                        {DateFormat(item?.ETADate) ?? "N/A"}
                      </div>
                      {/* <div className="port-code">
                      {" "}
                      {item?.DischargePortCode ?? "N/A"}
                    </div> */}
                      <div className="col-4 shipment-head-dta">
                        {item?.DischargePortCode ?? "N/A"}
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </>
          ) : (
            <div className="font-change">No data found!</div>
          )}
        </div>
      </div>
      <div className="etd-expectation-container">
        <div className="heading-etd-expectation">
          ETD Exception <CustomTooltip of="Dashboard" forKey="ETD_Exception" />
        </div>
        <div className="data-etd-expectation" id="scrollableEDA">
          {/* <div className="table-head etd-expectation-header">
            <div>Shipment Number</div> */}
          <div className="table-head d-flex sticky-top p-1">
            <div className="col-4 ">Shipment Number</div>
            {/* <div style={{ paddingLeft: "3px" }}>ETD Date</div>
            <div className="new-etd-heading">Load Port</div> */}
            <div className="col-4">ETD Date</div>
            <div className="col-4 ">Load Port</div>
          </div>
          {true ? (
            <>
              <InfiniteScroll
                dataLength={etd?.length} //if this value change next method will fire
                next={getetd}
                hasMore={etdHasMoreData.current} //if true loader will appear
                loader={
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "2px",
                    }}
                  >
                    <CircularProgress size={"10px"} />
                  </Box>
                }
                scrollableTarget="scrollableEDA"
              >
                {etd.map((item, index) => {
                  return (
                    // <div
                    //   className=" table-data individual-eta-expectation"
                    //   key={index}
                    // >
                    <div className=" table-data" key={index}>
                      {/* <div
                      className="etd-expectation-shipment-data"
                      style={{ cursor: "pointer", color: "var(--color-secondary-dark)" }}
                      onClick={() => {
                        context.setGridViewShipment(false);
                        navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                          state: {
                            ShipmentId: item.ShipmentId,
                            ETA: item.ETADate,
                            ETD: item.ETDDate,
                            ORIGIN: item.OriginPortName,
                            DESTINATION: item.DestinationPortName,
                          },
                        });
                      }}
                    >
                      {item?.ShipmentId ?? "N/A"}
                    </div> */}
                      <div
                        className="col-4 shipment-head"
                        style={{
                          cursor: "pointer",
                          color: "var(--color-primary)",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "5rem",
                        }}
                        onClick={() => {
                          context.setGridViewShipment(false);
                          context.setShipmentId(item?.ShipmentId);
                          navigate(Routeconstant.SHIPMENT_LISTING_DETAILS, {
                            state: {
                              ShipmentId: item.ShipmentId,
                              // ETA: item.ETADate,
                              // ETD: item.ETDDate,
                              // ORIGIN: item.OriginPortName,
                              // DESTINATION: item.DestinationPortName,
                            },
                          });
                        }}
                      >
                        {item?.ShipmentId ?? "N/A"}
                      </div>
                      {/* <div style={{ position: "relative", left: "-13px" }}>
                      {DateFormat(item?.ETDDate) ?? "N/A"}
                    </div> */}
                      <div className="col-4 shipment-head-list">
                        {DateFormat(item?.ETDDate) ?? "N/A"}
                      </div>
                      <div
                        className="col-4 shipment-head-dta"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "5rem",
                        }}
                      >
                        {item?.LoadPortCode ?? "N/A"}
                      </div>
                      {/* <div style={{ paddingLeft: "10px" }}>
                      {item?.LoadPortCode ?? "N/A"}
                    </div> */}
                    </div>
                  );
                })}
              </InfiniteScroll>
            </>
          ) : (
            <div className="font-change">No data found!</div>
          )}
        </div>
      </div>
    </>
  );
};

export default Etaetd;
