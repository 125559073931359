import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";

import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";

import { Grid, Typography } from "@mui/material";
import { useState } from "react";
import urlResolver from "../../../../../utils/urlResolver";
import { useSelector } from "react-redux";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function MapComp(props) {
  const { data } = useSelector((state) => state.order.orderDetailsFromAPI);

  // console.log(props?.cordinates[0]?.originPort?.Lat, "mapData");

  const routes = [
    {
      lat: props?.cordinates[0]?.originPort?.Lat,
      lng: props?.cordinates[0]?.originPort?.Lng,
    },
    {
      lat: props?.cordinates[0]?.desnationPort?.Lat,
      lng: props?.cordinates[0]?.desnationPort?.Lng,
    },
  ];
  const pathOptions = {
    geodesic: false,
    strokeColor: "var(--color-dark)",
    strokeOpacity: 1,
    icons: [
      {
        icon: {
          path: props.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeOpacity: 0,
          fillColor: "var(--color-dark)",
          fillOpacity: 1.0,
          scale: 4,
        },
        repeat: "80px",
      },
    ],
  };
  return (
    // <Grid
    //   container
    //   spacing={4}
    //   sx={{
    //     position: "relative",
    //     top: "37px",
    //     left: "40px",
    //     width: "100%",
    //     height: "100%",

    //     "@media only screen  and (max-width: 424px)": {
    //       width: "96%",
    //     },
    //     borderRadius: "1rem",
    //   }}
    // >
    <Map
      icon={{
        url: urlResolver("assets/location.gif"),
        scaledSize: new window.google.maps.Size(20, 31),
      }}
      google={props.google}
      zoom={1}
      initialCenter={{
        lat: 42.39,
        lng: -72.52,
      }}
      className="scdc"
      // bounds={points}
    >
      <Marker
        icon={{
          url: urlResolver("assets/marker-new.png"),
          scaledSize: new window.google.maps.Size(20, 31),
        }}
        // key={item}
        position={{
          lat: props?.cordinates[0]?.originPort?.Lat,
          lng: props?.cordinates[0]?.originPort?.Lng,
        }}
        // title={item?.Code}
        // name={item?.Description}
      />

      <Polyline
        path={routes ?? []}
        strokeColor="#0000FF"
        strokeOpacity={0.8}
        options={pathOptions}
        strokeWeight={2}
      />
      <Marker
        icon={{
          url: urlResolver("assets/marker-new.png"),
          scaledSize: new window.google.maps.Size(20, 31),
        }}
        position={{
          lat: props?.cordinates[0]?.desnationPort?.Lat,
          lng: props?.cordinates[0]?.desnationPort?.Lng,
        }}
        // animation={
        //   isMarkerBlinking ? props.google.maps.Animation.BOUNCE : null
        // }
        // onClick={handleMarkerClick}
      />
      {/* <Marker
                icon={{
                  url: urlResolver("assets/location.png`,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                
                // title={`${item?.CountryName ?? "N/A"}(${item?.Count ?? "N/A"})`}
                // name={`${item?.CountryName ?? "N/A"}(${item?.Count ?? "N/A"})`}
                position={{
                  lat: pinData.lat,
                  lng: pinData.lng,
                  // lat: 40.9,
                  // lng: -74.067,
                }}
              /> */}
    </Map>
    // </Grid>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0",
})(React.memo(MapComp));
