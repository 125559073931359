export const Routeconstant = {
  // Shipment Details without Login
  SHIPMENTDETAILS: "/shipment/details/:id",
  // Auth
  LOGIN: "/login",
  TRACKYOURSHIPMENT: "/trackyourshipment",
  RESETPASSWORD: `/reset-password/:id`,
  FORGETPASSWORD: "/forget-password",

  // RMS
  RMS: "/rms",
  CREATERMS: "/createrms/:QuotationId",
  CREATERMSLIST: "/createrms/list",
  DOCUMENTRMS: "/documentrms",
  CARGORATES: "/cargorates",
  RMSREPORT: "/rmsreport/:inquiryNo",
  CREATERATES: "/createrates",
  QUOTESRATES: "/quoterates",
  //Shipment Listing
  SHIPMENT_LISTING: "/shipment/listing",
  SHIPMENT_LISTING_DETAILS: "/shipment/listing/details",
  DETAILS: "details",
  ROUTING: "routing",
  CARGO_DETAILS: "cargodetails",
  DOCUMENTATION: "documentation",

  //Shipment Analyics
  SHIPMENT_ANALYTICS: "/shipment/analytics",

  // Order Listing
  CREATE_ORDER: "/createorder",
  ORDER_DASHBOARD: "/order",
  ORDER_REPORTS: "/order/reports",
  ORDER_LISTING: "/order/listing",
  ORDER_DETAILS: "/order/details/:orderid",
  ORDER_ROUTING: "routing",
  ORDER_CARGO_DETAILS: "cargodetails",
  ORDER_DOCUMENTATION: "documentation",
  ORDER_STATUS: "/order/status",
  ORDER_TRACK: "/order/track/:orderId",
  SYSTEM_LOGS: "/order/system-log/:orderID",
  ADMIN_SETTINGS: "/order/admin-settings",
  //Order Analyics
  // ORDER_ANALYTICS: "/order/analytics",

  //Enquiry
  ENQUIRY: "/enquiry",
  ENQUIRYDASHBOARD: "/Enquirydashboard",
  ENQUIRYPREVIEW: "/Enquirypreview/:inquiryNo",

  // Statement of Account
  STATEMENT: "/statement",

  // User Logs
  USER_LOGS: "/user",
  USER_Orgination_mapping: "/Org_Mapping",

  //  Landing
  LANDING_PAGES: "/",
};
