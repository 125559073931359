import React, { useEffect, useState, useRef, useContext } from "react";
import "../shipment/shipment.css";
import "./rmsMargin.css";
import { IconButton, Tabs, Tab, Tooltip } from "@mui/material";
import { basename } from "../../Config";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import instance from "../../api/Interceptor";
import dayjs from "dayjs";
import FilterModalRms from "./components/FilterModalRmsMargin";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import Backdrop from "@mui/material/Backdrop";
import { AuthContext } from "../../store/context/AuthProvider";

function AlertDialog({
  setOpen,
  open,
  rejectReason,
  setRejectReason,
  Quotation,
  AcceptRejecClickHandler,
  setBackdropOpen,
}) {
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setRejectReason("");
  }, []);
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "700px", // Adjust the maximum width as needed
            width: "100%", // Make sure the width is set to 100% to fill the available space
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {`Provide the reason of rejection of Quotation Number(${Quotation.current})`}
        </DialogTitle>
        <DialogContent>
          <TextField
            id="standard-multiline-flexible"
            style={{
              textAlign: "center",
              maxWidth: "700px",
              width: "100%",
            }}
            label="Reason"
            multiline
            variant="standard"
            value={rejectReason}
            onChange={(event) => {
              setRejectReason(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            autoFocus
          >
            cancel
          </Button>
          <Button
            onClick={() => {
              setBackdropOpen(true);
              AcceptRejecClickHandler(Quotation, 2, rejectReason);
              handleClose();
            }}
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const RmsMargin = () => {
  const navigate = useNavigate();
  const hasmoreData = useRef(10);
  const hasmoreDataBooking = useRef(10);
  const [showmoreData, setShowMoreData] = useState(true);
  const [showmoreDataBooking, setShowMoreDataBooking] = useState(true);
  const [getQuoteData, setQuoteRmsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showmoreLoader, setShowMoreLoader] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [bookingData, setBookingData] = useState([]);
  const [sortmodal, setSortmodal] = useState(false);
  const [rejectDialogueStatus, setRejectDialogueStatus] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const Quotation = useRef(null);
  const indexOfCard = useRef(null);
  const [BackdropOpen, setBackdropOpen] = useState(false);
  const [filterModalData, setFilterModalData] = useState({
    Carrier: "",
    Origin: "",
    Destination: "",
    ValidatFrom: null,
    ValidTo: null,
    TransportMode: "",
  });
  const showMoreClicked = useRef(false);
  const filtterDAta = useRef({
    Carrier: "",
    Origin: "",
    Destination: "",
    ValidatFrom: null,
    ValidTo: null,
    TransportMode: "",
  });
  const context = useContext(AuthContext);

  const [tab, setTab] = useState("Quote");
  const handleInquiryClick = (inquiryNo) => {
    navigate(`/rmsreport/${inquiryNo}`);
  };
  const handleQuotationClick = (item) => {
    context.setQuotationID(item.QuotationId);
    navigate(`/createrms/${item.QuotationId}`, { state: item });
  };
  const AcceptRejecClickHandler = (Quotation, status, reason) => {
    const body = {
      Status: status,
      RejectReason: reason,
      QuotationNo: Quotation.current,
    };
    instance
      .post("api/v1/Quotation/UpdateQuoteStatus", body)
      .then((res) => {
        getQuoteData[indexOfCard.current].QuoteStatus = status;
        getQuoteData[indexOfCard.current].RejectReason = reason;
        setQuoteRmsData((prev) => [...prev]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setBackdropOpen(false);
      });
  };

  const handlesearch = (e) => {
    const searchValue = e.target.value.trim();
    setSearchValue(searchValue);
  };
  // useEffect(() => {
  //   getRmsDetails();
  // }, []);

  useEffect(() => {
    hasmoreData.current = 10;
    hasmoreDataBooking.current = 10;
    setSearchValue("");
    setFilterModalData({
      Carrier: "",
      Origin: "",
      Destination: "",
      ValidatFrom: null,
      ValidTo: null,
      TransportMode: "",
    });
    filtterDAta.current = {
      Carrier: "",
      Origin: "",
      Destination: "",
      ValidatFrom: null,
      ValidTo: null,
      TransportMode: "",
    };
    if (tab === "Quote") {
      getQuoteDetails("");
    } else {
      getRmsDetails("");
    }
  }, [tab]);

  // booking api call
  const getRmsDetails = (search) => {
    if (showMoreClicked.current === false) setLoading(true);
    const body = {
      OriginPortName: filtterDAta.current.Origin ?? "",
      DestinationCode: filtterDAta.current.Destination ?? "",
      TransportMode: filtterDAta.current.TransportMode ?? "",
      CurrentPage: 0,
      PageSize: hasmoreDataBooking.current,
      SortColumnName: "",
      SortDirection: "",
      QuotationNo: "",
      InquiryNo: search.at(0) === "B" ? search :"",
      OriginCode: "",
      DestinationPortName: "",
      ShowDeveloperResponse: true,
      CWBookingNumber: search.at(0) === "B" ? "" :search,
    };
    instance
      .post("api/v1/RateManagement/GetRateCollection", body)
      .then((res) => {
        const data = res.data;
        setBookingData(data);
        if (res?.totalCount <= hasmoreDataBooking.current) {
          setShowMoreDataBooking(false);
        } else {
          setShowMoreDataBooking(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setShowMoreLoader(false);
        showMoreClicked.current = false;
      });
  };

  // quote api call
  const getQuoteDetails = (search) => {
    if (showMoreClicked.current === false) setLoading(true);
    const body = {
      QuotationNo: search ?? "",
      Carrier: filtterDAta.current.Carrier ?? "",
      OriginPortName: filtterDAta.current.Origin ?? "",
      DestinationCode: filtterDAta.current.Destination ?? "",
      ValidFrom: filtterDAta.current.ValidatFrom ?? "",
      ValidTo: filtterDAta.current.ValidTo ?? "",
      CurrentPage: 0,
      PageSize: hasmoreData.current,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post("api/v1/Quotation/GetQuoteCollection", body)
      .then((res) => {
        setQuoteRmsData(res.data ?? []);
        if (res?.totalCount <= hasmoreData.current) {
          setShowMoreData(false);
        } else {
          setShowMoreDataBooking(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setShowMoreLoader(false);
        showMoreClicked.current = false;
      });
  };
  return (
    <>
      {sortmodal ? (
        <FilterModalRms
          setSortmodal={setSortmodal}
          sortmodal={sortmodal}
          setFilterModalData={setFilterModalData}
          filterModalData={filterModalData}
          getQuoteDetails={getQuoteDetails}
          tab={tab}
          getRmsDetails={getRmsDetails}
          filtterDAta={filtterDAta}
          setSearchValue={setSearchValue}
        />
      ) : null}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={BackdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="container-fluid">
        <div className="row mb-3">
          <p className="layout-head mb-2 px-4">Shipments</p>
          <div className="d-flex  flex-row justify-content-center align-items-center">
            {/* filter button */}
            <div
              className={`col-lg-1 col-md-1 col-xs-1 d-flex justify-content-center align-items-center filter-div ${
                Object.values(filtterDAta.current).filter((element) => {
                  if (element?.length > 0) return 1;
                }).length > 0
                  ? "color-style"
                  : ""
              }`}
            >
              <div style={{ marginRight: "10%" }}>
                <FilterAltOutlinedIcon
                  style={{ cursor: "pointer" }}
                  color={`${
                    Object.values(filtterDAta.current).filter((element) => {
                      if (element?.length > 0) return 1;
                    }).length > 0
                      ? "third"
                      : "primary"
                  }`}
                  onClick={() => {
                    setSortmodal(true);
                  }}
                />
              </div>
              <div className=" d-flex cursorPointer ">
                {Object.values(filtterDAta.current).filter((element) => {
                  if (element?.length > 0) return 1;
                }).length > 0 && (
                  <div className="d-flex ps-2">
                    <div style={{ border: "1px solid white" }} />
                    <IconButton size="small" style={{ marginLeft: "20%" }}>
                      <CloseOutlinedIcon
                        color="third"
                        onClick={() => {
                          setFilterModalData((prev) => {
                            return {
                              ...prev,
                              Carrier: "",
                              Origin: "",
                              Destination: "",
                              ValidatFrom: null,
                              ValidTo: null,
                              TransportMode: "",
                            };
                          });
                          filtterDAta.current = {
                            Carrier: "",
                            Origin: "",
                            Destination: "",
                            ValidatFrom: null,
                            ValidTo: null,
                            TransportMode: "",
                          };
                          if (tab === "Quote") {
                            getQuoteDetails();
                          } else {
                            getRmsDetails();
                          }
                        }}
                      />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            {/* search bar */}
            <div className="col-lg-10 col-md-11 col-xs-11">
              <div className="headersearch searchShipmentDiv ">
                <input
                  type="text"
                  className="form-control "
                  placeholder={
                    tab === "Quote"
                      ? "Search your Quote Number"
                      : "Search your Booking/CWBooking Number"
                  }
                  value={searchValue}
                  onChange={handlesearch}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") {
                      if (tab === "Quote") {
                        getQuoteDetails(searchValue);
                      } else {
                        getRmsDetails(searchValue);
                      }
                    }
                  }}
                />
                <img
                  src={`/${basename}/assets/search_icon.svg`}
                  style={{ color: "rgba(var(--text-heading-dark))" }}
                  className="searchIcon"
                  alt=""
                />
                {searchValue && (
                  <CloseOutlinedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      cursor: "pointer",
                    }}
                    className="searchClearIcon"
                    onClick={() => {
                      setSearchValue("");
                      if (tab === "Quote") {
                        getQuoteDetails("");
                      } else {
                        getRmsDetails("");
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {/* get rate */}
            <div className="col-md-1 col-xs-1 buttons-container">
              <IconButton size="large" onClick={() => navigate("/createrates")}>
                Get Rates
              </IconButton>
            </div>
          </div>
          <br />
          <br />
          <Tabs
            value={tab}
            onChange={(event, newValue) => setTab(newValue)}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              value="Quote"
              label="Quote"
              className={tab === "Quote" ? "active-tab" : "non-active"}
            />
            <Tab
              value="Booking"
              label="Booking"
              className={tab === "Booking" ? "active-tab" : "non-active"}
            />
          </Tabs>
        </div>
      </div>

      {/* quote table */}
      <div
        className="tableWrap"
        style={{ display: tab === "Quote" ? "block" : "none" }}
      >
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="font-table">
                Quotation Id
              </th>
              <th scope="col" className="font-table">
                Carrier Name
              </th>
              <th scope="col" className="font-table">
                Commodity Group Code
              </th>
              <th scope="col" className="font-table">
                Origin Port
              </th>
              <th scope="col" className="font-table">
                Destination Port
              </th>
              <th scope="col" className="font-table">
                Via Code
              </th>
              <th scope="col" className="font-table">
                Valid From Date
              </th>
              <th scope="col" className="font-table">
                Valid To Date
              </th>
              <th scope="col" className="font-table">
                Created Date
              </th>
              <th scope="col" className="font-table">
                Total
              </th>
              <th scope="col" className="font-table">
                Status
              </th>
              <th scope="col" className="font-table">
                Action
              </th>
              <th scope="col" className="font-table">
                Rejected Reason
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <>
                {new Array(6).fill(1).map(() => {
                  return (
                    <tr>
                      {new Array(11).fill(1).map(() => {
                        return (
                          <td>
                            <Skeleton
                              animation="wave"
                              width={210}
                              height={60}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </>
            ) : (
              <>
                {getQuoteData.length === 0 ? (
                  <>
                    {
                      getQuoteData.length === 0
                        ? (
                          <>
                            <div
                              className="d-flex justify-content-center align-items-center"
                              style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                width: "100%"
                              }}
                            >NO DATA FOUND</div>
                          </>
                        )
                        : (
                          <>
                            {getQuoteData.map((item, index) => (
                              <tr key={index}>
                                <td
                                  style={{ cursor: "pointer", color: "var(--color-mile)" }}
                                  onClick={() => handleInquiryClick(item.QuotationId)}
                                > {item.QuotationId}</td>

                                <td>{item.CarrierName}</td>
                                <td>{item.CommodityGroupCode}</td>
                                <td>{item.OriginName}</td>
                                <td>{item.DestinationName}</td>
                                <td>{item.ViaCode}</td>
                                <td>{dayjs(item?.ValidFromDate).format('DD-MM-YYYY')}</td>
                                <td>{dayjs(item?.ValidToDate).format('DD-MM-YYYY')}</td>
                                <td>{dayjs(item?.CreateDateTimeUTC).format('DD-MM-YYYY')}</td>
                                <td>{item.CurrencyCode} {item.TotalRate}</td>
                                <td>{
                                  item.QuoteStatus === 0
                                    ? <span style={{ color: "var(--color-orange)" }}>Pending</span>
                                    : (item.QuoteStatus === 1
                                      ? <span style={{ color: "var(--color-ternary)" }}>Accepted</span>
                                      : (item.QuoteStatus === 2
                                        ? <span style={{ color: "var(--color-miscellaneous-7)" }}>Rejected</span>
                                        : <span style={{ color: "var(--color-primary-dark)" }}>Converted</span>
                                      )
                                    )
                                }</td>
                                <td>
                                  {
                                    item.QuoteStatus === 0 ? (
                                      <>
                                        < Button style={{ marginRight: "5px" }} variant="outlined" color="success" size="small" onClick={() => {
                                          Quotation.current = item.QuotationId
                                          indexOfCard.current = index
                                          AcceptRejecClickHandler(Quotation, 1, "")
                                          setBackdropOpen(true)
                                        }
                                        }>
                                          Accepted
                                        </Button>
                                        <Button variant="outlined" color="error" size="small" onClick={() => {
                                          Quotation.current = item.QuotationId
                                          indexOfCard.current = index
                                          setRejectDialogueStatus(true)
                                        }}>
                                          Rejected
                                        </Button>
                                      </>
                                    ) : (item.QuoteStatus === 1 ? <Button variant="outlined" color="primary" size="small" onClick={() => handleQuotationClick(item)}>Convert To Booking</Button> : "")
                                  }
                                </td>
                                <td>
                                  <Tooltip title={item.RejectReason}>
                                    <span>{item.RejectReason.substring(0, 15)}{item.RejectReason.length > 15 ? '...' : ''}</span>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )
                    }
                  </>
                ) : (
                  <>
                    {getQuoteData.map((item, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            cursor: "pointer",
                            color: "var(--color-primary)",
                          }}
                          onClick={() => handleInquiryClick(item.QuotationId)}
                        >
                          {" "}
                          {item.QuotationId}
                        </td>

                        <td>{item.CarrierName}</td>
                        <td>{item.CommodityGroupCode}</td>
                        <td>{item.OriginName}</td>
                        <td>{item.DestinationName}</td>
                        <td>{item.ViaCode}</td>
                        <td>
                          {dayjs(item?.ValidFromDate).format("DD-MM-YYYY")}
                        </td>
                        <td>{dayjs(item?.ValidToDate).format("DD-MM-YYYY")}</td>
                        <td>
                          {dayjs(item?.CreateDateTimeUTC).format("DD-MM-YYYY")}
                        </td>
                        <td>{item.CurrencyCode} {item.TotalRate}</td>
                        <td>
                          {item.QuoteStatus === 0 ? (
                            <span style={{ color: "var(--color-orange)" }}>
                              Pending
                            </span>
                          ) : item.QuoteStatus === 1 ? (
                            <span style={{ color: "var(--color-ternary)" }}>
                              Accepted
                            </span>
                          ) : item.QuoteStatus === 2 ? (
                            <span
                              style={{ color: "var(--color-miscellaneous-7)" }}
                            >
                              Rejected
                            </span>
                          ) : (
                            <span
                              style={{ color: "var(--color-primary-dark)" }}
                            >
                              Converted
                            </span>
                          )}
                        </td>
                        <td>
                          {item.QuoteStatus === 0 ? (
                            <>
                              <Button
                                style={{ marginRight: "5px" }}
                                variant="outlined"
                                color="success"
                                size="small"
                                onClick={() => {
                                  Quotation.current = item.QuotationId;
                                  indexOfCard.current = index;
                                  AcceptRejecClickHandler(Quotation, 1, "");
                                  setBackdropOpen(true);
                                }}
                              >
                                Accepted
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={() => {
                                  Quotation.current = item.QuotationId;
                                  indexOfCard.current = index;
                                  setRejectDialogueStatus(true);
                                }}
                              >
                                Rejected
                              </Button>
                            </>
                          ) : item.QuoteStatus === 1 ? (
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={() => handleQuotationClick(item)}
                            >
                              Convert To Booking
                            </Button>
                          ) : (
                            ""
                          )}
                        </td>
                        <td>
                          <Tooltip title={item.RejectReason}>
                            <span>
                              {item.RejectReason.substring(0, 15)}
                              {item.RejectReason.length > 15 ? "..." : ""}
                            </span>
                          </Tooltip>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
        {showmoreData && !loading && getQuoteData.length > 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: "12px" }}
          >
            {showmoreLoader ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  hasmoreData.current += 10;
                  showMoreClicked.current = true;
                  setShowMoreLoader(true);
                  getQuoteDetails("");
                }}
              >
                show more
              </Button>
            )}
          </div>
        )}
      </div>
      {/* booking table */}
      <div
        className="tableWrap"
        style={{ display: tab === "Booking" ? "block" : "none" }}
      >
        <table className="table">
          <thead>
            <tr>
              {/* <th scope="col"></th> */}
              <th scope="col" className="font-table">
                Booking Number
              </th>
              <th scope="col" className="font-table">
                CWBooking Number
              </th>
              <th scope="col" className="font-table">
                Quotation Number
              </th>
              <th scope="col" className="font-table">
                Transport Mode
              </th>
              <th scope="col" className="font-table">
                Origin Port
              </th>
              <th scope="col" className="font-table">
                Destination Port
              </th>
              <th scope="col" className="font-table">
                INCO Terms
              </th>
              <th scope="col" className="font-table">
                Cargo Type
              </th>
              <th scope="col" className="font-table">
                Cargo Ready Date
              </th>
              <th scope="col" className="font-table">
                Created Date
              </th>
              <th scope="col" className="font-table">
                Status
              </th>
            </tr>
          </thead>
          {loading ? (
            <>
              {new Array(6).fill(1).map(() => {
                return (
                  <tr>
                    {new Array(11).fill(1).map(() => {
                      return (
                        <td>
                          <Skeleton
                            width={210}
                            height={60}
                            style={{
                              backgroundColor: "var(--color-miscellaneous-9)",
                            }}
                          />
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <tbody>
                {bookingData.map((item, index) => (
                  <tr key={index}>
                    {/* <td>
                        <img src={`/${basename}/assets/star.svg`} alt="" />
                      </td> */}
                    <td
                      style={{
                        cursor: "pointer",
                        color: "var(--color-primary)",
                      }}
                      onClick={() => handleInquiryClick(item.InquiryNo)}
                    >
                      {" "}
                      {item.InquiryNo}
                    </td>
                    <td>{item.CWBookingNumber}</td>
                    <td>{item.QuotationNumber}</td>
                    <td>{item.TransportMode}</td>
                    <td>{item?.OriginPortName ?? item.OriginPort}</td>
                    <td>{item?.DestinationPort ?? item.DestinationPortName}</td>
                    <td>{item.Incoterm}</td>
                    <td>{item.Carotype}</td>
                    <td>{dayjs(item?.Cargoreadydate).format("DD-MM-YYYY")}</td>
                    <td>
                      {dayjs(item?.CreateDateTimeUTC).format("DD-MM-YYYY")}
                    </td>
                    <td>
                      {item.StatusCode == 0 ? (
                        <span style={{ color: "var(--color-orange)" }}>
                          Pending
                        </span>
                      ) : item.StatusCode == 1 ? (
                        <span style={{ color: "var(--color-ternary)" }}>
                          Approved
                        </span>
                      ) : item.StatusCode == 2 ? (
                        <span style={{ color: "var(--color-red)" }}>
                          Rejected
                        </span>
                      ) : (
                        <span>Unknown Status</span>
                      )}
                      {console.log("item.StatusCode:", item.StatusCode, index)}
                    </td>
                    {/* <td>
                        <span style={{ color: "var(--color-orange)" }}>{item.StatusDescription}</span>
                        </td> */}
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>
        {showmoreDataBooking && !loading && bookingData.length > 0 && (
          <div
            className="d-flex justify-content-center"
            style={{ marginBottom: "12px" }}
          >
            {showmoreLoader ? (
              <CircularProgress />
            ) : (
              <Button
                variant="outlined"
                onClick={() => {
                  hasmoreDataBooking.current += 10;
                  showMoreClicked.current = true;
                  setShowMoreLoader(true);
                  getRmsDetails("");
                }}
              >
                show more
              </Button>
            )}
          </div>
        )}
      </div>

      {rejectDialogueStatus && (
        <AlertDialog
          setOpen={setRejectDialogueStatus}
          open={rejectDialogueStatus}
          setRejectReason={setRejectReason}
          rejectReason={rejectReason}
          Quotation={Quotation}
          AcceptRejecClickHandler={AcceptRejecClickHandler}
          setBackdropOpen={setBackdropOpen}
        />
      )}
    </>
  );
};

export default RmsMargin;
