import React from "react";
import Finances from "./Finances";
import MapcompDashboard from "./MapcompDashboard";
import PendingRms from "./Favorite";
import Upcoming from "./Upcoming";
import Etaetd from "./etaetd";
import "./dashboard.css";
import accessGiven from "../../utils/permission";
export default function Dashboard() {
  const permission = accessGiven();
  return (
    <div className="dashboard-container">
      <div className="finances-favorite-container">
        {permission.financesAccess && <Finances />}

        <div
          className={
            permission.financesAccess === false
              ? "favorite-container-check"
              : "favorite-container"
          }
        >
          <div className="inside-favorite-container">
            <PendingRms />
          </div>
        </div>
      </div>
      <div className="map-etd-eta-container">
        <div className="map-upcoming-container">
          <div className="inside-container">
            <Upcoming />
          </div>
        </div>
        <div className="etd-eta-container">
          <div className="inside-container-eta-etd">
            <Etaetd />
          </div>
        </div>
      </div>
    </div>
  );
}
