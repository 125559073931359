import { baseOrderUrl } from "../../../../../Config";
import orderInstance from "../../../../../api/orderInterceptor";
import { API_PATH } from "../../../../../constant/apiPath";
const getRouteDetails = async (orderId) => {
  let data = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_ORDER_MILESTONE_DETAILS}=${orderId}`
  );
  // console.log(data, "data");
  return data;
};
const getLocationDetails = async (orderId) => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_ORDER_LOCATION_DETAILS}=${orderId}`
  );
  return data;
};
export { getRouteDetails, getLocationDetails };
