
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function CounterMargin(props) {
    const { name, TotalRate, setContainer, containers } = props
    const [count, setCount] = useState(1);
    // const handleInputChange = (event) => {
    //     const inputValue = event?.target?.value ?? 0;
    //     const numericValue = inputValue.replace(/\D/g, "");
    //     console.log(numericValue, "vlaue handel", event, " dddd", inputValue);
    //     if (0 < parseInt(numericValue) <= 99) setCount(parseInt(numericValue));
    // };
    useEffect(() => {
        const obj = { name, TotalRate, quantity: count };
        const data = containers.filter((obj) => obj.name != name)
        setContainer([...data, obj])
    }, [count]);
    return (
        <div className="d-flex">
            <button
                className="value-button"
                id="decrease"
                style={{
                    backgroundColor: "var(--color-secondary-dark)",
                    borderRadius: "10px 0 0 10px",
                    height: "30px",
                    padding: "0px",
                }}
                onClick={() => {
                    if (count != 1) {
                        setCount((prev) => prev - 1)
                    }
                }}
            >
                <RemoveIcon style={{ color: "white" }} />
            </button>
            <input style={{ width: "40px", height: "30px", textAlign: "center" }} type="text" id="amount" name="amount" value={count} readonly />
            <button
                className="value-button"
                id="inccrease"
                style={{
                    backgroundColor: "#236c9c",
                    borderRadius: "0px 10px 10px 0px",
                    height: "30px",
                }}
                onClick={() => {
                    setCount((prev) => {
                        return prev + 1;
                    })
                }}
            >

                <AddIcon style={{ color: "white" }} />
            </button>
        </div>
    );

}