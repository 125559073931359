
import React, { useState, useEffect } from "react";
import {
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Typography,
  TableFooter,
  TableBody
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../../shipment/shipment.css";
import "../RmsMargin";
import { useNavigate, useParams } from "react-router-dom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import { basename } from "../../../Config";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { AuthContext } from "../../../store/context/AuthProvider";
import instance from "../../../api/Interceptor";
import 'react-datepicker/dist/react-datepicker.css';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Skeleton from '@mui/material/Skeleton';

import {
  Select,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
} from "@mui/material";
import urlResolver from "../../../utils/urlResolver";
const RmsReportMargin = () => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
  };
  const lineStyle = {
    width: "130px",
    borderBottom: "3px solid var(--color-secondary-dark)", // Add a horizontal line
  };
  const headingStyle = {
    fontSize: "18px", // Adjust the font size as needed
  };
  const textStyle = {
    fontSize: "16px",
    color: "var(--color-secondary-dark)" // Adjust the font size as needed
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { inquiryNo } = useParams();
  const [data, setData] = useState([])
  const [bookingData, setBookingData] = useState([])
  const [QuoteData, setQuoteData] = useState([])
  const [ContainerData, setContainerData] = useState([])
  const [ContainerData2, setContainerData2] = useState([])
  const context = React.useContext(AuthContext);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const type = inquiryNo.charAt(0) === 'Q' // type true means quatation view
  const completeBreakUP = [];
  ContainerData?.forEach((singlecontainer) => {
    singlecontainer?.Rates?.forEach((rate) => {
      completeBreakUP.push({
        "quantity": singlecontainer.ContainerCount,
        "item": singlecontainer.ContainerISO,
        "description": rate.Name,
        "rate": rate.Rate,
      })
    })
  })
  let bookinginformation, bookingcontainerInformation;
  const getBookingDetails = () => {
    setLoading(true)
    const body = {
      "InquiryNo": inquiryNo,
      "CurrentPage": 0,
      "PageSize": 10,
      "SortColumnName": "",
      "SortDirection": ""
    }
    const url = ("api/v1/RateManagement/GetRateDetails");
    instance.post(url, body)
      .then(({ data }) => {
        setBookingData(data)
        bookinginformation = data;
        bookingcontainerInformation = data[0]?.RatingPacking
        setContainerData2(bookingcontainerInformation)
        console.log(bookingcontainerInformation, "getbookingdetails")
        const quatation = data[0]?.RatingHeader?.QuotationNumber;
        getQuotesDetails(quatation)
      }).catch((err) => {
        console.log(err)
      })
  }

  const getQuotesDetails = (QuotationNo) => {
    setLoading(true)
    let information;
    const body = {
      "QuotationNo": QuotationNo
    }
    const url = "api/v1/Quotation/GetQuote"
    instance.post(url, body)
      .then(({ data }) => {
        information = data;
        setData(data);
        const containerDisplayInformation = data[0]?.ContainerTypes
        if (type) setContainerData(data[0]?.ContainerTypes)
        else {
          let data1 = data[0]?.ContainerTypes
          data1.forEach((containerInformation) => {
            bookingcontainerInformation.forEach((element) => {
              if (element.ContainerType === containerInformation.ContainerISO) {
                containerInformation.ContainerCount = element.ContainerCount
              }
            })
          })
          setContainerData(data1)
        }
        setQuoteData(data)
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        let finalData;
        if (type) {
          finalData = { ...information[0]?.Quotation ?? {} }
        } else {
          finalData = { ...information[0]?.Quotation ?? {}, ...bookinginformation[0]?.RatingHeader ?? {} }
        }
        console.log(finalData, "finalData")
        setData(finalData)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (type) getQuotesDetails(inquiryNo)
    else getBookingDetails();

  }, [])

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevOpen) => !prevOpen);
  };
  return (
    <>
      <Grid item lg={12} md={6} xs={12} width={"100%"}>
        <Grid container spacing={1} >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
            className="p-4"
            sx={{ cursor: "pointer", marginBottom: "1rem" }}
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon />
            Back to Cargo details
          </Grid>
          <Grid item lg={4} md={6} xs={12} className="p-4">
            <div> <b className="head-text text-center" style={headingStyle}>Quotation Number :</b>{data.QuotationId}</div>
          </Grid>
          {type === false && (
            <Grid item lg={4} md={6} xs={12} className="p-4">
              <div><b className="head-text text-center" style={headingStyle}>Booking Number :</b>{inquiryNo}</div>
            </Grid>)}
        </Grid></Grid>
      <Grid container spacing={1}>
        {/* origin and destination information */}

        {/* card information */}
        <Grid item lg={12} md={12} xs={12} className="p-4">
          <Grid container spacing={1}>
            <Grid item lg={1} md={1} xs={2}>
              <p className="m-0"> Rate Details</p>
            </Grid>

            <Grid
              item
              lg={11}
              xs={10}
              className="d-flex ali dash-rms"
            >
              <Divider
                variant="center"
                flexItem
                sx={{
                  backgroundColor: " var(--color-secondary-light)",
                  width: "100%",
                  marginTop: "0.8rem",
                }}
              />
            </Grid>
            {
              loading
                ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      width={"100%"}
                    >
                      <Skeleton variant="rounded" height={"6em"} />
                    </Grid>
                  </>
                )
                : (
                  <>
                    <Grid container spacing={2} alignItems="center"
                      className="p-4" style={{
                        backgroundColor: "white",
                        boxShadow: " 0px 1px 10px rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        padding: "0rem",
                        position: "relative",
                        marginTop: "1rem",
                        marginLeft: "0px",
                        paddingBottom: "20px",
                      }}>
                      <Grid item lg={2} md={6} xs={12}>
                        <div><p className="mb-1  head-text text-center" style={headingStyle}>
                          Origin
                        </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle} >
                            <LocationOnOutlinedIcon color="primary" />
                            {data?.OriginName ?? "N/A"}
                          </p></div>
                      </Grid>
                      <Grid item lg={2} md={6} xs={12}>
                        <div> <p className="mb-1  head-text text-center" style={headingStyle}>
                          Transport Mode
                        </p>  <div style={containerStyle} className="d-flex align-items-center justify-content-between" >
                            {/* <HorizontalRuleOutlinedIcon color="primary" /> */}
                            <div style={lineStyle}></div>
                            {data?.TransportMode === "AIR" && (
                              <img
                                src ={urlResolver(`assets/Air.svg`)}
                                alt="AIR"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                            {data?.TransportMode === "COU" && (
                              <img
                                src ={urlResolver(`assets/courier.svg`)}
                                alt="COU"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                            {data?.TransportMode === "SEA" && (
                              <img
                               src ={urlResolver(`assets/ship.svg`)}
                                alt="SEA"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                            {data?.TransportMode === "RAO" && (
                              <img
                                src ={urlResolver(`assets/road.svg`)}
                                alt="RAO"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                            {data?.TransportMode === "RAIL" && (
                              <img
                               src ={urlResolver(`assets/rail.svg`)}
                                alt="RAIL"
                                style={{ width: "30px", height: "30px" }}
                              />
                            )}
                            {/* <HorizontalRuleOutlinedIcon color="primary"/> */}
                            <div style={lineStyle}></div>
                          </div></div>
                      </Grid>
                      <Grid item lg={2} md={6} xs={12}>
                        <div>
                          <p className="mb-1 head-text text-center" style={headingStyle}>
                            Destination
                          </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle}>
                            <LocationOnOutlinedIcon color="primary" />
                            {data?.DestinationName ?? "N/A"}
                          </p></div>
                      </Grid>
                      <Grid item lg={1} md={6} xs={12}>
                        <div><p className="mb-1 head-text text-center" style={headingStyle}>
                          Service Level
                        </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle}>
                            {data?.ServiceLevel?.length ? data?.ServiceLevel : "N/A"}
                          </p></div>
                      </Grid>
                      <Grid item lg={2} md={6} xs={12}>
                        <div><p className="mb-1 head-text text-center" style={headingStyle}>
                          Carrier Service Level
                        </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle}>
                          {data?.ServiceLevel?.length ? data?.ServiceLevel: "N/A"}
                          </p></div>
                      </Grid>
                      <Grid item lg={1} md={6}  xs={12}>
                        <div><p className="mb-1 head-text text-center" style={headingStyle}>
                          EST travel time
                        </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle}>
                            {data?.TransitTime?.length > 0 ? (`${data?.TransitTime} days`) : "N/A"}
                          </p></div>
                      </Grid>
                      <Grid item lg={1} md={6} xs={12}>
                        <div><p className="mb-1 head-text text-center" style={headingStyle}>
                          Carrier Name
                        </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle}>
                          {data?.CarrierName?.length ? data?.CarrierName: "N/A"}
                          </p></div>
                      </Grid>
                      <Grid item lg={1} md={6}  xs={12}>
                        <div><p className="mb-1 head-text text-center" style={headingStyle}>
                          Total Rate
                        </p>
                          <p className="mb-0 head-text-para text-center" style={textStyle}>
                            {data?.CurrencyCode} {completeBreakUP.reduce((accumulater, element) => {
                              return (accumulater += element.rate * element.quantity)
                            }, 0) ?? "N/A"}
                            <ExpandCircleDownOutlinedIcon
                              className={isAccordionOpen ? "chageicon180" : ""}
                              color="primary"
                              onClick={handleAccordionToggle}
                            /></p></div>
                      </Grid>

                      {/* Render the accordion content based on the open state */}
                      {isAccordionOpen && (
                        <div className="w-100 p-5">
                          <div className="table-responsive">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">SNo.</th>
                                  <th scope="col">Description</th>
                                  <th scope="col">Item</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Rate</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {completeBreakUP?.map((item, index) => (
                                  <tr key={item.id}>
                                    <td>{index + 1}</td>
                                    <td>{item.description ?? "N/A"}</td>
                                    <td>{item.item ?? "N/A"}</td>
                                    <td>{item.quantity ?? "N/A"}</td>
                                    <td>{item.rate ?? "N/A"}</td>
                                    <td>{item.quantity * item.rate ?? "N/A"}</td>
                                  </tr>
                                ))}
                                <tr>
                                  <th colSpan={5}>
                                    Grand Total:
                                  </th>
                                  <th>
                                    <Typography variant="h6" component="span">
                                      {data?.CurrencyCode} {completeBreakUP.reduce((accumulater, element) => {
                                        return (accumulater += element.rate * element.quantity)
                                      }, 0) ?? "N/A"}
                                    </Typography>
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan={1}>
                                    Remarks:
                                  </th>
                                  <th colSpan={5}>
                                    <div style={{ maxHeight: '87px', overflowY: 'auto' }}>
                                      <Typography variant="h6" component="span" style={{ fontSize: '18px' }}>
                                        {data?.Remarks ?? ""}
                                      </Typography></div>
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan={1}>
                                    commodity:
                                  </th>
                                  <th>
                                    <Typography variant="h6" component="span" style={{ fontSize: '18px' }}>
                                      {data?.CommodityDetails ?? ""}
                                    </Typography>
                                  </th>
                                </tr>
                                <tr>
                                  <th colSpan={1}>
                                    Weight:
                                  </th>
                                  <th>
                                    <Typography variant="h6" component="span" style={{ fontSize: '18px' }}>
                                      {`${data?.GrossWeight ?? ""} ${data?.WeightUnit ?? ""}`}
                                    </Typography>
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </Grid>

                  </>
                )
            }

          </Grid>
        </Grid>

        {/* Cargo Details */}
        {
          type === false && (<>
            <Grid item lg={12} md={12} xs={12} className="p-4">
              <Grid container spacing={1}>
                <Grid item lg={1} md={1} xs={2} >
                  <p className="m-0"> Booking Details</p>
                </Grid>
                <Grid
                  item
                  lg={11}
                  xs={10}
                  className="d-flex ali dash-rms"
                >
                  <Divider
                    variant="center"
                    flexItem
                    sx={{
                      backgroundColor: " var(--color-secondary-light)",
                      width: "100%",
                      marginTop: "0.8rem",
                    }}
                  />
                </Grid>
                {
                  loading
                    ? (
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: "1%" }}>
                        <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
                          <Skeleton variant="rounded" height={"15em"} />
                        </Grid>
                        <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                          <Skeleton variant="rounded" height={"15em"} />
                        </Grid>
                        <Grid item lg={3} md={12} sm={12}>
                          <Skeleton variant="rounded" height={"15em"} />
                        </Grid>
                      </Grid>
                    )
                    : (
                      <>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} style={{ marginTop: "1%" }}>
                          {/* --FIRST-- */}
                          <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
                            <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: "1rem" }}
                                className="details-section"
                              >
                                <TextField
                                  name="cargoType"
                                  label="CARGO TYPE"
                                  variant="outlined"
                                  value={data?.Carotype?.length ? data?.Carotype : "N/A"}
                                  disabled
                                />
                              </FormControl>
                              <br />
                              <FormControl
                                fullWidth
                                sx={{ paddingBottom: "1rem" }}
                                className="details-section"
                              >
                                <TextField
                                  name="incoType"
                                  label="INCO TERMS"
                                  variant="outlined"
                                  value={data?.Incoterm?.length ? data?.Incoterm : "N/A"}
                                  disabled
                                />
                              </FormControl>
                              <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                <TextField
                                  name="shipperNo"
                                  label="SHIPPER INVOICE NO."
                                  variant="outlined"
                                  value={data?.ShipperInvoiceNumber?.length ? data?.ShipperInvoiceNumber : "N/A"}
                                  disabled
                                />
                              </FormControl>
                              <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                <TextField
                                  name="goodValue"
                                  label="GOOD VALUE"
                                  variant="outlined"
                                  value={data?.Goodsvalue?.length ? data?.Goodsvalue : "N/A"}
                                  disabled
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                          {/* --MID-- */}
                          <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                            <Paper elevation={4} sx={{ padding: "24px", height: "100%", width: "100%" }}>
                              <Grid
                                container
                                rowSpacing={1}
                                columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                                  {/* <Typography className="pb-3">
                                <LocationOnIcon color="primary" />
                                <span className="card-rms">Origin</span>
                              </Typography> */}
                                  <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                    <TextField
                                      label="ORIGIN PIN/ZIP CODE"
                                      variant="outlined"
                                      name="originPin"
                                      value={data?.OriginPinCode?.length ? data?.OriginPinCode : "N/A"}
                                      disabled

                                    />
                                  </FormControl>
                                  <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                    <TextField
                                      name="datepicker"
                                      label="CARGO TYPE DATE"
                                      variant="outlined"
                                      value={data?.CargoReadyDate?.length ? data?.CargoReadyDate : "N/A"}
                                      disabled
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                                  {/* <Typography className="pb-3">
                                <LocationOnIcon color="primary" />
                                <span className="card-rms">Destination</span>
                              </Typography> */}
                                  <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                    <TextField
                                      label="DESTINATION PIN/ZIP CODE"
                                      variant="outlined"
                                      name="destPin"
                                      value={data?.DestinationPinCode?.length ? data?.DestinationPinCode : "N/A"}
                                      disabled
                                    />
                                  </FormControl>
                                  <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                    <TextField
                                      name="additionalServices"
                                      value={data?.AdditionalServices?.length ? data?.AdditionalServices : "N/A"}
                                      label="ADDITIONAL SERVICES"
                                      variant="outlined"
                                      disabled
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid item lg={12} md={12} sm={12} sx={{ width: "100%" }}>
                                <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                  <TextField
                                    name="goodDesc"
                                    value={data?.GoodsDescription?.length ? data?.GoodsDescription : "N/A"}
                                    disabled
                                    label="GOOD DESCRIPTION."
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                  />
                                </FormControl></Grid>
                            </Paper>
                          </Grid>
                          {/* --LAST-- */}
                          <Grid item lg={3} md={12} sm={12}>
                            <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
                              <Typography className="pb-3">
                                {/* <Switch
                              defaultChecked={false}
                              size="small"
                            /> */}
                                <span>Hazardous goods</span>
                              </Typography>
                              <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                <TextField
                                  name="imoClass"
                                  value={data?.IMOClass?.length ? data?.IMOClass : "N/A"}
                                  label="IMO CLASS"
                                  variant="outlined"
                                  disabled
                                />
                              </FormControl>
                              <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                <TextField
                                  name="hsCode"
                                  value={data?.HSCode?.length ? data?.HSCode : "N/A"}
                                  label="HS CODE"
                                  variant="outlined"
                                  disabled
                                // disabled={!hazardous || !ishazardous}
                                />
                              </FormControl>
                              <FormControl fullWidth sx={{ paddingBottom: "1rem" }} className="details-section">
                                <TextField
                                  name="unNumber"
                                  value={data?.UnNumber?.length ? data?.UnNumber : "N/A"}
                                  label="UN NUMBER"
                                  variant="outlined"
                                  disabled
                                // disabled={!hazardous || !ishazardous}
                                />
                              </FormControl>
                            </Paper>
                          </Grid>
                          {console.log("dataa", data)}
                          {/* --BOTTOM-- */}

                        </Grid>
                      </>
                    )
                }
              </Grid></Grid>
          </>
          )
        }

        <Grid container className="p-4 pt-0">
          <Grid item lg={1} md={1} xs={2} >
            <p className="m-0"> Package Details</p>
          </Grid>
          <Grid
            item
            lg={11}
            xs={11}
            className="d-flex align-items-center dash-rms"
          >
            <Divider
              variant="left"
              flexItem
              sx={{
                backgroundColor: " var(--color-secondary-light)",
                width: "100%",
                marginTop: "0.8rem",
              }}
            />
          </Grid></Grid>

        {/* table information in other cases */}
        <Grid container className="p-4 pt-0">
          {["LSE", "CON", "BCN", "LCL", "BLK", "LQD", "BBK", "OBC", "UNA"].includes(savedTransportData) ? (
            <>
              <Grid item lg={12}>
                <Paper>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2" style={headingStyle}>Packaging Type</p>
                          </TableCell>
                          <TableCell className="tablecell" >
                            <p className="head-text text-center pt-2" style={headingStyle}>Quantity</p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2" style={headingStyle}>Volume Unit</p>
                          </TableCell>
                          <TableCell className="tablecell" >
                            <p className="head-text text-center pt-2" style={headingStyle}>Volume</p>
                          </TableCell>
                          <TableCell className="tablecell">
                            <p className="head-text text-center pt-2" style={headingStyle}> Weight Unit</p>
                          </TableCell>
                          <TableCell className="tablecell" >
                            <p className="head-text text-center pt-2" style={headingStyle}>Weight</p>
                          </TableCell>
                          <TableCell className="tablecell" >
                            <p className="head-text text-center pt-2" style={headingStyle}>Stakable</p>
                          </TableCell>
                        </TableRow>
                        <TableRow >

                          <TableCell className="table-data" align="center">
                            <Select
                              size="small"
                              sx={{ padding: "0" }}
                              className="MuiSelect-outlined"

                              name="PackagingType"
                              value={data?.PackagingType ?? 'N/A'}
                              variant="outlined"

                            >
                              <MenuItem value="Select" disabled>
                                Select
                              </MenuItem>
                              <MenuItem value="Pallets">Pallets</MenuItem>
                              <MenuItem value="Skids">Skids</MenuItem>
                              <MenuItem value="Crates">Crates</MenuItem>
                              <MenuItem value="boxes">boxes</MenuItem>
                              <MenuItem value="Cartons">Cartons</MenuItem>
                              <MenuItem value="cases">cases</MenuItem>
                              <MenuItem value="Packages">Packages</MenuItem>
                              <MenuItem value="Drums">Drums</MenuItem>
                              <MenuItem value="Bags">Bags</MenuItem>
                              <MenuItem value="Rolls">Rolls</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <TextField
                              type="number"
                              name="Quantity"
                              value={data?.Quantity ?? 'N/A'}
                              variant="outlined"
                              size="small"
                              className="custom-textfield"

                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Select
                              sx={{ padding: "0" }}
                              size="small"
                              className="MuiSelect-outlined"
                              type="text"
                              name="VolumeUnit"
                              value={data?.VolumeUnit ?? 'N/A'}
                              variant="outlined"

                            >
                              <MenuItem value="Select" disabled>
                                Select
                              </MenuItem>
                              <MenuItem value="m3">m3</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <TextField
                              type="number"
                              name="Volume"
                              value={data?.Volume ?? 'N/A'}
                              variant="outlined"
                              size="small"
                              className="custom-textfield"
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Select
                              sx={{ padding: "0" }}
                              size="small"
                              className="MuiSelect-outlined"
                              type="text"
                              name="WeightUnit"
                              value={data?.WeightUnit ?? 'N/A'}
                              variant="outlined"

                            >
                              <MenuItem value="Select" disabled>
                                Select
                              </MenuItem>
                              <MenuItem value="Kg">Kg</MenuItem>
                              <MenuItem value="Ton">Ton</MenuItem>
                              <MenuItem value="LB">LB</MenuItem>
                            </Select>
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <TextField
                              type="number"
                              name="Weight"
                              value={data.Weight}
                              variant="outlined"
                              size="small"
                              className="custom-textfield"
                            />
                          </TableCell>
                          <TableCell className="table-data" align="center">
                            <Switch
                              size="small"
                              checked={data?.Stakable ?? 'N/A'}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </Paper>
              </Grid>
            </>
          ) : (<> <Grid item lg={6} md={6} xs={12}
            style={{
              backgroundColor: "white",
              boxShadow: " 0px 1px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "8px",
              padding: "0rem",
              position: "relative",
              marginTop: "-1rem",
              marginLeft: "0px",
              paddingBottom: "20px",
              width: "100%"
            }}>
            {/* <Paper> */}
            {
                type && (<TableContainer >
                  <Table>
                    <TableHead >
                      <TableRow className="form-control">
                        <TableCell className="tablecell">
                          <p className="head-text text-center pt-2" style={headingStyle}>Quantity</p>
                        </TableCell>
                        <TableCell className="tablecell" align="center" style={headingStyle}>
                          <p className="head-text text-center pt-2" style={headingStyle}>Container Type</p>
                        </TableCell>
                        {
                          !type && (
                            <TableCell className="tablecell" align="center" style={headingStyle}>
                              <p className="head-text text-center pt-2" style={headingStyle}>Weight</p>
                            </TableCell>
                          )
                        }
                      </TableRow>
                      {
                        loading
                          ? (
                            <TableRow>
                              <TableCell className="table-data" align="center">
                                <Skeleton variant="rounded" width={"25em"} height={"5em"} />
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                <Skeleton variant="rounded" width={"25em"} height={"5em"} />
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                <Skeleton variant="rounded" width={"25em"} height={"5em"} />
                              </TableCell>
                            </TableRow>
                          )
                          : (
                            <>
                              {
                                ContainerData?.length && ContainerData?.map((singlecontainer, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell >
                                        <p className="m-0 package-section">{singlecontainer?.ContainerCount ?? 'N/A'}</p>
                                      </TableCell>
                                      <TableCell >
                                        <p className="m-0 package-section">{singlecontainer?.ContainerType ?? singlecontainer?.ContainerISO ?? "N/A"}</p>
                                      </TableCell>
                                      {
                                        !type && (
                                          <TableCell >
                                            {/* <TextField
                                            type="text"
                                            name="containerType"
                                            value={singlecontainer?.ContainerISO ?? 'N/A'}
                                            variant="outlined"
                                            size="small"
                                            className="form-control"
                                          /> */}
                                            <p className="m-0 package-section">{singlecontainer?.weight ?? 'N/A'} {singlecontainer?.weightUnit ?? 'N/A'}</p>
                                          </TableCell>
                                        )
                                      }
    
                                    </TableRow>
                                  )
                                })
                              }
                            </>
                          )
                      }
                    </TableHead>
                  </Table>
                </TableContainer>)
            }
            
            {
                !type && (<TableContainer >
                  <Table>
                    <TableHead >
                      <TableRow className="form-control">
                        <TableCell className="tablecell">
                          <p className="head-text text-center pt-2" style={headingStyle}>Quantity</p>
                        </TableCell>
                        <TableCell className="tablecell" align="center" style={headingStyle}>
                          <p className="head-text text-center pt-2" style={headingStyle}>Container Type</p>
                        </TableCell>
                        {
                          !type && (
                            <TableCell className="tablecell" align="center" style={headingStyle}>
                              <p className="head-text text-center pt-2" style={headingStyle}>Weight</p>
                            </TableCell>
                          )
                        }
                      </TableRow>
                      {
                        loading
                          ? (
                            <TableRow>
                              <TableCell className="table-data" align="center">
                                <Skeleton variant="rounded" width={"25em"} height={"5em"} />
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                <Skeleton variant="rounded" width={"25em"} height={"5em"} />
                              </TableCell>
                              <TableCell className="table-data" align="center">
                                <Skeleton variant="rounded" width={"25em"} height={"5em"} />
                              </TableCell>
                            </TableRow>
                          )
                          : (
                            <>
                              {
                                ContainerData2?.length && ContainerData2?.map((singlecontainer, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell >
                                        <p className="m-0 package-section">{singlecontainer?.ContainerCount ?? 'N/A'}</p>
                                      </TableCell>
                                      <TableCell >
                                        <p className="m-0 package-section">{singlecontainer?.ContainerType ?? singlecontainer?.ContainerISO ?? "N/A"}</p>
                                      </TableCell>
                                      {
                                        !type && (
                                          <TableCell >
                                            {/* <TextField
                                            type="text"
                                            name="containerType"
                                            value={singlecontainer?.ContainerISO ?? 'N/A'}
                                            variant="outlined"
                                            size="small"
                                            className="form-control"
                                          /> */}
                                            <p className="m-0 package-section">{singlecontainer?.weight ?? 'N/A'} {singlecontainer?.weightUnit ?? 'N/A'}</p>
                                          </TableCell>
                                        )
                                      }
    
                                    </TableRow>
                                  )
                                })
                              }
                            </>
                          )
                      }
                    </TableHead>
                  </Table>
                </TableContainer>)
            }
            {/* </Paper> */}
          </Grid>
          </>
          )}</Grid>

      </Grid>
    </>
  );
}

export default RmsReportMargin;