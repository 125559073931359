import React, { useEffect, useRef, useState } from 'react';
import TablerowMargin from './TablerowMargin';
import {
 Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
 
} from "@mui/material";
import Paper from "@mui/material/Paper";
import "../rmsMargin.css";
import instance from "../../../api/Interceptor";
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
 
const CreateRmsListMargin = (props) => {
  const navigate = useNavigate();
  // const location = useLocation()
  const { containerDataInformation, QuotationData, handleRmsDataChangeprevious, FinalInformation, initialLoading } = props;
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const [loading, setLoading] = useState(false);
  const [containerInformation, setContainerInformation] = useState([])
  const previousCount = useRef(0)
  const [rows, setRows] = useState(
    {
      id: 0,
      PackagingType: "",
      Quantity: '',
      VolumeUnit: "",
      Volume: '',
      WeightUnit: "",
      Weight: '',
     containerQuant: '',
      containerType: "",
      Stakable: false,
      disableDelete: true
  },
  );
  const handleAddRow = () => {
    console.log("lenght",containerInformation?.length);
    const newRow = {
     id:  containerInformation?.length,
      PackagingType: "Select",
      Quantity: '',
      VolumeUnit: "Select",
      Volume: '',
      WeightUnit: "",
      Weight: "",
      containerQuant: '',
      containerType: "",
      Stakable: false, // Default value for Stakable field
      disableDelete: false, // Enable delete icon for the new row
    };
    // const updatedRows = rows.map((row) => ({

   //   ...row,
    //   disableDelete: false, // Enable delete icon for the default row
    // }));
    // setRows([newRow]);
    setContainerInformation((prev) => [...prev, newRow])
  };
  // console.log("newrowdata", containerInformation)
  const handleDeleteRow = (id) => {
    // if (id === 0) {
    //   return;
    // }
    // const updatedRows = rows.filter((row) => row.id !== id);
   containerInformation.splice(id,1)
    // setRows(updatedRows);
    setContainerInformation([...containerInformation])

 };
 
  const handleRowChange = (id, field, value) => {
   const updatedRows = rows.map((row) => {
      if (row.id === id) {
      return { ...row, [field]: value };
      }
      return row;
    });
    setRows(updatedRows);
  }
  const handleRmsDataChange = () => {
   const conformation = handleRmsDataChangeprevious();
    if (conformation) {
     setLoading(true);
    // let invalidRows = []
    // let invalidRows2 = []
      // if (["LSE", "CON", "BCN", "LCL", "BLK", "LQD", "BBK", "OBC", "UNA"].includes(savedTransportData)) {
      //   invalidRows = rows.filter((row) =>
      //     row.PackagingType === "Select" ||
      //     row.VolumeUnit === "Select" ||
      //     row.WeightUnit === "Select" ||
      //     row.Quantity === "" ||
      //     row.Volume === "" ||
      //     row.Weight === ""
      //   );
      // }
      // else {
      //   invalidRows2 = rows.filter((row) =>
      //     row.containerQuant === "" ||
      //     row.containerType === "Select"
      //   );
      // }
     // if (invalidRows.length > 0) {
     //   alert("Please select package type,Quantity, volume unit,volume,weight unit and weight for each row");
     // }
     // else if (invalidRows2.length > 0) {
      //   alert("Please select container quantity and container type for each row");
      // }
      // else {
      let packagerating = []
      // let rates = []
      // const ratingPackages = rows.map((row) => {
      containerInformation.map((container, index) => {
        let package_temp = {
          PakageTypeCode: "",
          PakageTypeName: "",
          FK_RH_InquiryNo: "",
          PackageQuantity: 0,
          Volume: 0,
          VolumeUnit: "",
          weight: container?.Weight ?? 0,
          weightUnit: container?.WeightUnit ?? "",
          IsStackable: false,
          ContainerType: container?.ContainerISO ?? "",
          ContainerCount: container?.ContainerCount ?? 1
        }
        packagerating.push(package_temp);
        //  container.rates.map((rate,index)=>{
        // let rates_temp ={
        // FK_RH_InquiryNo: "",
        // ChargeCode: container.SystemChargeCode,
        // Chargedescription: container.Name,
        // CurrencyCode:container.Currency,
        // CurrencyDescription: "",
        // OriginUnlocoCode: context.rmsData.OriginPort,
        // DestinationUnlocoCode: context.rmsData.destinationport,
       // Rates:container.Rate
        // }
        // rates.push(rates_temp);
        //  })
     })
     //   return {
    //     PakageTypeCode: "",
      //     PakageTypeName: row.PackagingType,
      //     FK_RH_InquiryNo: context.rmsinquiry,
      //     PackageQuantity: row.Quantity,
      //     Volume: row.Volume,
      //     VolumeUnit: row.VolumeUnit,
     //     weight: row.Weight,
     //     weightUnit: row.WeightUnit,
     //     IsStackable: row.Stakable,
     //     ContainerType: "",
     //     ContainerCount: ""
      //   };
      // });
      const body = {
        "RateHeader": {
         "QuotationNo": QuotationData?.QuotationId,
        "InquiryNo": "",
        "CWBookingNumber": "",
         "Transportmode": QuotationData?.TransportMode,
          "Containertype": QuotationData?.ContainerType,
          "MovementType": FinalInformation.current.MovementType,
          "Incoterm": FinalInformation.current.Incoterm,
          "Carotype": FinalInformation.current.Carotype,
          "IMOClass": FinalInformation.current.IMOClass,
          "HSCode": FinalInformation.current.HSCode,
          "UnNumber": FinalInformation.current.UnNumber,
          "OriginPort": QuotationData?.OriginCode,
          "destinationport": QuotationData?.DestinationCode,
          "Originpin": FinalInformation.current.Originpin,
          "Destpin": FinalInformation.current.Destpin,
          "POReceipt": "",
          "FinalDeliveryUnlocoCode": "",
          "ShipperInvoiceNumber": FinalInformation.current.ShipperInvoiceNumber,
          "Goodsvalue": FinalInformation.current.Goodsvalue,
          "Cargoreadydate": FinalInformation.current.Cargoreadydate,
          "AdditionalServices": FinalInformation.current.AdditionalServices,
          "Goodsdescription": FinalInformation.current.Goodsdescription,
          "StatusCode": FinalInformation.current.StatusCode,
          "StatusDescription": FinalInformation.current.StatusDescription,
          "JobType": "",
          "CreatedUserName": "",
         "ConsignorCode": "",
          "ConsignorName": "",
          "ConsigneeCode": "",
          "ConsigneeName": "",
          "NotifypartyCode": "",
          "NotifypartyName": "",
          "PaymentTerm": "",
          "ClientNotes": "",
          "CarrierCode": FinalInformation.current.CarrierName,
          "CarrierName": FinalInformation.current.CarrierName,
          // FinalInformation.current.CarrierCode
          "validityDate": FinalInformation.current.validityDate,
          // "CommodityDetails" : ,
          // "WeightUnit" : ,
          // "GrossWeight" :,
        },
       // "RatingCharges": rates,
       "RatingPackages": packagerating
     };
     instance
        .post("api/v1/RateManagement/CreateUpdateRateAndUpdateBookingStatus", body)
       .then((res) => {
          if (res?.data.length !== 0 && res?.data[0]?.hasOwnProperty("CWBookingNumber") && res?.data[0]?.CWBookingNumber !== null) {
           Swal.fire({
              icon: 'success',
              html: `Booking <span style="color: green">${res?.data[0]?.InquiryNumber}</span> Generated Successfully and Approved`,
             confirmButtonColor: 'var(--color-secondary-dark)',
             allowOutsideClick: false, // Prevents closing on outside click
          }).then((result) => {
             if (result.isConfirmed) {
               navigate(Routeconstant.RMS);
              }
            });
          }
          else if (res?.data.length !== 0 && res?.data[0]?.hasOwnProperty("CWBookingNumber") && res?.data[0]?.CWBookingNumber === null) {
            Swal.fire({
              icon: 'success',
             html: `Booking <span style="color: green">${res?.data[0]?.InquiryNumber}</span> Generated Successfully. <br><span style="font-size:smaller"> Kindly Approve it manually.</span>`,
              allowOutsideClick: false, // Prevents closing on outside click
              confirmButtonColor: 'var(--color-secondary-dark)',
           }).then((result) => {
              if (result.isConfirmed) {
                navigate(Routeconstant.RMS);
              }
            });
          }
          else if (res?.data.length !== 0 && !res?.data[0]?.hasOwnProperty("CWBookingNumber")) {
            Swal.fire({
              icon: 'success',
           html: `Booking <span style="color: green">${res?.data[0]?.InquiryNumber}</span> Generated Successfully.<br><span style="font-size:smaller"> Kindly Approve it manually.</span>`,
           allowOutsideClick: false, // Prevents closing on outside click
              confirmButtonColor: 'var(--color-secondary-dark)',
           }).then((result) => {
           if (result.isConfirmed) {
              navigate(Routeconstant.RMS);
              }
            });
          }
          else {
            Swal.fire({
              icon: 'error',
              html: `Booking Failed to generate`,
              allowOutsideClick: false, // Prevents closing on outside click
              confirmButtonColor: 'var(--color-secondary-dark)',
            }).then((result) => {
              if (result.isConfirmed) {
              navigate(Routeconstant.RMS);
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        })

   }
  };
  // useEffect(() => {
  //   if (context?.QuotationID?.length === 0) {
  //     navigate('/rms')
  //   }
  // }, [])
  useEffect(() => {
    const newcontainer = containerDataInformation?.map((data)=>{
      return {...data , WeightUnit: "", Weight: ''}
    })
    setContainerInformation(newcontainer)
    previousCount.current = containerDataInformation?.length
  }, [props.containerDataInformation])
  console.log(previousCount.current, "previousCount")
  return (
    <>
      {
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <Grid container spacing={1}>
        <Grid
          item
         xs={12}
          sx={{ cursor: "pointer", marginBottom: "1rem" }}
        // onClick={() => navigate(`/createrms/${context.QuotationID}`, { state: item })}
       >
         {/* <KeyboardBackspaceIcon />
         Back */}
        </Grid>
        <Paper elevation={4} sx={{ padding: "24px", height: "100%", width: "100%" }}>
          {
            initialLoading
             ? (
                <>
                  <Skeleton variant="rounded" width={"100%"} height={"4em"} />
                </>
              )
              : (
                <>
                 <Table>
                   {["LSE", "CON", "BCN", "LCL", "BLK", "LQD", "BBK", "OBC", "UNA"].includes(savedTransportData) ? (
                     <>
                       <Grid item xs={12} sx={{ width: "100%" }}>
                         <TableContainer>
                            <Table>
                              <TableHead>
                               <TableRow>
                                 <TableCell span="1" className="tablecell" align="center">
                                   Action
                                 </TableCell>
                               <TableCell span="1" className="tablecell" align="center">
                                 Packaging Type
                                </TableCell>
                                  <TableCell span="1" className="tablecell" align="center">
                                    Quantity
                                 </TableCell>
                                 <TableCell span="1" className="tablecell" align="center">
                                    Volume Unit
                                 </TableCell>
                                  <TableCell span="1" className="tablecell" align="center">
                                    Volume
                                  </TableCell>
                                 <TableCell span="1" className="tablecell" align="center">
                                    Weight Unit
                                  </TableCell>
                                  <TableCell span="1" className="tablecell" align="center">
                                   Weight
                                  </TableCell>
                                  <TableCell span="1" className="tablecell" align="center">
                                   Stakable
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </>
                    ) : (
                      <>
                          <Grid item lg={12} md={12} xs={10}>
                          <div >
                            <TableContainer style={{
                              maxWidth:"90vw",
                              overflow:"auto"
                            }}>
                              <Table>
                                <TableRow>
                                  <TableCell style={{paddingLeft:'30px'}}> Action</TableCell>
                                  <TableCell style={{paddingLeft:'60px'}}>Quantity</TableCell>
                                  <TableCell style={{paddingLeft:'60px'}}>Container Type</TableCell>
                                  <TableCell style={{paddingLeft:'80px'}}>Weight</TableCell>
                                  <TableCell style={{paddingLeft:'80px'}}> Unit</TableCell>
                                </TableRow>
                              </Table>
                            </TableContainer></div>

                          </Grid>
                          {/* <Grid item lg={6} md={6} xs={12} sx={{ width: "100%" }}>
                            <TableContainer >
                              <Table  >
                                <TableHead>
                                  <TableRow>
                                    <TableCell span="1"  >
                                      Action
                                    </TableCell>
                                    <TableCell span="1"  >
                                      Quantity
                                    </TableCell>
                                    <TableCell span="1"  >
                                      container Type
                                    </TableCell>
                                    <TableCell span="1"  >
                                      Weight
                                    </TableCell>
                                    
                                  </TableRow>
                                </TableHead>
                              </Table>
                            </TableContainer>
                          </Grid> */}
                        </>
                    )}
                    <TableBody>
                      {
                        containerInformation?.length > 0 &&
                        containerInformation?.map((information, index) => {
                          return (
                            <TablerowMargin
                              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                              className="table-row-data"
                              key={index}
                              indexvalue={index}
                              data={rows}
                              containerInformation={containerInformation}
                              prevcount={previousCount.current}
                              containerData={information}
                              onDelete={handleDeleteRow}
                              onChange={handleRowChange}
                              setContainerInformation={setContainerInformation}
                            />
                          )
                        })
                      }
                    </TableBody>
                    <Grid>
                      <Grid item xs={12} className="pb-3">
                        <Button
                          endIcon={<AddOutlinedIcon />}
                          sx={{ textTransform: "capitalize" }}
                          onClick={handleAddRow}
                        >
                          Add New
                        </Button>
                     </Grid>
                     <Grid item xs={12}>
                      </Grid>
                    </Grid>
                  </Table>
               </>
             )
         }
        </Paper>
      </Grid>
     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "1em" }}>
        {
          initialLoading
            ? (
              <>
                <Skeleton variant="rounded" width={"9em"} height={"3em"} style={{ borderRadius: "24px" }} />
              </>
            )
            : (
              <>
                <Button
                  variant="contained"
                  sx={{ borderRadius: "24px", textTransform: "capitalize", height: "3em", width: "12em" }}
                 style={{ fontSize: '1rem' }}
                  onClick={() => {
                    handleRmsDataChange();
                  }}
                >
                  Submit
               </Button>
              </>
           )
       }
     </div>
    </>
  );

};
export default CreateRmsListMargin;