import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import instance from "../../../api/Interceptor";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { AuthContext } from "../../../store/context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import HeaderButtonMargin from "../HeaderButtonMargin";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../rmsMargin.css";

const CreateRatesMargin = () => {
  const context = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [country, setCountry] = useState([]);
  const [countryPort, setCountryPort] = useState([]);
  const [countryDestPort, setCountryDestPort] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOriginCountry, setSelectedOriginCountry] = useState(null);
  const [selectedOriginPort, setSelectedOriginPort] = useState(null);
  const [selectedDestCountry, setSelectedDestCountry] = useState(null);
  const [selectedDestPort, setSelectedDestPort] = useState(null);
  const [orginationName, setOrginationName] = useState([]);
  const [weight, setweight] = useState(null);
  const [weightUnit, setWeigthUnit] = useState(null);
  const [commodity, setcommodity] = useState(null);
  const [originCountryTouched, setOriginCountryTouched] = useState(false);
  const [formOriginData, setFormOriginData] = useState({
    originCountry: null,
    originCountryName: null,
    originPortName: null,
    OriginPort: null,
    destCountry: null,
    destPortName: null,
    destPort: null,
    destCountryName: null,
  });
  const [transport, setTransport] = useState([]);
  const [formdropData, setFormdropData] = useState({
    transportType: "",
    orgizationName: "",
  });
  const [rmsFormData, setRmsFormData] = useState({
    originCountryName: "",
    OriginPort: "",
    destCountryName: "",
    destinationport: "",
    originPortName: "",
    destPortName: "",
    orgizationName: "",
  });
  const [validationErrors, setValidationErrors] = useState({
    transportType: "",
    originCountry: "",
    OriginPort: "",
    destCountry: "",
    destPort: "",
    orgizationName: "",
  });

  const getcountry = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCountries")
      .then((res) => {
        setCountry(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcountryPort = () => {
    setLoading(true);
    const body = {
      CountryCode: formOriginData.originCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        const nameAndCode = res.data.map((obj) => {
          return { title: `${obj.Name} (${obj.Code})` }
        })
        setCountryPort(nameAndCode ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getdestcountryPort = () => {
    setLoading(true);
    const body = {
      CountryCode: formOriginData.destCountry,
    };
    instance
      .post("api/v1/master/GetUnlocoInfo", body)
      .then((res) => {
        const nameAndCode = res.data.map((obj) => {
          return { title: `${obj.Name} (${obj.Code})` }
        })
        setCountryDestPort(nameAndCode ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleRmsDataChange = () => {
    setValidationErrors({
      transportType: "",
      originCountry: "",
      OriginPort: "",
      destCountry: "",
      destPort: "",
      orgizationName: "",
    });

    let errors = {};
    if (formdropData.transportType.trim() === "") {
      errors.transportType = "Please provide Transport Type.";
    }
    if (formdropData.orgizationName.trim() === "") {
      errors.orgizationName = "Please provide Orgization Name .";
    }
    if (
      formOriginData.originCountry === null ||
      formOriginData.originCountry === "" || formOriginData.originCountry === undefined
    ) {
      errors.originCountry = "Please provide Country Name.";
    }
    console.log(formOriginData, "formOriginData.OriginPort");
    if (
      formOriginData.OriginPort === null ||
      formOriginData.OriginPort === "" || formOriginData.OriginPort === undefined
    ) {
      errors.OriginPort = "Please provide Country Port.";
    }
    if (
      formOriginData.destCountry === null ||
      formOriginData.destCountry === "" || formOriginData.destCountry === undefined
    ) {
      errors.destCountry = "Please provide Country Name.";
    }
    if (formOriginData.destPort === null || formOriginData.destPort === ""
      || formOriginData.destPort === undefined) {
      errors.destPort = "Please provide Country Port.";
    }
    if (Object.keys(errors).length > 0) {
      // Display validation errors
      setValidationErrors(errors);
      return;
    }
    let selectedTransport = transport.find(
      (trans) => trans.Code === formdropData.transportType
    );
    let temp = {
      originCountryName: formOriginData.originCountryName,
      OriginPort: formOriginData.OriginPort,
      destCountryName: formOriginData.destCountryName,
      destinationport: formOriginData.destPort,
      originPortName: formOriginData.originPortName,
      destPortName: formOriginData.destPortName,
      Containertype: formdropData.transportType,
      transportName: selectedTransport ? selectedTransport.Description : "",
      orgizationName: formdropData.orgizationName,
      ClientCode: orginationName,
      weight: weight,
      weightUnit: weightUnit,
      commodity: commodity,
    };
    setRmsFormData(temp);
    context.setRmsData(temp);
    navigate(Routeconstant.CARGORATES);
  };
  const getOrgnizationName = () => {
    const body = {
      OrgCode: "",
      EmailId: "",
      ContactName: "",
      SortColumnName: "",
      SortDirection: "",
      CurrentPage: 0,
      PageSize: 0,
    };
    instance
      .post("UserAPI/api/user/GetAllOrgBasedOnEmailId", body)
      .then((res) => {
        setOrginationName(res?.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTransporttype = () => {
    setLoading(true);
    const body = {
      TransportMode: context.buttonActive.Code,
    };
    instance
      .post("api/v1/master/GetContainerType", body)
      .then((res) => {
        setTransport(res.data ?? []);
        // Check if context.buttonActive.Code is "SEA" and set the default transport type to "FCL" if not already selected
        if (context.buttonActive.Code === "SEA") {
          setFormdropData((prevFormData) => ({
            ...prevFormData,
            transportType: "FCL",
          }));
        } else {
          setFormdropData((prevFormData) => ({
            ...prevFormData,
            transportType: "",
          }));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.title,
  });
  const filterOptionsForCountryName = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.CountryName,
  });

  useEffect(() => {
    getcountryPort();
    setSelectedOriginCountry(
      country.find((c) => c.CountryCode === formOriginData.originCountry) ||
      null
    );
    setSelectedOriginPort(
      country.find((c) => c.CountryCode === formOriginData.OriginPort) || null
    );
    console.log("setSelectedOriginPort", selectedOriginPort);
  }, [formOriginData.originCountry, formOriginData.originCountryName]);

  useEffect(() => {
    getdestcountryPort();
    setSelectedDestPort(
      country.find((c) => c.CountryCode === formOriginData.destPort) || null
    );
  }, [formOriginData.destCountry]);
  useEffect(() => {
    getcountry();
    getOrgnizationName();
  }, []);

  useEffect(() => {
    getTransporttype();
  }, [context.buttonActive]);

  const handleDropdownChange = (event) => {
    const { name, value } = event.target;
    setFormdropData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    // Clear the error message for "orgizationName"
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      orgizationName: "",
    }));
  };
  useEffect(() => {
    if (orginationName.length == 1) {
      setFormdropData({
        ...formdropData,
        orgizationName: orginationName[0].OrgCode,
      });
    }
  }, [orginationName]);
  return (
    <Grid container gap={"10px"}>
      <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
        <span style={{ cursor: "pointer" }} onClick={() => navigate("/rms")}>
          <KeyboardBackspaceIcon />
          Back
        </span>
      </Grid>

      <HeaderButtonMargin />

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* --details gird-- */}
        <Grid item lg={4} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            <Typography className="pb-3">
              <span className="card-rms">Details</span>
            </Typography>
            {/* transport type */}
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: " var(--color-mile)" }}
              >
                TRANSPORT TYPE
              </InputLabel>
              <Select
                name="transportType"
                value={formdropData.transportType}
                onChange={handleDropdownChange}
                fullWidth
                className="MuiSelect-outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                default={20}
                label="TRANSPORT TYPE"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                variant="outlined"
              >
                {/* <MenuItem value={0} disabled>
                  Select
                </MenuItem> */}
                {transport?.map((trans, index) => {
                  const value = `${trans.Description} (${trans.Code})`;
                  return (
                    <MenuItem value={trans.Code}>{trans.Description}</MenuItem>
                  );
                })}
              </Select>
              {validationErrors.transportType && (
                <div className="error">{validationErrors.transportType}</div>
              )}
            </FormControl>
            {/* orginazation name */}
            {/* {
                 (customerName === "SAR Transport" && role === "Sales") || customerName === "SFLInsight"  && ( */}
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <InputLabel
                id="demo-simple-select-label"
              // style={{ color: " var(--color-mile)" }}
              >
                ORGANIZATION NAME <span style={{ color: "var(--color-miscellaneous-7)" }}>*</span>
              </InputLabel>
              <Select
                name="orgizationName"
                value={formdropData.orgizationName}
                onChange={handleDropdownChange}
                fullWidth
                className="MuiSelect-outlined"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                default={20}
                label="ORGANIZATION NAME"
                IconComponent={KeyboardArrowDownOutlinedIcon}
                variant="outlined"
              >
                {/* <MenuItem value={0} disabled>
                  Select
                </MenuItem> */}
                {orginationName?.map((obj, index) => {
                  return (
                    <MenuItem value={obj.OrgCode} key={index}>
                      {obj.OrgFullName}
                    </MenuItem>
                  );
                })}
              </Select>
              {validationErrors.orgizationName && (
                <div className="error">{validationErrors.orgizationName}</div>
              )}
            </FormControl>
            {/* )} */}
            <div className="d-flex flex-row align-items-center">
              <TextField
                id="outlined-basic"
                type="number"
                label="WEIGHT"
                value={weight}
                variant="outlined"
                onChange={(e) => {
                  setweight(e.target.value);
                }}
                sx={{ width: "90%" }}
              />
              <FormControl
                className="mt-2"
                sx={{ m: 1, minWidth: 120 }}
                size="small"
              >
                <InputLabel id="demo-select-small-label">Unit</InputLabel>
                <Select
                  className=" unit-section"
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={weightUnit}
                  label="UNIT"
                  onChange={(e) => {
                    setWeigthUnit(e.target.value);
                  }}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem> */}
                  <MenuItem value={"KG"}>KG</MenuItem>
                  <MenuItem value={"Ton"}>Ton</MenuItem>
                  <MenuItem value={"LB"}>LB</MenuItem>
                </Select>
              </FormControl>
            </div>

            <TextField
              fullWidth
              id="outlined-basic"
              label="COMMODITY "
              variant="outlined"
              value={commodity}
              sx={{ mt: 2 }}
              onChange={(e) => {
                setcommodity(e.target.value);
              }}
            />
          </Paper>
        </Grid>
        {/* origin and destination grid */}
        <Grid item lg={8} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{ padding: "24px", height: "100%", width: "100%" }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                <Typography className="pb-3">
                  <LocationOnIcon color="primary" />
                  <span className="card-rms">Origin</span>
                </Typography>

                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="COUNTRYNAME"
                    options={country}
                    getOptionLabel={(option) => option.CountryName}
                    value={selectedOriginCountry}
                    filterOptions={filterOptionsForCountryName}
                    onChange={(event, newValue) => {
                      setSelectedOriginCountry(newValue);
                      if (newValue == null) {
                        setFormOriginData((prevState) => ({
                          ...prevState, OriginPort: undefined,
                          originPortName: undefined,
                        }));
                      }
                      setFormOriginData((prevState) => ({
                        ...prevState, originCountry: newValue?.CountryCode,
                        originCountryName: newValue?.CountryName,
                      }));
                      // Clear the error message for "COUNTRY NAME"
                        setValidationErrors((prevErrors) => ({
                          ...prevErrors,
                          originCountry: "",
                        }));
                      // setFormOriginData({
                      //   ...formOriginData,
                      //   originCountry: newValue?.CountryCode,
                      //   originCountryName: newValue?.CountryName,
                      // });

                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={<p>COUNTRY NAME<span style={{ color: "var(--color-miscellaneous-7)" }}>*</span></p>} />
                    )}
                  />{" "}
                  {validationErrors.originCountry && (
                    <div className="error1">
                      {validationErrors.originCountry}
                    </div>
                  )}
                </FormControl>

                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="COUNTRYPORT"
                    options={countryPort}
                    getOptionLabel={(option) => option.title}
                    value={selectedOriginPort}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      setSelectedOriginPort(newValue);
                      const informationToSet = newValue.title.split("(")
                      setFormOriginData({
                        ...formOriginData,
                        originPortName: informationToSet[0] ?? "",
                        OriginPort: informationToSet[informationToSet.length - 1].trim().substring(0, informationToSet[informationToSet.length - 1].length - 1) ?? "",
                      });
                       // Clear the error message for "COUNTRY Port"
                       setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        OriginPort: "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={<p>PORT OF LOADING <span style={{ color: "var(--color-miscellaneous-7)" }}>* {countryPort.length > 0 ? "" : (selectedOriginCountry ? <CircularProgress size={"1em"} /> : "")}</span></p>} />
                    )}
                    disabled={!formOriginData.originCountry}
                  />
                  {validationErrors.OriginPort && (
                    <div className="error1">{validationErrors.OriginPort}</div>
                  )}
                </FormControl>
              </Grid>
              <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                <Typography className="pb-3">
                  <LocationOnIcon color="primary" />
                  <span className="card-rms">Destination</span>
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="DESTCOUNTRYNAME"
                    options={country}
                    getOptionLabel={(option) => option.CountryName}
                    value={selectedDestCountry}
                    filterOptions={filterOptionsForCountryName}
                    onChange={(event, newValue) => {
                      setSelectedDestCountry(newValue);
                      if (newValue == null) {
                        console.log("data000", newValue);
                        setFormOriginData((prevState) => ({
                          ...prevState, destPort: undefined,
                          destPortName: undefined,
                        }));
                      }
                      setFormOriginData((prevState) => ({
                        ...prevState, destCountry: newValue?.CountryCode,
                        destCountryName: newValue?.CountryName,
                      }));
                      // Clear the error message for "DEST COUNTRY "
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        destCountry: "",
                      }));
                      // setFormOriginData({
                      //   ...formOriginData,
                      //   destCountry: newValue?.CountryCode,
                      //   destCountryName: newValue?.CountryName,
                      // });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={<p>COUNTRY NAME<span style={{ color: "var(--color-miscellaneous-7)" }}>*</span></p>} />
                    )}
                  />{" "}
                  {validationErrors.destCountry && (
                    <div className="error1">{validationErrors.destCountry}</div>
                  )}
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem", marginBottom: "-1rem" }}
                  className="details-section"
                >
                  <InputLabel
                    style={{ color: "var(--color-mile)" }}
                  ></InputLabel>
                  <Autocomplete
                    id="DESTCOUNTRYPORT"
                    options={countryDestPort}
                    getOptionLabel={(option) => option.title}
                    value={selectedDestPort}
                    filterOptions={filterOptions}
                    onChange={(event, newValue) => {
                      const informationToSet = newValue.title.split("(")
                      setSelectedDestPort(newValue);
                      setFormOriginData({
                        ...formOriginData,
                        destPortName: informationToSet[0] ?? "",
                        destPort: informationToSet[informationToSet.length - 1].trim().substring(0, informationToSet[informationToSet.length - 1].length - 1) ?? "",
                      });
                      // Clear the error message for "DEST Port"
                      setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        destPort: "",
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={<p>PORT OF DISCHARGE<span style={{ color: "var(--color-miscellaneous-7)" }}>*{countryDestPort.length > 0 ? "" : (selectedDestCountry ? <CircularProgress size={"1em"} /> : "")}</span></p>} />
                    )}
                    disabled={!formOriginData.destCountry}
                  />

                  {validationErrors.destPort && (
                    <div className="error1">{validationErrors.destPort}</div>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* check rate button */}
        <Grid
          item
          xs={12}
          lg={12}
          className="d-flex justify-content-center mt-4"
        >
          <Button
            size="large"
            variant="contained"
            sx={{ borderRadius: "24px", textTransform: "capitalize" }}
            onClick={() => {
              // console.log("contex",context);
              // navigate(Routeconstant.CARGORATES);
              handleRmsDataChange();
            }}
          >
            Check rates
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CreateRatesMargin;
