import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import DetailsRms from "./DetailsRms";
import instance from "../../api/Interceptor";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderButton from "./HeaderButton";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const CreateRms = () => {
  const [headerdata, setHeaderdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [movement, setMovement] = React.useState([]);
  const [cargo, setCargo] = React.useState([]);
  const [inco, setInco] = React.useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getTransportmodes();
  }, []);
  const { state } = useLocation();
  // console.log(state, "dats in state");
  const getTransportmodes = () => {
    setLoading(true);
    instance
      .get("api/v1/master/transportmodes")
      .then((res) => {
        res.data.map((obj, index) => {
          <p>{obj}</p>;
          if (obj.Code === "AIR") {
            obj.blueimg = "AIR-WHITE.svg";
            obj.wideimg = "Air.svg";
          } else if (obj.Code === "COU") {
            obj.blueimg = "COURIER-WHITE.svg";
            obj.wideimg = "courier.svg";
          } else if (obj.Code === "SEA") {
            obj.blueimg = "SHIP-WHITE.svg";
            obj.wideimg = "ship.svg";
          } else if (obj.Code === "ROA") {
            obj.blueimg = "ROAD-WHITE.svg";
            obj.wideimg = "road.svg";
          } else if (obj.Code === "RAI") {
            obj.blueimg = "RAIL-WHITE.svg";
            obj.wideimg = "rail.svg";
          }
          // console.log("obj", obj);
        });
        setHeaderdata(res.data ?? []);

        getMovementtype();
        getcargotype();
        getincotype();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getMovementtype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetMovementType")
      .then((res) => {
        // console.log("movement", res.data);
        setMovement(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcargotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCargoType")
      .then((res) => {
        setCargo(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getincotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetIncoTerm")
      .then((res) => {
        setInco(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [transportType, setTransportType] = useState("SEA");
  return (
    <>
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          className="font-shipment"
          sx={{ cursor: "pointer", marginBottom: "1rem" }}
          onClick={() => navigate("/rms")}
        >
          <KeyboardBackspaceIcon />
          Back
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <HeaderButton
            data={headerdata}
            status={true}
            transportType={transportType}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DetailsRms
            breakoutData={state}
            setTransportType={setTransportType}
          />
        </Grid>
      </Grid>
      <style>
        {`
          @media (max-width: 768px) {
            .MuiGrid-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        `}
      </style>
    </>
  );
};

export default CreateRms;
