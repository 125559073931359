import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import NodatafoundComp from "../NodatafoundComp";
import { AuthContext } from "../../store/context/AuthProvider";
import { useContext } from "react";

export default function BarChart({ filterOptions, checkPop }) {
  const colors = [
    ["#236C9C"],
    ["#0065E3"],
    ["#6190CB"],
    ["#0A2D44"],
    ["#D0DEF0"],
  ];

  const [currentBarData, setCurrentBarData] = useState([]);
  const [nodataFound, setNoDataFound] = useState(false);
  const [labels, setLabels] = useState([]);
  const [labelsForPopUp, setLabelsForPopUp] = useState([]);
  // const [buttonKeys, setButtonKeys] = useState([]);
  const context = useContext(AuthContext);

  const createData = (data) => {
    // console.log(data?.shipmentCarrierCountCollection,"data?.shipmentCarrierCountCollection before")
    data?.shipmentCarrierCountCollection.sort((a, b) => {
      // Compare CarrierName first
      if (a.CarrierName === null && b.CarrierName !== null) {
        return 1; // Move item with null CarrierName to the end
      }
      if (a.CarrierName !== null && b.CarrierName === null) {
        return -1; // Move item with null CarrierName to the end
      }

      // If CarrierName is the same or not null, compare CarrierCode
      if (a.CarrierCode === null && b.CarrierCode !== null) {
        return 1; // Move item with null CarrierCode to the end
      }
      if (a.CarrierCode !== null && b.CarrierCode === null) {
        return -1; // Move item with null CarrierCode to the end
      }

      return 0; // Keep the order unchanged
    });
    // console.log(data?.shipmentCarrierCountCollection,"data?.shipmentCarrierCountCollection after")
    let temp = [];
    let tempLabel = [];
    data?.shipmentCarrierCountCollection?.map((obj) => {
      temp.push(obj.TotalCount); //data value
      tempLabel.push(obj.CarrierName); //label to display
    });

    // to avoid zero
    let temp2 = [];
    let tempLabel2 = [];
    tempLabel.forEach((item, index) => {
      if (temp[index] !== 0) {
        temp2.push(temp[index]);
        if (tempLabel[index] === null) {
          tempLabel2.push("N/A");
        } else {
          tempLabel2.push(tempLabel[index]);
        }
      }
    });

    temp = temp2;
    tempLabel = tempLabel2;

    setLabelsForPopUp(tempLabel);
    let tempTrimLab = tempLabel.slice(0, 4).map((label) => {
      if (label === "N/A") {
        return "N/A";
      } else {
        return label?.slice(0, 8) + "...";
      }
    });
    setLabels(tempTrimLab);
    setCurrentBarData(temp);
  };
  const getBarChart = () => {
    const body = {
      ShpEtdFrom: "",
      ShpEtdTo: "",
      ShpEtaFrom: "",
      ShpEtaTo: "",
      ShpTransportMode: filterOptions?.filterMode
        ? filterOptions?.filterMode
        : "AIR",
      ShpOriginPortCode: "",
      ShpOriginPortName: "",
      ShpDestinationPortCode: "",
      ShpConsignorCode: "",
      ShpConsigneeCode: "",
      IncoTerm: "",
      ShpDirection: "",
      ShpCarrierCode: filterOptions?.filterCarrier
        ? filterOptions?.filterCarrier
        : "",
      Year: filterOptions?.filterYear ? parseInt(filterOptions?.filterYear) : 0,
    };
    instance
      .post(API_PATH.GET_ANALYTICS_SHIPMENTCOUNTBYCARRIER, body)
      .then((res) => {
        if (res.data.length) {
          setNoDataFound(false);
          createData(res.data[0]);
          // let tempbuttonKeys = [];
          // res.data.map((obj, index) => {
          //   tempbuttonKeys.push(obj.TransportMode);
          // });
          // setButtonKeys(tempbuttonKeys);
        } else {
          setNoDataFound(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (context.filterLoaded) getBarChart();
  }, [filterOptions, context.filterLoaded]);

  const data = {
    labels: checkPop
      ? labelsForPopUp.map((lable) => {
          if (lable?.length < 15) return lable;
          return lable?.slice(0, 15) + "...";
        })
      : labels,
    datasets: [
      {
        data: currentBarData,
        backgroundColor: colors,
        label: "Count ",
        barPercentage: 1,
      },
    ],
  };

  return (
    <>
      {nodataFound ? (
        <>
          <NodatafoundComp />
        </>
      ) : (
        <div
          // className={
          //   checkPop && labelsForPopUp.length > 4 ? "chartAreaWrapper" : ""
          // }
          style={{
            height: "90%",
            width:
              checkPop &&
              currentBarData?.length >= 4 &&
              `${13 * currentBarData?.length}em`,
          }}
        >
          <Bar
            style={{
              minHeight: checkPop && "500px",
              maxHeight: "90%",
            }}
            data={data}
            options={{
              animation: !checkPop
                ? false
                : {
                    x: {
                      duration: 1000,
                      from: 3000,
                    },
                    y: {
                      duration: 1000,
                      from: 3000,
                    },
                  },
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: false,
                tooltip: {
                  yAlign: "bottom",
                  callbacks: {
                    title: (tooltipItems) => {
                      return labelsForPopUp[tooltipItems[0].parsed.x];
                    },
                  },
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}
