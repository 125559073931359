import React from "react";
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  Button,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./rmsbookmodal.css";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { useNavigate } from "react-router-dom";

const NewShipment = ({ setBoxmodal, boxmodal }) => {
  const handleClose = () => setBoxmodal(false);
  const navigate = useNavigate();
  return (
    <Dialog
      open={boxmodal}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "50%",
            maxWidth: "650px",
          },
        },
      }}
    >
      <DialogContent style={{ padding: "16px 22px" }}>
        <Grid container spacing={0}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className="d-flex justify-content-end pb-2"
          >
            <CloseOutlinedIcon
              onClick={() => {
                setBoxmodal(false);
              }}
            />
          </Grid>

          <div className="d-flex justify-content-between align-items-center ">
            <div className=" firstdiv-rms">
              <div className=" d-flex justify-content-between align-items-center">
                <p className="mb-0">Notify consignee party </p>
                <Switch />
              </div>
              <>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className=" modal-text">
                      COSIGNEE NAME
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter your password"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className=" modal-text">
                      ADDRESS LINE NO. 1
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter Address line no.1"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className=" modal-text">
                      ADDRESS LINE NO. 2
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter Address line no. 2"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">COUNTRY</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">STATE</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter country"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter state"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">CITY</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">
                      PIN/ZIP CODE
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter city"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter code"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            </div>
            <div
              style={{
                border: " 1px solid #E7E7F0",
                height: "22rem",
              }}
            />
            <div className="  firstdiv-rms">
              <div className="d-flex justify-content-between align-items-center ">
                <p className="mb-0">Notify consignee party </p>
                <Switch />
              </div>
              <>
                <Grid container spacing={1}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className=" modal-text">
                      COSIGNEE NAME
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter your password"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className=" modal-text">
                      ADDRESS LINE NO. 1
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter Address line no.1"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography className=" modal-text">
                      ADDRESS LINE NO. 2
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter Address line no. 2"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">COUNTRY</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">STATE</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter country"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter state"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">CITY</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Typography className=" modal-text">
                      PIN/ZIP CODE
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter city"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    <FormControl size="small" fullWidth>
                      <OutlinedInput
                        // value={filter.Carrier}
                        placeholder="Enter code"
                        // onChange={(e) => {
                        //   setFilter({ ...filter, Carrier: e.target.value });
                        // }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            </div>
          </div>

          {/* ------------- */}

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className=" d-flex justify-content-center align-items-center pt-3 p-0"
          >
            <Button
              className="btn-cancel me-4"
              onClick={() => {
                setBoxmodal(false);
              }}
            >
              cancel
            </Button>
            <Button
              className="btn-createshipment"
              onClick={() => {
                navigate(Routeconstant.RMS);
              }}
            >
              create shipment
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default NewShipment;
