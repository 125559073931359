import { Paper, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CounterMargin from "./CounterMargin";
import QuoteRatesMargin from "./QuoteRatesMargin";
import { useEffect } from "react";
import "../rmsMargin.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../store/context/AuthProvider";
import { customerName } from "../../../Config";

const QuotesCardMargin = (props) => {
    const navigate = useNavigate()
    const context = useContext(AuthContext);
    const { OriginName, DestinationName, CarrierName,CarrierServiceLevel, TransitTime, ValidToDate, ValidFromDate, DestinationCode, OriginCode, ContainerTypes, ClientCode ,CurrencyCode,ServiceLevel } = props.data;
    const [checkedArray, setCheckArray] = useState([])
    const { setUniqueActiveCard, uniqueActiveCard, indexkey } = props
    const [containers, setContainer] = useState([])
    const [fclRates, setFclRates] = useState(0)

  const checkboxClickHandler = (e, index, TotalRate) => {
    if (uniqueActiveCard != indexkey) {
      setCheckArray([]);
      setFclRates(0);
    }
    const name = e.target.value;
    if (e.target.checked === true) {
      setCheckArray((prev) => [...prev, index]);
      if (customerName == "SAR Transport") {
        const obj = { name, TotalRate, quantity: 1 };
        const data = containers.filter((obj) => obj.name != name);
        setContainer([...data, obj]);
      }
    } else {
      const data = containers.filter((obj) => obj.name != name);
      setContainer([...data]);
      setCheckArray((prev) => [...prev.filter((element) => element != index)]);
    }
    setUniqueActiveCard(indexkey);
  };

  useEffect(() => {
    const TotalFclRate = containers.reduce(
      (accumulator, obj) => accumulator + obj.TotalRate * obj.quantity,
      0
    );
    setFclRates(TotalFclRate);
  }, [containers]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  console.log(context.rmsData, "contexxtrmsdata");
  const handleButtonClick = () => {
    // You can prepare the data you want to send to the QuoteRates component here
    const dataToSend = {
      information: props.data,
      containers: containers,
      fclRates: fclRates,
      transportName: context?.rmsData?.transportName ?? "",
      transformMode: context.buttonActive.Code ?? "",
      ContainerTypeCode: context?.rmsData?.Containertype,
      orgizationName: context?.rmsData?.orgizationName,
      CurrencyCode: CurrencyCode,
    };
    console.log(dataToSend, "contexxtrmsdata data to send");

    // Navigate to the QuoteRates component and pass the data using the 'state' object
    navigate("/quoterates", { state: dataToSend });
  };

    return (
        <>
            <Paper className='d-flex  flex-column justify-content-around p-4' style={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 5px 15px, rgba(0, 0, 0, 0.22) 0px 10px 10px" }}>
                <Grid container spacing={2}>
                    <Grid item lg={1}>
                        <Typography className='headingMarginCss'>Origin</Typography>
                        <Typography className="subheadingRmsCss">{OriginName ?? OriginCode ?? 'N/A'}</Typography>
                    </Grid>
                    <Grid item lg={2}>
                        <Typography className='headingMarginCss'>Destination</Typography>
                        <Typography className="subheadingRmsCss">{DestinationName ?? DestinationCode ?? 'N/A'}</Typography>
                    </Grid>
                    <Grid item lg={1}>
                        <Typography className='headingMarginCss'>Carrier</Typography>
                        <Typography className="subheadingRmsCss">{(CarrierName && CarrierName.length) ? CarrierName : "N/A"}</Typography>

                    </Grid>
                    <Grid item lg={2}>
                        <Typography className='headingMarginCss'>Carrier Service Level</Typography>
                        <Typography className="subheadingRmsCss">{(CarrierServiceLevel && CarrierServiceLevel.length) ? CarrierServiceLevel : "N/A"}</Typography>

                    </Grid>
                    <Grid item lg={2}>
                        <Typography className='headingMarginCss'>Service Level</Typography>
                        <Typography className="subheadingRmsCss">{(ServiceLevel && ServiceLevel.length) ? ServiceLevel : "N/A"}</Typography>

                    </Grid>
                    <Grid item lg={1}>
                        <Typography className='headingMarginCss'>Transit time</Typography>
                        <Typography className="subheadingRmsCss">{(TransitTime && TransitTime.length) ? TransitTime : "N/A"}</Typography>

                    </Grid>
                    <Grid item lg={2}>
                        <Typography className='headingMarginCss'>Validity</Typography>
                        <Typography className="subheadingRmsCss">{`${formatDate(ValidFromDate)} `}  - {` ${formatDate(ValidToDate)}`} </Typography>
                    </Grid>
                    <Grid item lg={1}>
                        <Typography className='headingMarginCss'> Rate </Typography>
                        {
                            fclRates !== 0 && uniqueActiveCard === indexkey && <Typography className="subheadingRmsCss">{CurrencyCode} {fclRates} </Typography>
                        }
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ borderBottom: '3px solid var(--color-secondary-dark)', width: '100%', opacity: ".5", marginTop: ".5%" }} />


                <Grid container className='d-flex justify-content-evenly mt-2'>
                    <Grid item lg={11}>
                        {/* counter purpose */}
                        <Grid container className='d-flex justify-content-evenly'>
                            {
                                ContainerTypes?.map((Individualcontainer, index) => {

                                    const name = Individualcontainer?.ContainerISO ?? "N/A";
                                    // const TotalRate = Individualcontainer?.Rates.reduce((accumulator, obj) => obj.Rate + accumulator, 0) ?? 0
                                    const TotalRate = Individualcontainer?.TotalValue ?? 0
                                    return (
                                        <Grid item key={index}>
                                            <label>
                                                <input style={{ transform: "scale(1.5)", marginRight: "10px" }} type="checkbox" checked={uniqueActiveCard === indexkey && checkedArray.includes(index)}
                                                    onClick={(e) => checkboxClickHandler(e, index, TotalRate)} value={name} />
                                                <span style={{ fontSize: "var(--fs-head)" }}><b>{TotalRate}</b> / {name}</span>
                                            </label>
                                            {customerName !== "SAR Transport" && checkedArray.includes(index) && uniqueActiveCard === indexkey && <CounterMargin setContainer={setContainer} containers={containers} name={name} TotalRate={TotalRate} />}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>

                    </Grid>

          <Grid item lg={1}>
            <Button
              variant="contained"
              onClick={handleButtonClick}
              disabled={
                uniqueActiveCard === indexkey
                  ? checkedArray.length > 0
                    ? false
                    : true
                  : true
              }
            >
              Create Quotes
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default QuotesCardMargin;
