import { baseOrderUrl } from "../../../../../Config";
import orderInstance from "../../../../../api/orderInterceptor";
import { API_PATH } from "../../../../../constant/apiPath";
const docTypes = async () => {
  let { data } = await orderInstance.get(
    `${baseOrderUrl}${API_PATH.GET_DOCUMENT_LIST}`
  );
  return data;
};
export { docTypes };
