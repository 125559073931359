import React, { useState } from "react";
import { basename } from "../../../Config";
import { Divider, Grid, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import "../rmsMargin.css";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HorizontalRuleOutlinedIcon from "@mui/icons-material/HorizontalRuleOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import DocUploadShipmentSidebar from "../../../components/sidebar/DocUploadShipmentSidebar";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import Button from "@mui/material/Button";
import NewShipment from "../../../components/model/rms/NewShipment";
import NegotiateShipment from "../../../components/model/rms/NegotiateShipment";
import CreatedShipment from "../../../components/model/rms/CreatedShipment";

const DocumentRmsMargin = () => {
  const navigate = useNavigate();

  const [docUpload, setDocUpload] = useState(false);
  const [negotiatemodal, setNegotiatemodal] = useState("");
  const [boxmodal, setBoxmodal] = useState(false);
  const [arrow, setArrow] = useState({
    first: false,
  });
  return (
    <>
      {docUpload ? (
        <DocUploadShipmentSidebar
          setDocUpload={setDocUpload}
          docUpload={docUpload}
        />
      ) : null}
      {boxmodal ? (
        <NewShipment boxmodal={boxmodal} setBoxmodal={setBoxmodal} />
      ) : null}
      {negotiatemodal === "b" ? (
        <CreatedShipment
          negotiatemodal={negotiatemodal}
          setNegotiatemodal={setNegotiatemodal}
        />
      ) : null}
      {negotiatemodal === "a" ? (
        <NegotiateShipment
          negotiatemodal={negotiatemodal}
          setNegotiatemodal={setNegotiatemodal}
        />
      ) : null}

      <Grid container spacing={1}>
        <Grid
          item
          md={12}
          sx={{ cursor: "pointer", marginBottom: "1rem" }}
          onClick={() => navigate(Routeconstant.CREATERMSLIST)}
        >
          <KeyboardBackspaceIcon />
          Back to Cargo details
        </Grid>
      </Grid>
      <div
        style={{ backgroundColor: "white", borderRadius: "12px 12px 0px 0px" }}
      >
        <Grid container spacing={1} className="pe-4">
          <Grid
            item
            xs={8}
            className="p-4"
            // sx={{ borderRight: "1px solid #E7E7F0 " }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className="basic-head">Documentation</Typography>
              </Grid>
              <Grid item xs={12} sx={{ position: "relative" }}>
                <table className="w-100 documentation-info mt-3">
                  <thead>
                    <tr className="documentation table">
                      <th>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="defaultCheck1"
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Type </th>
                      <th>File type</th>
                      <th className="d-flex justify-content-center shadow-none">
                        <img src={`/${basename}/assets/dotted.svg`} />
                      </th>
                    </tr>
                  </thead>
                  <>
                    <tbody>
                      {[1, 2, 3, 4, 6, 7].map(() => {
                        return (
                          <tr className="documentation-row">
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="defaultCheck1"
                                />
                              </div>
                            </td>
                            <td>h </td>
                            <td>h </td>
                            <td>
                              <Button>
                                <PictureAsPdfOutlinedIcon className="pdf-icon" />
                              </Button>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <Button variant="outlined" className="mr-2">
                                  <FileDownloadOutlinedIcon />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                </table>
                <img
                  src={`/${basename}/assets/DocumentUploadIcon.svg`}
                  style={{
                    position: "absolute",
                    left: "90%",
                    bottom: " 10%",
                    height: " 5rem",
                  }}
                  onClick={() => {
                    setDocUpload(true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* ------------- */}
          <Grid item xs={4} sx={{ borderRadius: "20px" }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "white",
                  boxShadow: " 0px 1px 10px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  padding: "2rem 1rem 1rem 2rem",
                }}
              >
                <p className="mb-0 selected-head">Selected Options</p>

                <Grid container spacing={1} className="px-3 pt-5 pb-3">
                  <p className="mb-0 head-text">Port to Port</p>

                  <Grid
                    item
                    xs={12}
                    style={{
                      padding: "8px 8px 8px 2px",
                      borderBottom: "1px solid rgb(231, 231, 240",
                    }}
                    className="d-flex align-items-center justify-content-between"
                  >
                    <div className="d-flex  align-items-center justify-content-between">
                      <LocationOnOutlinedIcon color="primary" />
                      <p className="mb-0 font-rms "> India</p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <HorizontalRuleOutlinedIcon color="primary" />
                      <img
                        src={`/${basename}/assets/ship.svg`}
                        style={{ width: "25%" }}
                      />
                      <HorizontalRuleOutlinedIcon color="primary" />
                    </div>
                    <div className="d-flex  align-items-center justify-content-between">
                      <LocationOnOutlinedIcon color="primary" />
                      <p className="mb-0 font-rms">USA</p>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      padding: "2rem 0rem 3rem 0rem",
                    }}
                  >
                    <p className="mb-1  head-text ">
                      EST travel time
                      <span className=" head-text-para ps-2 ">
                        32-40<span className=" head-text-span">days</span>
                      </span>
                    </p>
                    <p className="mb-0 head-text-para "></p>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    style={{
                      padding: "2rem 0rem 3rem 0rem",
                    }}
                    className="d-flex align-items-center justify-content-end "
                  >
                    <div className="pe-2">
                      <p className="mb-0 indigo-rms doc-indigo">Freight</p>
                      <p className="mb-0 indigo-rms  doc-indigo-right">Right</p>
                    </div>
                    <div>
                      <p className="mb-0 airlines-rms ">Indigo Airlines </p>
                      {[1, 2, 3, 4].map(() => {
                        return (
                          <StarIcon
                            color="primary"
                            style={{ fontSize: "1rem" }}
                          />
                        );
                      })}

                      <StarBorderOutlinedIcon
                        color="primary"
                        style={{ fontSize: "1rem" }}
                      />
                      <span className=" span-rate">(5192)</span>
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    style={{
                      borderTop: "1px solid rgb(231, 231, 240",
                      borderBottom: "1px solid rgb(231, 231, 240",
                      padding: "2rem 0rem ",
                    }}
                  >
                    <p className="m-0 head-text-para"> Price breakup</p>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid">
                          Basic fare
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="d-flex align-items-center grid-rms"
                      >
                        <Divider
                          variant="middle"
                          flexItem
                          sx={{
                            backgroundColor: " var(--color-secondary-light)",
                            width: "88%",
                            marginTop: "0.8rem",
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid">
                          {" "}
                          100000/-
                        </p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid">
                          Convience fee
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="d-flex align-items-center grid-rms"
                      >
                        <Divider
                          variant="middle"
                          flexItem
                          sx={{
                            backgroundColor: " var(--color-secondary-light)",
                            width: "88%",
                            marginTop: "0.8rem",
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid"> 25000/-</p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid">
                          {" "}
                          Insuarnce fee
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="d-flex align-items-center grid-rms"
                      >
                        <Divider
                          variant="middle"
                          flexItem
                          sx={{
                            backgroundColor: " var(--color-secondary-light)",
                            width: "88%",
                            marginTop: "0.8rem",
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid"> 25000/-</p>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid"> Total</p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        className="d-flex align-items-center grid-rms"
                      >
                        <Divider
                          variant="middle"
                          flexItem
                          sx={{
                            backgroundColor: " var(--color-secondary-light)",
                            width: "88%",
                            marginTop: "0.8rem",
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <p className="m-0 head-text-span text-grid">
                          {" "}
                          150000/-
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      padding: "1.5rem 0rem 0.5rem 0rem ",
                    }}
                  >
                    <p className="total-rms">₹150000/-</p>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      padding: "1.5rem 0rem 0.5rem 0rem ",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      {arrow.first ? (
                        <div
                          style={{
                            backgroundColor: "#236C9C",
                            padding: "0.5rem",
                            borderRadius: "20px",
                            position: " absolute",
                            width: "100%",
                            left: "0",
                            bottom: " 26%",
                          }}
                        >
                          <div className="d-flex justify-content-between">
                            <p
                              className="div-shipment"
                              onClick={() => {
                                setNegotiatemodal("a");
                              }}
                            >
                              Chat to negotiate
                            </p>
                            <KeyboardArrowRightOutlinedIcon
                              style={{ color: "white" }}
                            />
                          </div>
                          <div
                            style={{
                              borderBottom: " 1px solid var(--color-white)",
                            }}
                          />
                          <div className="d-flex justify-content-between pb-4 pt-2">
                            <p
                              className="div-shipment"
                              onClick={() => {
                                setBoxmodal(true);
                              }}
                            >
                              Convert to shipment
                            </p>
                            <KeyboardArrowRightOutlinedIcon
                              style={{ color: "white" }}
                            />
                          </div>
                        </div>
                      ) : null}

                      <div
                        className=" d-flex  justify-content-between  div-shipment btn-shipment"
                        onClick={() => {
                          if (arrow.first === true) {
                            setArrow({ ...arrow, first: false });
                          } else {
                            setArrow({ ...arrow, first: true });
                          }
                        }}
                      >
                        <p className="mb-0">Take Action</p>

                        <div
                          style={{
                            border: " 1px solid var(--color-white)",
                          }}
                        />
                        <KeyboardArrowUpOutlinedIcon
                          style={{
                            color: "white",
                            // borderLeft: "2px solid white",
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DocumentRmsMargin;
