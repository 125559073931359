import React from "react";
import QuotesCardMargin from "./QuotesCardMargin";
import { useEffect } from "react";
import instance from "../../../api/Interceptor";
import { useState } from "react";

import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../../store/context/AuthProvider";
import { customerName } from "../../../Config";

const CargoRatesMargin = () => {
  const context = useContext(AuthContext);
  const [getRMSRates, setGetRMSRates] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [uniqueActiveCard, setUniqueActiveCard] = useState(null);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const today = new Date();
  // today.setMonth(today.getMonth() + 1); // Add one month to the current date
  const endDate = formatDate(today.toISOString());
  const handleChange_GetRMSRates = () => {
    setLoading(true);
    const body = {
      ClientCode: "",
      ContainerMode: context.rmsData.Containertype,
      OriginCode: context.rmsData.OriginPort,
      DestinationCode: context.rmsData.destinationport,
      SearchDate: endDate,
      TrasnportMode: context.buttonActive.Code,
    };
    instance
      .post("api/v1/Quotation/GetRates", body)
      .then((res) => {
        setGetRMSRates(res.data);
        console.log("setGetRMSRates", res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    handleChange_GetRMSRates();
  }, []);
  useEffect(() => {
    if (!context.rmsData.Containertype) navigate("/createrates");
  }, [context?.rmsData?.Containertype]);
  if (loading) {
    return (
      <>
        <Skeleton variant="rounded" width={"100%"} height={"20%"} />
        <br />
        <Skeleton variant="rounded" width={"100%"} height={"20%"} />
        <br />
        <Skeleton variant="rounded" width={"100%"} height={"20%"} />
        <br />
        <Skeleton variant="rounded" width={"100%"} height={"20%"} />
        <br />
      </>
    );
  } else if (getRMSRates?.length === 0) {
    return (
      <div className="center-message">
        {customerName === "SAR Transport" ? (
          <span className="font-change">
            No data Found Contact Pricing Team.
          </span>
        ) : (
          <span className="font-change">No data Found!</span>
        )}
      </div>
    );
  } else {
    return (
      <div className="d-flex flex-column  gap-4">
        {getRMSRates?.map((element, index) => {
          return (
            <QuotesCardMargin
              key={index}
              data={element}
              indexkey={index}
              uniqueActiveCard={uniqueActiveCard}
              setUniqueActiveCard={setUniqueActiveCard}
            />
          );
        })}
      </div>
    );
  }
};

export default CargoRatesMargin;
