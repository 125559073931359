import { Backdrop, CircularProgress, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import HeaderButton from "./HeaderEnquiryBtn";
import DetailsEnquiry from "./DetailsEnquiry";
import instance from "../../api/Interceptor";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { AuthContext } from "../../store/context/AuthProvider";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Index = () => {
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log("userData", userData);
  const context = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [headerdata, setHeaderdata] = useState([]);
  const [error, setError] = useState(false);
  const [formdropData1, setFormdropData1] = useState({
    transportType: "",
    movementType: "",
    cargoType: "",
    incoType: "",
  });
  const [formOriginData, setFormOriginData] = useState({
    originCountry: null,
    originPort: null,
    originPin: "",
    destCountry: null,
    destPort: null,
    destPin: "",
    remarks: "",
    originCountryName: null,
    destCountryName: null,
    originPortName: null,
    destPortName: null,
  });
  const [rows, setRows] = useState([
    {
      id: 0,
      PackagingType: "",
      Quantity: "",
      DimensionalUnit: "",
      Length: "",
      Width: "",
      Height: "",
      WeightUnit: "",
      WeightPerPiece: "",
      containerQuant: "",
      containerType: "",
      Stakable: false, // Default value for Stakable field
      disableDelete: true,
    }, // Default row with disableDelete set to true
  ]);
  const [validationErrors, setValidationErrors] = useState({
    transportType: "",
    movementType: "",
    incoType: "",
    selectedDate: "",
    originCountry: "",
    originPort: "",
    destCountry: "",
    destPort: "",
  });
  const [loading, setLoading] = useState(false);
  const [movement, setMovement] = useState([]);
  const [cargo, setCargo] = useState([]);
  const [inco, setInco] = useState([]);
  const [error1, setError1] = useState(false); // For the first scenario
  const [error2, setError2] = useState(false); // For the second scenario
  useEffect(() => {
    getTransportmodes();
  }, []);

  const ClearInputData = () => {
    // Reset the state variables to their initial values or empty strings
    setHeaderdata([]);
    setFormdropData1({
      transportType: "",
      movementType: "",
      cargoType: "",
      incoType: "",
    });

    setFormOriginData({
      originCountry: null,
      originPort: null,
      originPin: "",
      destCountry: null,
      destPort: null,
      destPin: "",
      remarks: "",
    });

    setRows([
      {
        id: 0,
        PackagingType: "",
        Quantity: "",
        DimensionalUnit: "",
        Length: "",
        Width: "",
        Height: "",
        WeightUnit: "",
        WeightPerPiece: "",
        containerQuant: "",
        containerType: "",
        Stakable: false,
        disableDelete: true,
      },
    ]);
    setValidationErrors({
      transportType: "",
      movementType: "",
      incoType: "",
      selectedDate: "",
      originCountry: "",
      originPort: "",
      destCountry: "",
      destPort: "",
    });
  };
  // console.log("context.buttonActive1222", context.buttonActive.Description);

  const handleRmsDataChange1 = () => {
    setError1(false);
    setError2(false);
    setValidationErrors({
      transportType: "",
      movementType: "",
      incoType: "",
      originCountry: "",
      originPort: "",
      destCountry: "",
      destPort: "",
    });
    // console.log("error");
    let errors = {};
    // Perform input validations
    if (formdropData1.transportType.trim() === "") {
      errors.transportType = "Please provide Transport Type.";
    }
    if (formdropData1.movementType.trim() === "") {
      errors.movementType = "Please provide Movement Type.";
    }
    if (formdropData1.incoType.trim() === "") {
      errors.incoType = "Please provide Inco Type.";
    }
    if (
      formOriginData.originCountry === null ||
      formOriginData.originCountry === ""
    ) {
      errors.originCountry = "Please provide Country Name.";
    }
    if (
      formOriginData.originPort === null ||
      formOriginData.originPort === ""
    ) {
      errors.originPort = "Please provide Country Port.";
    }
    if (
      formOriginData.destCountry === null ||
      formOriginData.destCountry === ""
    ) {
      errors.destCountry = "Please provide Country Name.";
    }
    if (formOriginData.destPort === null || formOriginData.destPort === "") {
      errors.destPort = "Please provide Country Port.";
    }

    if (Object.keys(errors).length > 0) {
      // Display validation errors
      setValidationErrors(errors);
      return;
    }
    setLoading(true);
    // console.log("savedTransportData", JSON.parse(localStorage.getItem("TransportData")));
    const transportTypeString = JSON.parse(
      localStorage.getItem("TransportData")
    );
    const transportTypeParts = transportTypeString.split(" (");
    const code =
      transportTypeParts.length === 2
        ? transportTypeParts[1].replace(")", "")
        : null;

    if (
      ["LSE", "CON", "BCN", "LCL", "BLK", "LQD", "BBK", "OBC", "UNA"].includes(
        code
      )
    ) {
      // Validation for the first scenario
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        if (
          item.PackagingType === "Select" ||
          item.DimensionalUnit === "Select" ||
          item.WeightUnit === "Select" ||
          item.Quantity === "" ||
          item.Length === "" ||
          item.Width === "" ||
          item.Height === "" ||
          item.WeightPerPiece === ""
        ) {
          setError1(true);
          setError2(false);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select package type, Quantity, Dimensional unit, Length, Width, Height, Weight unit, and weight Per Piece for each row",
            confirmButtonColor: "#236c9c",
          });
          setLoading(false);
          return;
        }
      }
    } else {
      // Validation for the second scenario
      for (let i = 0; i < rows.length; i++) {
        const item = rows[i];
        if (item.containerQuant === "" || item.containerType === "Select") {
          setError1(false);
          setError2(true);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Please select container quantity and container type for each row",
            confirmButtonColor: "#236c9c",
          });
          setLoading(false);
          return;
        }
      }
    }
    // console.log("formdropData1.transportType", formdropData1.transportType);

    const ratingPackages = rows.map((row) => {
      const quantityValue =
        row.containerQuant !== "" ? row.containerQuant : row.Quantity;
      return {
        containerType: row.containerType,
        dimensionUnit: row.DimensionalUnit,
        height: row.Height,
        length: row.Length,
        pkgType: row.PackagingType,
        quantity: quantityValue,
        stackable: row.Stakable,
        weight: row.WeightPerPiece,
        weightUnit: row.WeightUnit,
        width: row.Width,
      };
    });

    const body = {
      Inquiry_CargoDetails: ratingPackages,
      Inquiry_CargoType: formdropData1.cargoType,
      Inquiry_CompanyType: "",
      Inquiry_ContactName: userData.Name,
      Inquiry_DestinationCountry: `${formOriginData.destCountryName} (${formOriginData.destCountry})`,
      Inquiry_DestinationPinCode: formOriginData.destPin,
      Inquiry_DestinationPort: `${formOriginData.destPortName} (${formOriginData.destPort})`,
      Inquiry_EMail: userData.Email,
      Inquiry_IncoTerm: formdropData1.incoType,
      Inquiry_MovementType: formdropData1.movementType,
      Inquiry_Organization: "",
      Inquiry_OriginCountry: `${formOriginData.originCountryName} (${formOriginData.originCountry})`,
      Inquiry_OriginPinCode: formOriginData.originPin,
      Inquiry_OriginPort: `${formOriginData.originPortName} (${formOriginData.originPort})`,
      Inquiry_Phone: "",
      Inquiry_Remarks: formOriginData.remarks,
      Inquiry_TransportMode:
        context?.buttonActive?.Description ?? "Air Freight",
      Inquiry_ContainerType: formdropData1.transportType,
    };
    instance
      .post("api/v1/Inquiry/Create", body)
      .then((res) => {
        // console.log("resp", res.data);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Data submitted successfully!",
          confirmButtonColor: "#236c9c",
        });
        //  toast.success("Data submitted successfully!");
        ClearInputData(true);
        // console.log("After clearing:", formOriginData.originPort, formOriginData.destPort);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const getTransportmodes = () => {
    setLoading(true);
    instance
      .get("api/v1/master/transportmodes")
      .then((res) => {
        // console.log("ResponseData", res.data);
        res.data.map((obj, index) => {
          <p>{obj}</p>;
          if (obj.Code === "AIR") {
            obj.blueimg = "AIR-WHITE.svg";
            obj.wideimg = "Air.svg";
          } else if (obj.Code === "COU") {
            obj.blueimg = "COURIER-WHITE.svg";
            obj.wideimg = "courier.svg";
          } else if (obj.Code === "SEA") {
            obj.blueimg = "SHIP-WHITE.svg";
            obj.wideimg = "ship.svg";
          } else if (obj.Code === "ROA") {
            obj.blueimg = "ROAD-WHITE.svg";
            obj.wideimg = "road.svg";
          } else if (obj.Code === "RAI") {
            obj.blueimg = "RAIL-WHITE.svg";
            obj.wideimg = "rail.svg";
          }
          // console.log("obj", obj)
        });
        setHeaderdata(res.data ?? []);
        // console.log("header", headerdata);
        getMovementtype();
        getcargotype();
        getincotype();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getMovementtype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetMovementType")
      .then((res) => {
        // console.log("movement", res.data);
        setMovement(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getcargotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetCargoType")
      .then((res) => {
        //  console.log("movement",res.data);
        setCargo(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getincotype = () => {
    setLoading(true);
    instance
      .get("api/v1/master/GetIncoTerm")
      .then((res) => {
        //  console.log("movement",res.data);
        setInco(res.data ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      {
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      }
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sx={{ cursor: "pointer", marginBottom: "1rem" }}
          onClick={() => navigate("/enquirydashboard")}
        >
          <KeyboardBackspaceIcon />
          Back
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <HeaderButton
            data={headerdata}
            formdropData1={formdropData1}
            setFormdropData1={setFormdropData1}
            formOriginData={formOriginData}
            setFormOriginData={setFormOriginData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <DetailsEnquiry
            formdropData1={formdropData1}
            setFormdropData1={setFormdropData1}
            formOriginData={formOriginData}
            setFormOriginData={setFormOriginData}
            handleRmsDataChange1={handleRmsDataChange1}
            rows={rows}
            setRows={setRows}
            validationErrors={validationErrors}
            setValidationErrors={setValidationErrors}
          />
        </Grid>
      </Grid>
      <style>
        {`
          @media (max-width: 768px) {
            .MuiGrid-container {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
          }
        `}
      </style>
    </>
  );
};

export default Index;
