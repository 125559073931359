import React, { useState } from "react";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import urlResolver from "../../../utils/urlResolver";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import FilterModal from "../modal/FilterModal";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";

const SearchFunctionaility = ({ search, setSearch }) => {
  const pattern = /^[A-Za-z\s]+$/;
  return (
    <Grid
      item
      xs={10}
      sm={10}
      md={10}
      lg={10}
      className="headersearch searchShipmentDiv"
    >
      <input
        type="text"
        className="form-control "
        placeholder=" Search by user name/ Email ID"
        value={search}
        onChange={(event) => {
          const inputString = event.target.value;
          if (pattern.test(inputString) || inputString.length === 0) {
            setSearch(inputString);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
          }
        }}
      />
      <img
        src={urlResolver("assets/search_icon.svg")}
        style={{ color: "rgba(var(--text-heading-dark))" }}
        className="searchIcon"
      />
      {search && (
        <CloseOutlinedIcon
          onClick={() => {
            setSearch("");
          }}
          style={{
            position: "absolute",
            right: "1rem",
            cursor: "pointer",
          }}
          className="searchClearIcon"
        />
      )}
    </Grid>
  );
};
const FilterFunctionality = ({ setOpen }) => {
  return (
    <Grid
      item
      xs={1}
      sm={1}
      md={1}
      lg={1}
      className="cursorPointer d-flex justify-content-center align-items-center filter-div"
    >
      <FilterAltOutlinedIcon
        onClick={() => {
          setOpen(true);
        }}
      />
    </Grid>
  );
};
const TableHeadOrg = ({ data, setSeletedRow, selectedRow }) => {
  return (
    <thead>
      <tr>
        <th style={{ verticalAlign: "middle" }}>organization</th>
        <th style={{ verticalAlign: "middle" }}>organization code</th>
        <th style={{ verticalAlign: "middle" }}>
          <Checkbox
            checked={data.length == selectedRow?.length}
            onChange={(event) => {
              if (event.target.checked) {
                setSeletedRow(data.map((info) => info.orgcode));
              } else {
                setSeletedRow([]);
              }
            }}
          />
          Assign
        </th>
      </tr>
    </thead>
  );
};
const TableBodyRowOrg = ({ information, selectedRow, setSeletedRow }) => {
  return (
    <tr>
      <td
        style={{
          maxWidth: "200px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {information?.orgname ?? "N/A"}
      </td>
      <td
        style={{
          maxWidth: "100px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {information?.orgcode ?? "N/A"}
      </td>
      <td>
        <Checkbox
          checked={selectedRow.includes(information?.orgcode)}
          onChange={(event) => {
            if (event.target.checked) {
              setSeletedRow((prev) => [...prev, information?.orgcode]);
            } else {
              setSeletedRow((prev) =>
                prev.filter((info) => info != information?.orgcode)
              );
            }
          }}
        />
      </td>
    </tr>
  );
};
const OrganizationMapping = () => {
  const { state } = useLocation();
  const information = state.item;
  const [search, setSearch] = useState(null);
  // store the code of the selected row as code are unique and help to determine the seleted data
  const [selectedRow, setSeletedRow] = useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);

  const navigate = useNavigate(-1);

  // state to store the info requested from api
  const [data, setData] = useState([
    {
      orgname:
        "kjahdsksdfadsfasdjflasjdflkjsdljasdlkjfladsjflkasdjflkasdjflkasdjfl;asdjflk;ads",
      orgcode: "NIYB",
      // checkStatus : "",
    },
    {
      orgname:
        "kjahdsksdfadsfasdjflasjdflkjsdljasdlkjfladsjflkasdjflkasdjflkasdjfl;asdjflk;ads",
      orgcode: "NIYesB",
      // checkStatus : "",
    },
    {
      orgname:
        "kjahdsksdfadsfasdjflasjdflkjsdljasdlkjfladsjflkasdjflkasdjflkasdjfl;asdjflk;ads",
      orgcode: "NIYsqB",
      // checkStatus : "",
    },
    {
      orgname:
        "kjahdsksdfadsfasdjflasjdflkjsdljasdlkjfladsjflkasdjflkasdjflkasdjfl;asdjflk;ads",
      orgcode: "NIYB12",
      // checkStatus : "",
    },
    {
      orgname:
        "kjahdsksdfadsfasdjflasjdflkjsdljasdlkjfladsjflkasdjflkasdjflkasdjfl;asdjflk;ads",
      orgcode: "NIYBww",
      // checkStatus : "",
    },
  ]);
  return (
    <>
      {openFilterModal && (
        <FilterModal setOpen={setOpenFilterModal} open={openFilterModal} />
      )}
      <div>
        <div className="d-flex  justify-content-between">
          <p
            className="cursorPointer"
            onClick={() => {
              navigate(-1);
            }}
          >
            {" "}
            <KeyboardBackspaceIcon /> Back
          </p>
          <div className="font-weight-bold">{information?.ContactName}</div>
          <div></div>
        </div>

        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
        >
          <SearchFunctionaility search={search} setSearch={setSearch} />
          <FilterFunctionality setOpen={setOpenFilterModal} />
        </Grid>
        <br />

        <table className="table">
          <TableHeadOrg
            data={data}
            selectedRow={selectedRow}
            setSeletedRow={setSeletedRow}
          />
          <tbody>
            {data.map((singleRowInfo, index) => (
              <TableBodyRowOrg
                key={index}
                information={singleRowInfo}
                setSeletedRow={setSeletedRow}
                selectedRow={selectedRow}
              />
            ))}
          </tbody>
        </table>

        <div className="d-flex justify-content-end">
          <Button variant="contained">Save</Button>
        </div>
      </div>
    </>
  );
};

export default OrganizationMapping;
