import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Divider,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import instance from "../../api/Interceptor";
import Autocomplete from "@mui/material/Autocomplete";
import { AuthContext } from "../../store/context/AuthProvider";
import EnquiryList from "./EnquiryList";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import HeaderEnquiryBtn from "./HeaderEnquiryBtn";
import { all } from "axios";
const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
// console.log("con=====>", savedTransportData);

const Enquirypreview = () => {
  const [loading, setLoading] = useState(false);
  const { inquiryNo } = useParams();
  const [allinquiryData, setAllInquiryData] = useState([]);
  console.log("inquiryNo=====>", inquiryNo);
  const getInquiryDetails = () => {
    setLoading(true);
    const body = {
      inquiryNumber: inquiryNo,
    };
    const url = "api/v1/Inquiry/GetInquiry";
    instance
      .post(url, body)
      .then((res) => {
        setAllInquiryData(res.data);
        // console.log("err", res)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getInquiryDetails();
  }, []);
  const transportTypeString = savedTransportData;
  const transportTypeParts = transportTypeString.split(" (");
  const code =
    transportTypeParts.length === 2
      ? transportTypeParts[1].replace(")", "")
      : null;
  // console.log("code", code);
  return (
    <>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <HeaderEnquiryBtn
          selectedType={allinquiryData?.enquiryModel?.Inquiry_TransportMode}
        />
      </Grid>
      <br></br>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* --FIRST-- */}

        <Grid item lg={1} md={1} xs={2}>
          <p className="m-0"> Rate Details</p>
        </Grid>
        <Grid item lg={11} xs={10} className="d-flex ali dash-rms">
          <Divider
            variant="center"
            flexItem
            sx={{
              backgroundColor: " var(--color-secondary-light)",
              width: "100%",
              marginTop: "0.8rem",
            }}
          />
        </Grid>

        <Grid item lg={3} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <TextField
                label="TRANSPORT TYPE"
                variant="outlined"
                name="transportType"
                value={
                  allinquiryData?.enquiryModel?.Inquiry_ContainerType?.length
                    ? allinquiryData?.enquiryModel?.Inquiry_ContainerType
                    : "N/A"
                }
                disabled
              />
            </FormControl>
            {/* / */}
            <br />
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <TextField
                label="MOVEMENT TYPE"
                variant="outlined"
                name="movementType"
                value={
                  allinquiryData?.enquiryModel?.Inquiry_MovementType?.length
                    ? allinquiryData?.enquiryModel?.Inquiry_MovementType
                    : "N/A"
                }
                disabled
              />
            </FormControl>

            {/* /////////// */}
            <br />
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <TextField
                label="CARGO TYPE"
                variant="outlined"
                name="cargoType"
                value={
                  allinquiryData?.enquiryModel?.Inquiry_CargoType?.length
                    ? allinquiryData?.enquiryModel?.Inquiry_CargoType
                    : "N/A"
                }
                disabled
              />
            </FormControl>

            {/* /////////////// */}

            <br />
            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <TextField
                label="INCO TERMS"
                variant="outlined"
                name="incoType"
                value={
                  allinquiryData?.enquiryModel?.Inquiry_IncoTerm?.length
                    ? allinquiryData?.enquiryModel?.Inquiry_IncoTerm
                    : "N/A"
                }
                disabled
              />
            </FormControl>
          </Paper>
        </Grid>
        {/* --MID-- */}
        <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
          <Paper
            elevation={4}
            sx={{ padding: "24px", height: "100%", width: "100%" }}
          >
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                <Typography className="pb-3">
                  <LocationOnIcon color="primary" />
                  <span className="card-rms">Origin</span>
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="COUNTRYNAME"
                    variant="outlined"
                    name="originPort"
                    value={
                      allinquiryData?.enquiryModel?.Inquiry_OriginCountry
                        ?.length
                        ? allinquiryData?.enquiryModel?.Inquiry_OriginCountry
                        : "N/A"
                    }
                    disabled
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="COUNTRYPORT"
                    variant="outlined"
                    name="originPort"
                    value={
                      allinquiryData?.enquiryModel?.Inquiry_OriginPort?.length
                        ? allinquiryData?.enquiryModel?.Inquiry_OriginPort
                        : "N/A"
                    }
                    disabled
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="COUNTRY PIN/ZIP CODE"
                    variant="outlined"
                    name="originPin"
                    value={
                      allinquiryData?.enquiryModel?.Inquiry_OriginPinCode
                        ?.length
                        ? allinquiryData?.enquiryModel?.Inquiry_OriginPinCode
                        : "N/A"
                    }
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6} md={12} sm={12} sx={{ width: "100%" }}>
                <Typography className="pb-3">
                  <LocationOnIcon color="primary" />
                  <span className="card-rms">Destination</span>
                </Typography>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="DESTCOUNTRYNAME"
                    variant="outlined"
                    name="destcountryname"
                    value={
                      allinquiryData?.enquiryModel?.Inquiry_DestinationCountry
                        ?.length
                        ? allinquiryData?.enquiryModel
                            ?.Inquiry_DestinationCountry
                        : "N/A"
                    }
                    disabled
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="DESTCOUNTRYPORT"
                    variant="outlined"
                    name="destcountry"
                    value={
                      allinquiryData?.enquiryModel?.Inquiry_DestinationPort
                        ?.length
                        ? allinquiryData?.enquiryModel?.Inquiry_DestinationPort
                        : "N/A"
                    }
                    disabled
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ paddingBottom: "1rem" }}
                  className="details-section"
                >
                  <TextField
                    label="COUNTRY PIN/ZIP CODE"
                    variant="outlined"
                    name="destPin"
                    value={
                      allinquiryData?.enquiryModel?.Inquiry_DestinationPinCode
                        ?.length
                        ? allinquiryData?.enquiryModel
                            ?.Inquiry_DestinationPinCode
                        : "N/A"
                    }
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* --LAST-- */}
        <Grid item lg={3} md={12} sm={12}>
          <Paper elevation={4} sx={{ padding: "24px", height: "100%" }}>
            <Typography className="pb-3"></Typography>

            <FormControl
              fullWidth
              sx={{ paddingBottom: "1rem" }}
              className="details-section"
            >
              <TextField
                name="remarks"
                //   value={formOriginData.remarks}
                label="REMARKS"
                variant="outlined"
                multiline
                rows={5}
              />
            </FormControl>
          </Paper>
        </Grid>
        {/* --BOTTOM-- */}
        <Grid container className="p-4 pt-0">
          <Grid item lg={1} md={1} xs={2}>
            <p className="m-0"> Package Details</p>
          </Grid>
          <Grid
            item
            lg={11}
            xs={11}
            className="d-flex align-items-center dash-rms"
          >
            <Divider
              variant="left"
              flexItem
              sx={{
                backgroundColor: " var(--color-secondary-light)",
                width: "100%",
                marginTop: "0.8rem",
              }}
            />
          </Grid>
        </Grid>

        {/* table information in other cases */}
        <Grid container className="p-4 pt-0">
          {[
            "LSE",
            "CON",
            "BCN",
            "LCL",
            "BLK",
            "LQD",
            "BBK",
            "OBC",
            "UNA",
          ].includes(code) ? (
            <>
              <Grid item xs={12} sx={{ width: "100%" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Packaging Type
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Dimensional Unit
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Length {/* New column for Length */}
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Width {/* New column for Width */}
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Height {/* New column for Width */}
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Weight Unit
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Weight Per Piece
                        </TableCell>
                        <TableCell
                          span="1"
                          className="tablecell"
                          align="center"
                        >
                          Stakable
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={8} sx={{ width: "100%" }}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          span="1"
                          className="table-data"
                          align="center"
                          style={{ width: "40%" }}
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          span="1"
                          className="table-data"
                          align="center"
                        >
                          container Type
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </Grid>
            </>
          )}
          <Table>
            <TableBody>
              <>
                {[
                  "LSE",
                  "CON",
                  "BCN",
                  "LCL",
                  "BLK",
                  "LQD",
                  "BBK",
                  "OBC",
                  "UNA",
                ].includes(code) ? (
                  <>
                    {allinquiryData?.cargoDetails?.length &&
                      allinquiryData?.cargoDetails?.map((cargodata, index) => {
                        return (
                          <TableRow
                            style={{
                              display: "table",
                              width: "100%",
                              borderCollapse: "collapse",
                              borderSpacing: "0",
                            }}
                          >
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.pkgType ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.quantity ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.dimensionUnit ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.length ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.width ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.height ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.weightUnit ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <p className="m-0 package-section">
                                {cargodata?.weight ?? "N/A"}
                              </p>
                            </TableCell>
                            <TableCell className="table-data" align="center">
                              <Switch
                                size="small"
                                checked={cargodata?.stackable === "true"}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </>
                ) : (
                  <>
                    <Grid item xs={8} sx={{ width: "100%" }}>
                      <TableContainer component={Paper}>
                        <Table>
                          <TableHead>
                            {allinquiryData?.cargoDetails?.length &&
                              allinquiryData?.cargoDetails?.map(
                                (cargodata, index) => {
                                  return (
                                    <TableRow>
                                      <TableCell
                                        span="1"
                                        className="table-data"
                                        align="center"
                                        style={{ width: "40%" }}
                                      >
                                        <p className="m-0 package-section">
                                          {cargodata?.quantity ?? "N/A"}
                                        </p>
                                      </TableCell>
                                      <TableCell
                                        className="table-data"
                                        align="center"
                                      >
                                        <p className="m-0 package-section">
                                          {cargodata?.containerType ?? "N/A"}
                                        </p>
                                      </TableCell>
                                    </TableRow>
                                  );
                                }
                              )}
                          </TableHead>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </>
                )}
              </>
            </TableBody>
          </Table>
        </Grid>
        {/* ) : null} */}
      </Grid>
    </>
  );
};

export default Enquirypreview;
